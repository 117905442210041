import { common } from '@gosupersimple/types';

import { compact } from 'lodash';

import {
  Aggregation,
  AggregationType,
  CompositeFilterCondition,
  DereferencedPipelineOperation,
  FilterCondition,
  GroupAggregateOperation,
  PipelineOperation,
  Property,
  SortedAggregation,
  SwitchToRelationOperation,
} from '../types';

export const isKeyedAggregation = (
  aggregation: Pick<Aggregation, 'key' | 'value'>,
): aggregation is { key: string } & Aggregation =>
  aggregation.key !== undefined && aggregation.value?.expression === undefined;

export const isExprAggregation = (
  aggregation: Pick<Aggregation, 'key' | 'value'>,
): aggregation is { type: AggregationType; value: common.ExpressionValue; property: Property } =>
  aggregation.key === undefined && aggregation.value?.expression !== undefined;

export const isMetricAggregation = (
  aggregation: Pick<Aggregation, 'metricId'>,
): aggregation is { metricId: string } & Aggregation => aggregation.metricId !== undefined;

export const isSortedAggregation = (
  aggregation: Pick<Aggregation, 'type' | 'sort'>,
): aggregation is SortedAggregation => aggregation.sort !== undefined;

export const isSingleFilterCondition = (
  condition: FilterCondition | CompositeFilterCondition,
): condition is FilterCondition => !('operands' in condition);

export const isValueExpression = (value: unknown): value is common.ExpressionValue => {
  return typeof value === 'object' && value !== null && 'expression' in value;
};

export const isValidOperation = (operation: PipelineOperation): boolean =>
  operation.operation !== 'invalid';

export const containsInvalidOperations = (operations: PipelineOperation[]): boolean =>
  !operations.every(isValidOperation);

export const isGroupAggregateOperation = (
  operation: PipelineOperation | DereferencedPipelineOperation,
): operation is GroupAggregateOperation => operation.operation === 'groupAggregate';

export const isSwitchToRelationOperation = (
  operation: PipelineOperation | DereferencedPipelineOperation,
): operation is SwitchToRelationOperation => operation.operation === 'switchToRelation';

export const containsGroupAggregateOperation = (operations: DereferencedPipelineOperation[]) =>
  operations.some(isGroupAggregateOperation);

export const getLastGroupAggregate = (operations: DereferencedPipelineOperation[]) =>
  operations.findLast(isGroupAggregateOperation);

export const getLastGroupAggregateIndex = (operations: DereferencedPipelineOperation[]) =>
  operations.findLastIndex(isGroupAggregateOperation);

export const getLastSwitchToRelationIndex = (operations: DereferencedPipelineOperation[]) =>
  operations.findLastIndex(isSwitchToRelationOperation);

export const getCompositeConditionKeys = (condition: CompositeFilterCondition): string[] => {
  if ('operands' in condition) {
    return condition.operands.flatMap(getCompositeConditionKeys);
  }
  return [condition.key];
};

export const getAggregationKeys = (aggregations: Aggregation[]): string[] => {
  return compact([
    ...aggregations.map((aggregation) => aggregation.key),
    ...aggregations.flatMap((aggregation) =>
      aggregation.sort !== undefined ? getSortingKeys(aggregation.sort) : null,
    ),
  ]);
};

export const getSortingKeys = (sorting: common.Sorting[]) => {
  return sorting.map((sort) => sort.key);
};

export const getGroupingKeys = (grouping: common.Grouping[]) => {
  return grouping.map((group) => group.key);
};

export const getOperationPropertyKeys = (
  operation: PipelineOperation | DereferencedPipelineOperation,
) => {
  switch (operation.operation) {
    case 'deriveField':
      return [operation.parameters.key];
    case 'addRelatedColumn':
      return operation.parameters.columns.map((column) => column.property.key);
    case 'groupAggregate':
      return operation.parameters.aggregations.map((aggregation) => aggregation.property.key);
    case 'relationAggregate':
      return operation.parameters.aggregations.map((aggregation) => aggregation.property.key);
    default:
      return [];
  }
};
