import { isEqual } from 'lodash';

import { isAggregatedVisualisation, Visualisation } from '@/explore/types';

export const regenerateVisualisationIfNeeded = ({
  visualisation,
  defaultVisualisation,
  nextDefaultVisualisation,
}: {
  visualisation: Visualisation;
  defaultVisualisation: Visualisation;
  nextDefaultVisualisation: Visualisation;
}): Visualisation => {
  const isDirty = !isEqual(visualisation, defaultVisualisation);
  const typeChanged =
    isAggregatedVisualisation(visualisation) !==
    isAggregatedVisualisation(nextDefaultVisualisation);

  if (!isDirty || typeChanged) {
    return nextDefaultVisualisation;
  }

  return visualisation;
};
