import { RefObject, useEffect } from 'react';

export type MetaKey = 'ctrl' | 'shift' | 'alt' | 'meta';
export type TargetKeys = string | [string, ...MetaKey[]];
type EventCallback = (event: KeyboardEvent) => void;

export const eventIncludesKey = (event: KeyboardEvent, keys: TargetKeys) => {
  const [key, ...modifiers] = Array.isArray(keys) ? keys : [keys];

  if (event.key.toLocaleLowerCase() === key.toLocaleLowerCase()) {
    if (modifiers.length === 0) {
      return true;
    }

    for (const modifier of modifiers) {
      if (event[`${modifier}Key`]) {
        return true;
      }
    }
  }

  return false;
};

/**
 * A convenience hook for listening to keyboard events and firing a callback.
 * Example: `useKeyPress('Escape', () => inputRef.current?.blur(), inputRef);`
 *
 * @param targetKeys The keys to listen for (from event.key); if you wish to include a combination with a modifier key,
 * pass an array with the key and the modifier keys (e.g. ['s', 'ctrl'] or ['Enter', 'meta', 'shift']).
 * If multiple modifiers are passed in, only 1 of them has to be pressed for the callback to fire.
 * @param callback  The callback to fire when the key is pressed
 * @param targetRef (optional) A ref to the element to listen on. If not provided, the document body will be used.
 */
export const useKeyPress = (
  targetKeys: TargetKeys,
  callback: EventCallback,
  targetRef?: RefObject<HTMLElement> | undefined,
) => {
  useEffect(() => {
    if (targetRef !== undefined && !targetRef.current) {
      return;
    }

    const handler = (event: KeyboardEvent) => {
      eventIncludesKey(event, targetKeys) && callback(event);
    };

    const target = targetRef?.current ?? document.body;
    target.addEventListener('keydown', handler);

    return () => {
      target.removeEventListener('keydown', handler);
    };
  }, [callback, targetKeys, targetRef]);
};
