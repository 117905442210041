import { first } from 'lodash';

import { common } from '@gosupersimple/types';

import { ensureValidVisualisation, generateVisualisation } from '../components/visualisation/utils';
import { getModel } from '../model/utils';
import {
  InvalidOperationError,
  getFinalState,
  getFinalStateOrThrow,
  getNextPipelineStateOrThrow,
} from '../pipeline/state';
import {
  dereferenceOperation,
  dereferenceOperations,
  dereferencePipeline,
  flattenPipeline,
  getBaseModelId,
} from '../pipeline/utils';
import {
  Cell,
  Exploration,
  InvalidCell,
  InvalidOperation,
  MetricV2,
  Model,
  Pipeline,
  PipelineOperation,
  PipelineState,
} from '../types';
import { getCohortOperation } from '../edit-cohort/utils';
import { getFunnelOperation } from '../edit-funnel/utils';

export class InvalidPipelineReferenceError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'InvalidPipelineReferenceError';
  }
}

export class InvalidModelReferenceError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'InvalidModelReferenceError';
  }
}

export const restoreInvalidCell = (cell: Cell): Exclude<Cell, InvalidCell> => {
  return cell.kind === 'invalid'
    ? {
        ...cell.cell,
        ...('title' in cell ? { title: cell.title } : {}),
        viewOptions: cell.viewOptions,
      }
    : cell;
};

export const restoreInvalidOperation = (
  operation: PipelineOperation,
): Exclude<PipelineOperation, InvalidOperation> =>
  operation.operation === 'invalid' ? operation.parameters.operation : operation;

const restoreInvalidCells = (exploration: Exploration): Exploration => {
  return {
    ...exploration,
    view: {
      cells: exploration.view.cells.map(restoreInvalidCell),
    },
  };
};

const restoreInvalidOperations = (exploration: Exploration): Exploration => {
  return {
    ...exploration,
    view: {
      cells: exploration.view.cells.map((cell) => {
        if ('pipeline' in cell) {
          return {
            ...cell,
            pipeline: {
              ...cell.pipeline,
              operations: cell.pipeline.operations.map(restoreInvalidOperation),
            },
          };
        }
        return cell;
      }),
    },
  };
};

/**
 * Validate the base model of a pipeline exists.
 * Some operations contain their own pipeline, like funnels and cohorts. For these we validate model(s) in the inner pipeline instead.
 */
const validateBaseModel = (
  cell: Cell,
  ctx: {
    exploration: Exploration;
    models: Model[];
    metrics: MetricV2[];
  },
) => {
  if (!('pipeline' in cell)) {
    return;
  }
  switch (cell.kind) {
    case 'records': {
      const baseModelId = getBaseModelId(cell.pipeline, ctx.exploration);
      if (getModel(ctx.models, baseModelId) === undefined) {
        throw new InvalidModelReferenceError(`Model "${baseModelId}" not found`);
      }
      const firstOperation = first(cell.pipeline.operations);
      if (firstOperation?.operation === 'joinPipeline') {
        const relatedBaseModelId = getBaseModelId(
          firstOperation.parameters.pipeline,
          ctx.exploration,
        );
        if (getModel(ctx.models, relatedBaseModelId) === undefined) {
          throw new InvalidModelReferenceError(`Model "${relatedBaseModelId}" not found`);
        }
      }
      break;
    }
    case 'cohort': {
      const operation = getCohortOperation(cell.pipeline);
      if (operation?.operation !== 'cohort') {
        break;
      }
      const baseModelId = getBaseModelId(operation.parameters.pipeline, ctx.exploration);
      if (getModel(ctx.models, baseModelId) === undefined) {
        throw new InvalidModelReferenceError(`Model "${baseModelId}" not found`);
      }
      break;
    }
    case 'funnel': {
      const operation = getFunnelOperation(cell.pipeline);
      operation.parameters.steps.forEach((step) => {
        const baseModelId = getBaseModelId(step.pipeline, ctx.exploration);
        if (getModel(ctx.models, baseModelId) === undefined) {
          throw new InvalidModelReferenceError(`Model "${baseModelId}" not found`);
        }
      });
    }
  }
};

export const ensureValidExploration = (
  exploration: Exploration,
  models: Model[],
  metrics: MetricV2[],
  variables: common.QueryVariables,
) => {
  const restoredExploration = restoreInvalidOperations(restoreInvalidCells(exploration));

  const validatedExploration = {
    ...restoredExploration,
    view: {
      cells: restoredExploration.view.cells.map((cell) =>
        ensureValidCell(cell, { exploration: restoredExploration, models, metrics, variables }),
      ),
    },
  };

  return {
    ...validatedExploration,
    view: {
      cells: validatedExploration.view.cells.map((cell) =>
        ensureValidSort(cell, { exploration: validatedExploration, models, metrics, variables }),
      ),
    },
  };
};

const ensureValidCell = (
  cell: Cell,
  ctx: {
    exploration: Exploration;
    models: Model[];
    metrics: MetricV2[];
    variables: common.QueryVariables;
  },
): Cell => {
  if (cell.kind === 'invalid') {
    return cell;
  }

  try {
    validateBaseModel(cell, ctx);
  } catch (e) {
    if (e instanceof InvalidModelReferenceError || e instanceof InvalidPipelineReferenceError) {
      return {
        id: cell.id,
        kind: 'invalid',
        ...('title' in cell ? { title: cell.title } : {}),
        message: e.message,
        viewOptions: cell.viewOptions,
        cell,
      };
    }
    throw e;
  }

  const pipeline = 'pipeline' in cell ? ensureValidPipeline(cell.pipeline, ctx) : undefined;
  const visualisations =
    pipeline && 'visualisations' in cell && cell.visualisations !== undefined
      ? cell.visualisations.map((visualisation) => {
          try {
            return ensureValidVisualisation(visualisation, {
              pipeline: ensureValidPipeline(flattenPipeline(pipeline, ctx.exploration), ctx),
              exploration: ctx.exploration,
              models: ctx.models,
              metrics: ctx.metrics,
            });
          } catch (error) {
            return generateVisualisation(
              dereferencePipeline(
                ensureValidPipeline(flattenPipeline(pipeline, ctx.exploration), ctx),
                ctx.exploration,
              ),
              ctx,
            );
          }
        })
      : undefined;

  return {
    ...cell,
    ...(pipeline ? { pipeline } : {}),
    ...(visualisations ? { visualisations } : {}),
  };
};

const ensureValidSort = (
  cell: Cell,
  ctx: {
    exploration: Exploration;
    models: Model[];
    metrics: MetricV2[];
    variables: common.QueryVariables;
  },
): Cell => {
  if (!('sort' in cell) || (cell.sort?.length ?? 0) === 0) {
    return cell;
  }

  const dereferencedPipeline = dereferencePipeline(cell.pipeline, ctx.exploration);
  const { fields } = getFinalStateOrThrow(
    dereferencedPipeline.baseModelId,
    dereferencedPipeline.operations,
    ctx,
  );

  return {
    ...cell,
    sort: cell.sort?.filter((sort) => fields.some((field) => field.key === sort.key)),
  };
};

const ensureValidOperation = (
  operation: PipelineOperation,
  ctx: {
    exploration: Exploration;
    pipelineState: PipelineState;
    models: Model[];
    metrics: MetricV2[];
    variables: common.QueryVariables;
    operationIndex: number;
  },
): PipelineOperation => {
  if (operation.operation === 'invalid') {
    return operation;
  }
  try {
    getNextPipelineStateOrThrow(
      ctx.pipelineState,
      dereferenceOperation(operation, ctx.exploration),
      ctx.operationIndex,
      ctx,
    );
    return operation;
  } catch (e) {
    if (e instanceof InvalidOperationError || e instanceof InvalidPipelineReferenceError) {
      return {
        operation: 'invalid',
        parameters: {
          message: e.message,
          operation,
        },
      };
    }
    throw e;
  }
};

export const ensureValidPipeline = (
  pipeline: Pipeline,
  ctx: {
    exploration: Exploration;
    models: Model[];
    metrics: MetricV2[];
    variables: common.QueryVariables;
  },
): Pipeline => {
  const flattenedPipeline = flattenPipeline(pipeline, ctx.exploration);
  const baseModelId = flattenedPipeline.baseModelId;
  const parentPipelineLength = flattenedPipeline.operations.length - pipeline.operations.length;
  const parentPipeline = {
    ...flattenedPipeline,
    operations: flattenedPipeline.operations.slice(0, parentPipelineLength),
  };
  const dereferencedParentOperations =
    parentPipelineLength > 0
      ? dereferencePipeline(ensureValidPipeline(parentPipeline, ctx), ctx.exploration).operations
      : [];
  return {
    ...pipeline,
    operations: pipeline.operations.reduce<PipelineOperation[]>((acc, operation, i) => {
      const pipelineState = getFinalState(
        baseModelId,
        [...dereferencedParentOperations, ...dereferenceOperations(acc, ctx.exploration)],
        ctx,
      );

      return [
        ...acc,
        ensureValidOperation(operation, {
          ...ctx,
          pipelineState,
          operationIndex: parentPipelineLength + i,
        }),
      ];
    }, []),
  };
};
