import { useState } from 'react';
import classNames from 'classnames';
import { pipeline } from '@gosupersimple/types';

import { isEqual } from 'lodash';

import { Form } from '@/components/form';
import { PenguinoInput } from '@/components/form/penguino-input';
import { Input } from '@/components/form/input';
import { Button, IconButton } from '@/components/button';

import { useExplorationContext } from '../exploration/exploration-context';
import type { Fields } from '../types';
import { nameToKey } from './utils';

import { useDirtyContext } from '../dirty-context';

import form from '../../components/form/form.module.scss';
import styles from './pipeline.module.scss';

interface DeriveFieldFormProps {
  fields: Fields;
  operation?: pipeline.DeriveFieldOperation;
  setOperation(operation: pipeline.DeriveFieldOperation): void;
  onClose(): void;
  onSwitchToRelatedModel?(): void;
}

const DefaultParameters = {
  key: '',
  fieldName: '',
  value: { expression: '', version: '1' },
};

export const DeriveFieldForm = (props: DeriveFieldFormProps) => {
  const { onSwitchToRelatedModel } = props;
  const { getVariables } = useExplorationContext();
  const initialParameters = props.operation?.parameters ?? DefaultParameters;
  const [parameters, setParameters] =
    useState<pipeline.DeriveFieldOperation['parameters']>(initialParameters);
  const { setDirty } = useDirtyContext();

  const handleChange = (parameters: pipeline.DeriveFieldOperation['parameters']) => {
    setParameters(parameters);
    const isDirty = !isEqual(initialParameters, parameters);
    setDirty(isDirty);
  };

  const handleFieldNameChange = (fieldName: string) => {
    handleChange({
      ...parameters,
      fieldName,
      key: nameToKey(fieldName),
    });
  };

  const handleExpressionChange = (expression: string) => {
    handleChange({
      ...parameters,
      value: {
        ...parameters.value,
        expression,
      },
    });
  };

  const handleSubmit = () => {
    setDirty(false);
    props.setOperation({
      operation: 'deriveField',
      parameters,
    });
  };

  const handleCancel = () => {
    setDirty(false);
    props.onClose();
  };

  return (
    <Form className={form.formHorizontal} onSubmit={handleSubmit}>
      <div className={classNames(form.formRow, form.alignTop)}>
        <div className={form.formRowInner}>
          <PenguinoInput
            fields={props.fields}
            variables={getVariables()}
            required
            autoFocus
            placeholder="Enter custom expression..."
            value={parameters.value.expression}
            rows={2}
            onChange={handleExpressionChange}
          />
        </div>
        {onSwitchToRelatedModel && (
          <IconButton
            icon="CustomFormula"
            title="Use related model"
            size="small"
            className={styles.customFormulaActive}
            onClick={(event) => {
              event.stopPropagation();
              onSwitchToRelatedModel();
            }}
          />
        )}
      </div>
      <div className={form.formRow}>
        <label className={form.formLabel}>Column name</label>
        <Input
          required
          type="text"
          placeholder="Enter value..."
          value={parameters.fieldName}
          onChange={(e) => handleFieldNameChange(e.target.value)}
        />
      </div>

      <div className={form.formControls}>
        <Button size="small" type="submit">
          {props.operation ? 'Save' : 'Add'}
        </Button>
        <Button size="small" variant="outlined" onClick={handleCancel}>
          {props.operation ? 'Cancel' : 'Back'}
        </Button>
      </div>
    </Form>
  );
};
