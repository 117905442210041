import { useState } from 'react';

import { AccessToken, useCreateAccessTokenMutation, useDeleteAccessTokenMutation } from '@/graphql';

import { Section } from '../common';

import { CreateAccessTokenForm } from './create-form';
import { AccessTokenList } from './list';

import { Button } from '../../components/button';
import { Icon } from '../../components/icon';

import styles from './section.module.scss';
import commonStyles from '../common/common.module.scss';

interface AccessTokensSectionProps {
  accessTokens: AccessToken[];
  refetch: () => void;
}

export const AccessTokensSection = (props: AccessTokensSectionProps) => {
  const { accessTokens, refetch } = props;
  const [createMutation, { data: createdData, loading }] = useCreateAccessTokenMutation();
  const [deleteMutation] = useDeleteAccessTokenMutation();

  const [showCreateForm, setShowCreateForm] = useState(false);

  const handleCreate = async (name: string) => {
    await createMutation({
      variables: {
        input: {
          name,
        },
      },
    });
    setShowCreateForm(false);
    refetch();
  };

  const handleDelete = async (id: string) => {
    if (confirm('Are you sure?') === true) {
      await deleteMutation({
        variables: {
          accessTokenId: id,
        },
      });
      refetch();
    }
  };

  const createdAccessToken =
    createdData?.createAccessToken.__typename === 'AccessTokenPreview'
      ? createdData.createAccessToken
      : undefined;

  return (
    <Section
      title="API tokens"
      headerActions={
        !showCreateForm && accessTokens.length > 0
          ? [
              {
                label: 'Generate API Token',
                iconName: 'Plus',
                onClick: () => {
                  setShowCreateForm(true);
                },
              },
            ]
          : []
      }>
      {showCreateForm && (
        <div className={styles.frame}>
          <CreateAccessTokenForm
            loading={loading}
            onSubmit={handleCreate}
            onCancel={() => setShowCreateForm(false)}
          />
        </div>
      )}
      {accessTokens.length === 0 ? (
        <div className={commonStyles.emptyView}>
          <div>No API tokens. Create an API token get programmatic access to your account.</div>
          <Button
            icon={<Icon name="Plus" size={16} />}
            variant="outlined"
            onClick={() => setShowCreateForm(true)}>
            Generate API Token
          </Button>
        </div>
      ) : (
        <AccessTokenList
          accessTokens={accessTokens}
          createdAccessToken={createdAccessToken}
          onDelete={handleDelete}
        />
      )}
    </Section>
  );
};
