import { ScaleLinear, ScaleTime } from 'd3-scale';

import { BarRounded } from '@visx/shape';
import { Group } from '@visx/group';

type BarSeriesProps = {
  data: { date: Date; value: number }[];
  fillColor: string;
  barIdx: number;
  numBars: number;
  valueScale: ScaleLinear<number, number>;
  dateScale: ScaleTime<number, number>;
  barRadius?: number;
  bandwidthPadding?: number;
  barPadding?: number;
};

export const BarSeries = (props: BarSeriesProps) => {
  const {
    data,
    fillColor,
    barIdx,
    numBars,
    valueScale,
    dateScale,
    barRadius = 5,
    bandwidthPadding = 0.5,
    barPadding = 0.2,
  } = props;

  return (
    <Group>
      {data.map(({ date, value }, idx) => {
        const y = valueScale(value) ?? 0;
        const bandwidth = (dateScale.range()[1] / data.length) * (1 - bandwidthPadding);

        const barWidth = (bandwidth / numBars) * (1 - barPadding);
        const height = valueScale.range()[0] - y;
        const x = (dateScale(date) ?? 0) - bandwidth / 2 + (bandwidth / numBars) * barIdx;
        return (
          <BarRounded
            key={idx}
            x={x}
            y={y}
            width={barWidth}
            height={height}
            fill={fillColor}
            radius={barRadius}
            top
          />
        );
      })}
    </Group>
  );
};
