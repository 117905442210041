import { useState, useEffect } from 'react';
import { first, omit } from 'lodash';
import { common } from '@gosupersimple/types';

import { AnalyticsContextProvider, useTrackEvent } from '@/lib/analytics';

import { useMetadataContext } from '../metadata-context';
import { Select } from '../../components/form/select';
import { SearchInput } from '../../components/form/search-input';
import { useExplorationContext } from '../exploration/exploration-context';
import { useExplorationCellContext } from '../exploration/exploration-cell-context';
import {
  getCell,
  getCellIndex,
  addExplorationCells,
  prepareExplorationCellsForImport,
} from '../exploration/utils';
import { Cell, Exploration, Model, Pipeline } from '../types';
import { replacePipeline } from '../edit-pipeline/utils';
import { getFinalStateOrThrow } from '../pipeline/state';
import { PipelineSearchModal } from '../exploration-search/pipeline-search';
import {
  useEnsureFieldsExist,
  useEnsureFieldsExistGrouped,
} from '../edit-pipeline/hooks/use-ensure-fields-exist';
import { SidebarSection } from '../exploration/sidebar-section';
import { EditCohortState } from './types';
import {
  buildOptions,
  getCohortOperation,
  cohortTimeIntervalOptions,
  getDefaultCohortTimeKey,
  getDefaultEventTimeKey,
  getDefaultIdFieldKey,
  isStateReady,
  updateCohortTimeKeys,
  getIdFields,
  getCohortPipelineState,
  getAllTimeKeyFields,
  getTimeKeyValue,
} from './utils';
import { dereferencePipeline, getParentPipelineColor } from '../pipeline/utils';
import { PipelineModel } from '../components/pipeline/pipeline-model';
import { getModelExploration } from '../utils';
import { useEnsurePipelinesExist } from '../edit-pipeline/hooks/use-ensure-pipelines-exist';

import form from '../../components/form/form.module.scss';

const DefaultTimeInterval: common.CohortTimeInterval = 'month';

interface EditCohortProps {
  pipeline: Pipeline;
  explorations: Exploration[];
  setExploration: (exploration: Exploration) => void;
}

export const EditCohort = (props: EditCohortProps) => {
  const { pipeline } = props;

  const { models, metricsV2: metrics, getModel } = useMetadataContext();
  const { exploration, selectCell, scrollToCell } = useExplorationContext();
  const { cellIndex, cell } = useExplorationCellContext();
  const [showExplorationList, setShowExplorationList] = useState(false);
  const { getVariables } = useExplorationContext();
  const trackEvent = useTrackEvent();

  const [state, setState] = useState<EditCohortState>(
    getCohortOperation(pipeline)?.parameters ?? {},
  );

  // Values received via props take precendence over the state. While it works, it is not correct though since
  // external props update (some other component triggers exploration change) reverts the changes made by
  // user that were stored in state.
  useEffect(() => setState(getCohortOperation(pipeline)?.parameters ?? {}), [pipeline]);

  const variables = getVariables();

  const handleSelectEvent = (pipeline: Pipeline, updatedExploration: Exploration) => {
    const dereferencedPipeline = dereferencePipeline(pipeline, updatedExploration);
    const { fields, model } = getFinalStateOrThrow(
      dereferencedPipeline.baseModelId,
      dereferencedPipeline.operations,
      { models, variables, metrics },
    );

    const state = updateCohortTimeKeys(
      {
        pipeline,
        cohortId: getDefaultIdFieldKey(fields, model, models),
        cohortTimeInterval: DefaultTimeInterval,
        eventTimeInterval: DefaultTimeInterval,
      },
      {
        cohortTimeKey: getDefaultCohortTimeKey(model, models),
        eventTimeKey: getDefaultEventTimeKey(fields, model),
      },
    );

    updateState(state, updatedExploration);
    setShowExplorationList(false);

    const updatedSelectedCellIndex = getCellIndex(cell.id, updatedExploration);
    selectCell(updatedSelectedCellIndex);
    scrollToCell(updatedSelectedCellIndex);
  };

  const handleSelectModel = (model: Model) => {
    handleSelectEvent({ baseModelId: model.modelId, operations: [] }, exploration);
  };

  const handleSelectCell = (selectedCell: Cell, dependencies: Cell[]) => {
    if (!('pipeline' in selectedCell) || selectedCell.pipeline.pipelineId === undefined) {
      throw new Error('Invalid selection for cohort event');
    }

    const isFromCurrentExploration = getCell(selectedCell.id, exploration) !== undefined;

    if (isFromCurrentExploration) {
      const pipeline = {
        parentId: selectedCell.pipeline.pipelineId,
        operations: [],
      };
      return handleSelectEvent(pipeline, exploration);
    }

    [selectedCell, ...dependencies] = prepareExplorationCellsForImport([
      selectedCell,
      ...dependencies,
    ]);
    if (!('pipeline' in selectedCell) || selectedCell.pipeline.pipelineId === undefined) {
      throw new Error('Cell has no pipelineId after preparation for import.');
    }
    const updatedExploration: Exploration = addExplorationCells(
      exploration,
      [...dependencies, selectedCell],
      cellIndex,
    );

    const pipeline = {
      parentId: selectedCell.pipeline.pipelineId,
      operations: [],
    };

    handleSelectEvent(pipeline, updatedExploration);
  };

  const updateState = (state: EditCohortState, exploration: Exploration) => {
    setState(state);

    trackEvent('Retention Chart Updated', {
      explorationId: exploration.explorationId,
      name: exploration.name,
      chart: state,
    });

    if (isStateReady(state)) {
      const updatedSelectedCellIndex = getCellIndex(cell.id, exploration);
      const nextExploration = replacePipeline(exploration, updatedSelectedCellIndex, {
        ...pipeline,
        operations: [{ operation: 'cohort', parameters: state }],
      });

      props.setExploration(nextExploration);
    }
  };

  const handleImportEvent = () => {
    if (state.pipeline === undefined || !('baseModelId' in state.pipeline)) {
      throw new Error('Trying to import non-existent or invalid cohort event');
    }
    const modelExploration = getModelExploration(getModel(state.pipeline.baseModelId));
    const cell = first(modelExploration.view.cells);
    if (cell === undefined || !('pipeline' in cell) || cell.pipeline.pipelineId === undefined) {
      throw new Error('Model exploration generation produced invalid cell');
    }

    const pipelineId = cell.pipeline.pipelineId;
    const updatedState = {
      ...state,
      pipeline: {
        ...omit(state.pipeline, 'baseModelId'),
        parentId: pipelineId,
      },
    };

    setState(updatedState);

    if (!isStateReady(updatedState)) {
      throw new Error('Cannot add cohort event to exploration: state is not ready');
    }

    const withUpdatedOperation = replacePipeline(exploration, cellIndex, {
      ...pipeline,
      operations: [{ operation: 'cohort', parameters: updatedState }],
    });
    props.setExploration({
      ...withUpdatedOperation,
      view: {
        cells: [
          ...withUpdatedOperation.view.cells.slice(0, cellIndex + 1),
          cell,
          ...withUpdatedOperation.view.cells.slice(cellIndex + 1),
        ],
      },
    });

    selectCell(cellIndex + 1);
    scrollToCell(cellIndex + 1);
  };

  const handleSelectCohortId = (cohortId: string) =>
    updateState({ ...state, cohortId }, exploration);

  const handleSelectCohortTimeKey = (cohortTimeKey: string) => {
    updateState(updateCohortTimeKeys(state, { cohortTimeKey }), exploration);
  };

  const handleSelectCohortTimeInterval = (cohortTimeInterval: common.CohortTimeInterval) =>
    updateState({ ...state, cohortTimeInterval }, exploration);

  const handleSelectEventTimeKey = (eventTimeKey: string) =>
    updateState(updateCohortTimeKeys(state, { eventTimeKey }), exploration);

  const handleSelectEventTimeInterval = (eventTimeInterval: common.CohortTimeInterval) =>
    updateState({ ...state, eventTimeInterval }, exploration);

  const handleClearModel = () => {
    updateState({}, exploration);

    const nextExploration = replacePipeline(exploration, cellIndex, {
      ...pipeline,
      operations: [],
    });

    props.setExploration(nextExploration);
  };

  const paddedExploration = useEnsurePipelinesExist(
    exploration,
    state.pipeline !== undefined && 'parentId' in state.pipeline ? [state.pipeline.parentId] : [],
  );

  const hasPipeline = state.pipeline !== undefined && 'parentId' in state.pipeline;
  const parentPipelineColor =
    state.pipeline !== undefined
      ? getParentPipelineColor(paddedExploration, state.pipeline)
      : undefined;

  const pipelineState = getCohortPipelineState(state.pipeline, paddedExploration, {
    models,
    variables,
    metrics,
  });
  const fields = pipelineState?.fields ?? [];
  const relations = pipelineState?.relations ?? [];

  const idFields = useEnsureFieldsExist(
    getIdFields(fields),
    state.cohortId !== undefined ? [state.cohortId] : [],
  );
  const cohortTimeKeyValue = getTimeKeyValue(state.cohortTimeKey, state.pipeline, relations);
  const eventTimeKeyValue = getTimeKeyValue(state.eventTimeKey, state.pipeline, relations);
  const cohortTimeKeyFields = useEnsureFieldsExistGrouped(
    getAllTimeKeyFields(fields, state.pipeline, relations, getModel),
    cohortTimeKeyValue !== undefined ? [cohortTimeKeyValue] : [],
  );
  const eventTimeKeyFields = useEnsureFieldsExistGrouped(
    getAllTimeKeyFields(fields, state.pipeline, relations, getModel),
    eventTimeKeyValue !== undefined ? [eventTimeKeyValue] : [],
  );

  const options = buildOptions(
    idFields,
    cohortTimeKeyFields,
    eventTimeKeyFields,
    state.pipeline,
    paddedExploration,
    pipelineState?.model,
  );
  console.log('L272', state, pipelineState, options, eventTimeKeyValue, eventTimeKeyFields);

  return (
    <>
      <SidebarSection title="Chart settings">
        <div className={form.formContainer}>
          {options !== undefined ? (
            <>
              <div className={form.separatedFormSection}>
                <PipelineModel
                  title="From"
                  color={parentPipelineColor}
                  accessories={[
                    ...(hasPipeline
                      ? []
                      : [
                          {
                            icon: 'Instance' as const,
                            title: 'Edit Event Pipeline',
                            onClick: handleImportEvent,
                          },
                        ]),
                    {
                      icon: 'Trash2',
                      title: 'Remove',
                      onClick: handleClearModel,
                    },
                  ]}>
                  {options.title}
                </PipelineModel>
              </div>
              <div className={form.separatedFormSection}>
                <div className={form.sectionTitle}>User</div>
                <div className={form.formRow}>
                  <div className={form.formLabel}>ID field</div>
                  <SearchInput
                    options={options.idFields}
                    value={state.cohortId}
                    onChange={handleSelectCohortId}
                  />
                </div>
              </div>
              <div className={form.separatedFormSection}>
                <div className={form.sectionTitle}>Cohort</div>
                <div className={form.formRow}>
                  <div className={form.formLabel}>Cohort time</div>
                  <SearchInput
                    options={options.cohortTimeKeyFields}
                    value={cohortTimeKeyValue}
                    onChange={handleSelectCohortTimeKey}
                  />
                </div>
                <div className={form.formRow}>
                  <div className={form.formLabel}>Time interval</div>
                  <Select
                    options={cohortTimeIntervalOptions}
                    value={state.cohortTimeInterval}
                    onChange={handleSelectCohortTimeInterval}
                  />
                </div>
              </div>
              <div className={form.separatedFormSection}>
                <div className={form.sectionTitle}>Event</div>
                <div className={form.formRow}>
                  <div className={form.formLabel}>Event time</div>
                  <SearchInput
                    options={options.eventTimeKeyFields}
                    value={eventTimeKeyValue}
                    onChange={handleSelectEventTimeKey}
                  />
                </div>
                <div className={form.formRow}>
                  <div className={form.formLabel}>Time interval</div>
                  <Select
                    options={cohortTimeIntervalOptions}
                    value={state.eventTimeInterval}
                    onChange={handleSelectEventTimeInterval}
                  />
                </div>
              </div>
            </>
          ) : (
            <div className={form.separatedFormSection}>
              <button
                className={form.placeholderButton}
                onClick={() => setShowExplorationList(true)}>
                Select input…
              </button>
            </div>
          )}
        </div>
      </SidebarSection>

      {showExplorationList && (
        <AnalyticsContextProvider
          properties={{
            intent: 'retention chart event selection',
            exploration,
          }}>
          <PipelineSearchModal
            explorations={props.explorations}
            exploration={exploration}
            onSelectModel={handleSelectModel}
            onSelectCell={handleSelectCell}
            onClose={() => setShowExplorationList(false)}
            kinds={['Event']}
            title="Select Event"
          />
        </AnalyticsContextProvider>
      )}
    </>
  );
};
