import { first } from 'lodash';

import { SearchInput } from '@/components/form/search-input';

import { Exploration, MetricV2, Model } from '../types';
import { getPipelineById, isRecordsCell } from '../exploration/utils';
import { getPipelineFields } from '../pipeline/state';
import { getExplorationVariables } from '../utils';
import { fieldToOption } from './utils';
import { useEnsureFieldsExist } from './hooks/use-ensure-fields-exist';

import { deconstructRelationOptionValue, getCustomRelationOptions } from './utils/relation';

import { useExplorationContext } from '../exploration/exploration-context';

import form from '../../components/form/form.module.scss';

type Data = {
  pipelineId: string;
  joinStrategy: {
    joinKeyOnBase: string;
    joinKeyOnRelated: string;
  };
};

type EditJoinStrategyFieldsProps = {
  data: Data;
  exploration: Exploration;
  models: Model[];
  metrics: MetricV2[];
  basePipelineId: string;
  onChange: (data: Data) => void;
};

export const EditJoinStrategyFields = (props: EditJoinStrategyFieldsProps) => {
  const { data, onChange, exploration, models, metrics, basePipelineId } = props;
  const { selectedCell } = useExplorationContext();

  if (selectedCell === null || !isRecordsCell(selectedCell)) {
    throw new Error(`EditJoinStrategyFields can only be rendered in a RecordsCell`);
  }

  const pipelineOptions = getCustomRelationOptions(exploration, selectedCell);

  const explorationVariables = getExplorationVariables(exploration);
  const stateContext = {
    models,
    variables: explorationVariables,
    metrics,
  };
  const baseFields = useEnsureFieldsExist(
    getPipelineFields(getPipelineById(basePipelineId, exploration), exploration, stateContext),
    [data.joinStrategy.joinKeyOnBase],
  );

  const relatedFields = useEnsureFieldsExist(
    getPipelineFields(getPipelineById(data.pipelineId, exploration), exploration, stateContext),
    [data.joinStrategy.joinKeyOnRelated],
  );

  const handleRelatedPipelineChange = (value: string) => {
    const reference = deconstructRelationOptionValue(value);
    if (!('pipelineId' in reference)) {
      throw new Error(`Invalid pipelineId in value ${value}`);
    }
    const { pipelineId } = reference;
    const firstRelatedField = first(
      getPipelineFields(getPipelineById(pipelineId, exploration), exploration, stateContext),
    );

    if (firstRelatedField === undefined) {
      throw new Error(`Pipeline ${pipelineId} does not have any fields`);
    }

    onChange({
      ...data,
      pipelineId,
      joinStrategy: {
        joinKeyOnBase: firstRelatedField.key,
        joinKeyOnRelated: firstRelatedField.key,
      },
    });
  };

  const handleBaseFieldChange = (key: string) => {
    onChange({
      ...data,
      joinStrategy: {
        ...data.joinStrategy,
        joinKeyOnBase: key,
      },
    });
  };

  const handleRelatedFieldChange = (key: string) => {
    onChange({
      ...data,
      joinStrategy: {
        ...data.joinStrategy,
        joinKeyOnRelated: key,
      },
    });
  };

  return (
    <>
      <div className={form.formRow}>
        <label className={form.formLabel}>Data</label>
        <SearchInput
          options={pipelineOptions}
          value={data.pipelineId}
          onChange={handleRelatedPipelineChange}
        />
      </div>
      <div className={form.formRow}>
        <label className={form.formLabel}>Base column</label>
        <SearchInput
          options={baseFields.map(fieldToOption)}
          value={data.joinStrategy.joinKeyOnBase}
          onChange={handleBaseFieldChange}
        />
      </div>
      <div className={form.formRow}>
        <label className={form.formLabel}>Related column</label>
        <SearchInput
          options={relatedFields.map(fieldToOption)}
          value={data.joinStrategy.joinKeyOnRelated}
          onChange={handleRelatedFieldChange}
        />
      </div>
    </>
  );
};
