import { BarRounded, BarStackHorizontal } from '@visx/shape';
import { Group } from '@visx/group';
import { scaleBand } from '@visx/scale';
import { localPoint } from '@visx/event';
import { useTooltip } from '@visx/tooltip';
import { AxisLeft } from '@visx/axis';
import { GridColumns } from '@visx/grid';

import { ScaleLinear, ScaleOrdinal } from 'd3-scale';

import { ChartTooltipSingleValue } from '../common';
import { CategoryData } from '../grouped-chart/types';

import styles, { gridLineColor } from '../charts.module.scss';

type TooltipData = {
  categoryLabel: string;
  categoryValue: string;
  valueLabel: string;
  valueKey: string;
  value: number;
  color: string;
};

interface HorizontalBarChartProps {
  categoryFormat: {
    precision?: string;
  };
  onCategroryClick?: (event: React.MouseEvent, key: string, value: any) => void;
  data: CategoryData;
  width: number;
  height: number;
  valueScale: ScaleLinear<number, number>;
  colorScale: ScaleOrdinal<string, string>;
}

const GroupWidth = parseInt(styles.widthPerGroup);
const BarRadius = 5;
const CategoryPadding = 0.3;

export const StackedHorizontalBarChart = ({
  data,
  width,
  height,
  colorScale,
  valueScale,
  onCategroryClick,
}: HorizontalBarChartProps) => {
  const { showTooltip, hideTooltip, tooltipOpen, tooltipLeft, tooltipTop, tooltipData } =
    useTooltip<TooltipData>();

  const handleMouseOver = (event: any, data: TooltipData) => {
    const coords = localPoint(event.target.ownerSVGElement, event);
    if (coords === null) {
      return false;
    }
    showTooltip({ tooltipLeft: coords.x, tooltipTop: coords.y, tooltipData: data });
    return true;
  };

  const categoryScale = scaleBand({
    domain: data.items.map((d) => d.categoryValue),
    padding: CategoryPadding,
    range: [0, height],
  });

  return (
    <div className={styles.graph}>
      {tooltipData !== undefined ? (
        <ChartTooltipSingleValue
          tooltipTop={tooltipTop}
          tooltipLeft={tooltipLeft}
          tooltipData={{
            label: tooltipData.categoryValue,
            value: tooltipData.value,
          }}
          tooltipOpen={tooltipOpen}
          categoryLabel={tooltipData.categoryLabel}
          valueLabel={tooltipData.valueLabel}
          seriesColor={tooltipData.color}
        />
      ) : null}
      <svg width={width} height={height}>
        <Group left={GroupWidth}>
          <GridColumns width={width} height={height} scale={valueScale} stroke={gridLineColor} />
          <BarStackHorizontal<Record<string, any>>
            data={data.items.map((d) => ({
              categoryLabel: d.label,
              categoryValue: d.categoryValue,
              ...d.values,
              ...data.series.reduce(
                (acc, series) => ({
                  ...acc,
                  [series.key]: d.values[series.key] ?? 0,
                }),
                {},
              ),
            }))}
            keys={data.series.map(({ key }) => key)}
            y={(d) => d.categoryValue}
            width={width}
            xScale={valueScale}
            yScale={categoryScale}
            color={(x) => colorScale(x.toString())}>
            {(barStacks) =>
              barStacks.map((barStack, barStackIdx) =>
                barStack.bars
                  .filter((bar) => bar.width > 0)
                  .map((bar) => (
                    <BarRounded
                      radius={BarRadius}
                      key={`barstack-horizontal-${barStack.index}-${bar.index}`}
                      x={bar.x}
                      y={bar.y}
                      width={bar.width}
                      height={bar.height}
                      fill={bar.color}
                      right={barStackIdx === barStacks.length - 1}
                      onMouseMove={(e) =>
                        handleMouseOver(e, {
                          value: Number(bar.bar.data[bar.key]),
                          valueKey: bar.key.toString(),
                          categoryLabel: bar.bar.data.categoryLabel,
                          categoryValue: bar.bar.data.categoryValue,
                          valueLabel:
                            data.series.find(({ key }) => key === bar.key)?.label ??
                            bar.key.toString(),
                          color: bar.color,
                        })
                      }
                      onMouseOut={hideTooltip}
                      onClick={(e) => {
                        const item = data.items.at(bar.index);
                        if (item !== undefined) {
                          onCategroryClick?.(e, item.key, item.categoryValue);
                        }
                      }}
                    />
                  )),
              )
            }
          </BarStackHorizontal>
          <AxisLeft
            scale={categoryScale}
            stroke={gridLineColor}
            hideTicks
            tickLabelProps={{
              fill: '#FFF',
              fontSize: 12,
              textAnchor: 'end',
              width: 100,
              scaleToFit: 'shrink-only',
            }}
          />
        </Group>
      </svg>
    </div>
  );
};
