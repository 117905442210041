import { useEffect, useMemo, useState } from 'react';
import classnames from 'classnames';
import { first, last } from 'lodash';
import { format, isSameDay, parseISO, startOfDay, startOfMonth, startOfWeek } from 'date-fns';
import { Link } from 'react-router-dom';

import { useTrackEvent } from '@/lib/analytics';
import { MetricKind, useMetricDataQuery, timePrecisionToGql } from '@/graphql';
import { TimePrecision } from '@/lib/types';
import { useAccountTimezone, useBuildAccountUrl } from '@/lib/accounts/context';
import { buildMetricExplorationUrl } from '@/explore/utils';

import { Metric, MetricValues, Point } from '../../explore/types';
import { MetricIcon } from '../service-icon';
import { Loader } from '../../components/loader';
import { Select, SelectOptions } from '../form/select';

import ComparedTimeSeriesChart from './metric-chart';
import {
  formatMetricValue,
  formatPoints,
  getDateFnsFormatForPeriodInMetrics,
  getFormattedDateForPeriodInMetrics,
} from './utils';

import { Icon } from '../icon';

import styles from './metric-tile.module.scss';

function getComparisonLsKey(metricId: string) {
  return `compare_metric_${metricId}_with_id`;
}

function formatLastAggPeriod(aggPeriod: TimePrecision) {
  switch (aggPeriod) {
    case TimePrecision.Daily:
      return 'Today';
    case TimePrecision.Weekly:
      return 'This week';
    case TimePrecision.Monthly:
      return 'This month';
  }
}

function periodEndByAggregation(aggPeriod: TimePrecision) {
  const now = new Date();
  switch (aggPeriod) {
    case TimePrecision.Daily:
      return startOfDay(now);
    case TimePrecision.Weekly:
      // TODO: Use locale to determine week start. But must be synced with server side metrics.
      return startOfWeek(now, { weekStartsOn: 1 });
    case TimePrecision.Monthly:
      return startOfMonth(now);
  }
}

function formatPeriod(options: {
  kind: string;
  points: Point[];
  isCumulative: boolean;
  aggPeriod: TimePrecision;
}) {
  const earliestPoint = first(options.points);
  const latestPoint = last(options.points);

  if (earliestPoint === undefined || latestPoint === undefined) {
    return '';
  }
  const earliestDate = parseISO(earliestPoint.date);
  const latestDate = parseISO(latestPoint.date);

  const periodEndsNow = isSameDay(periodEndByAggregation(options.aggPeriod), latestDate);

  if (options.kind === MetricKind.AbsoluteValue) {
    return 'Latest known';
  }

  if (options.isCumulative && options.kind === MetricKind.NewValue) {
    if (periodEndsNow) {
      const dateFormat = !isSameDay(earliestDate, startOfMonth(earliestDate))
        ? 'MMMM do yyyy'
        : getDateFnsFormatForPeriodInMetrics(options.aggPeriod);

      return `Since ${format(earliestDate, dateFormat)}`;
    }
    return `Selected time range`;
  }

  if (periodEndsNow) {
    return formatLastAggPeriod(options.aggPeriod);
  } else if (latestPoint !== undefined) {
    return format(latestDate, getDateFnsFormatForPeriodInMetrics(options.aggPeriod));
  }
  return '';
}

type OtherMetric = Metric;

interface MetricTileProps {
  metric: Metric;
  accountId: string;
  aggPeriod: TimePrecision;
  startDate: Date;
  endDate: Date;
  isCumulative?: boolean;
  color?: string;
  allMetrics: OtherMetric[];
  fullWidth?: boolean;
  modelNames: { modelId: string; name: string }[];
}

export const MetricTile = (props: MetricTileProps) => {
  const {
    metric,
    accountId,
    aggPeriod,
    startDate,
    endDate,
    isCumulative = false,
    allMetrics,
    fullWidth,
  } = props;
  const [compareMetric, setCompareMetric] = useState<OtherMetric | null>(null);
  const [isChangingCompareMetric, setIsChangingCompareMetric] = useState(false);

  const buildAccountUrl = useBuildAccountUrl();
  const trackEvent = useTrackEvent();
  const timezone = useAccountTimezone();

  const { data, loading, error } = useMetricDataQuery({
    variables: {
      accountId,
      metricId: metric.metricId,
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
      aggregation: timePrecisionToGql(aggPeriod),
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  const { data: compareData, loading: loadingCompare } = useMetricDataQuery({
    variables: {
      accountId,
      metricId: compareMetric?.metricId ?? '',
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
      aggregation: timePrecisionToGql(aggPeriod),
    },
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-first',
    skip: !compareMetric,
  });

  useEffect(() => {
    const lsCompareWith = localStorage.getItem(getComparisonLsKey(metric.metricId));
    const newCompareMetric = allMetrics.find(
      (otherMetric) => otherMetric.metricId === lsCompareWith,
    );
    if (newCompareMetric) {
      setCompareMetric(newCompareMetric);
    }
  }, [metric, allMetrics]);

  useEffect(() => {
    const lsKey = getComparisonLsKey(metric.metricId);
    if (compareMetric === null) {
      localStorage.removeItem(lsKey);
    } else {
      localStorage.setItem(lsKey, compareMetric.metricId);
    }
  }, [compareMetric, metric]);

  const values: MetricValues = data?.account?.metric?.values ?? [];
  const compareValues: MetricValues = compareData?.account?.metric?.values ?? [];

  const formattedPoints = formatPoints(metric, values, isCumulative);
  const lastPoint = last(formattedPoints) ?? null;

  const compareMetricPoints = compareMetric
    ? formatPoints(compareMetric, compareValues, isCumulative)
    : null;

  const compareOptions = allMetrics
    .filter((otherMetric) => otherMetric.metricId !== metric.metricId)
    .map((otherMetric) => ({ value: otherMetric.metricId, label: otherMetric.name }));

  const options: SelectOptions = [
    ...(compareMetric === null ? [{ value: '', label: 'Select metric...' }] : []),
    { label: 'Metrics', options: compareOptions },
    ...(compareMetric !== null ? [{ value: '', label: 'Remove comparison' }] : []),
  ];

  const showError = (values.length === 0 || error !== undefined) && !loading;

  const chartData = useMemo(() => {
    const mergedData = formattedPoints.map((point) => ({
      date: point.date,
      value: point.value,
      compareValue:
        compareMetricPoints?.find((compareMetricPoint) => compareMetricPoint.date === point.date)
          ?.value ?? null,
    }));
    // Avoid showing a single dot
    return mergedData.length === 1 ? [mergedData[0], mergedData[0]] : mergedData;
  }, [formattedPoints, compareMetricPoints]);

  return (
    <div
      style={{ padding: 0 }}
      className={classnames(
        styles.metric,
        { [styles.fullWidth]: fullWidth },
        showError && styles.hasError,
      )}>
      <div className={styles.loading}>
        {loading && <Loader />}
        {!loading && loadingCompare && <Loader />}
      </div>
      <div className={styles.metricMain}>
        <h4 className={styles.tileHeader}>
          <MetricIcon
            service={metric.services}
            metricType={metric.metricType}
            className={styles.serviceIcon}
          />
          <Link to={buildAccountUrl(buildMetricExplorationUrl(metric.metricId))}>
            {metric.name}
          </Link>
          {isChangingCompareMetric && (
            <div className={styles.compareDropdown}>
              Add metric for comparison
              <div style={{ display: 'flex', alignItems: 'stretch', marginTop: 10 }}>
                <Select
                  options={options}
                  value={compareMetric?.metricId}
                  onChange={(newOption) => {
                    if (newOption === '') {
                      trackEvent('Metric comparison removed', {
                        metricId: metric.metricId,
                      });
                      setCompareMetric(null);
                    } else {
                      trackEvent('Metric comparison added', {
                        metricId: metric.metricId,
                        comparingToMetricId: newOption,
                      });

                      const selectedMetric = allMetrics.find((m) => m.metricId === newOption);
                      if (selectedMetric === undefined) {
                        return;
                      }
                      setCompareMetric(selectedMetric);
                    }
                    setIsChangingCompareMetric(false);
                  }}
                  className={styles.compareSelect}
                  size="large"
                />
              </div>
            </div>
          )}
          <div
            className={classnames(styles.openCompareBtn, {
              [styles.isComparing]: compareMetric !== null,
            })}
            onClick={() => {
              const newVal = !isChangingCompareMetric;
              if (newVal) {
                trackEvent('Metric comparison options opened', {
                  metricId: metric.metricId,
                });
              } else {
                trackEvent('Metric comparison options closed', {
                  metricId: metric.metricId,
                });
              }
              setIsChangingCompareMetric(newVal);
            }}>
            vs
          </div>
        </h4>
        {lastPoint !== null && !showError && (
          <div className={styles.summary}>
            <span className={styles.label}>
              {formatPeriod({
                kind: metric.kind,
                points: formattedPoints,
                isCumulative,
                aggPeriod,
              })}
            </span>
            <span className={styles.value}>{formatMetricValue(metric, lastPoint.value)}</span>
          </div>
        )}
      </div>
      {showError ? (
        <div className={styles.errorContainer}>
          <Icon name="CloudDrizzle" size={24} color={styles.error} />
          <span className={styles.errorText}>Something went wrong, please try again later.</span>
        </div>
      ) : (
        <div className={styles.chartContainer}>
          <ComparedTimeSeriesChart
            data={chartData}
            formatDate={(date: Date) =>
              getFormattedDateForPeriodInMetrics(date, aggPeriod, timezone)
            }
            formatValue={(value: number) => formatMetricValue(metric, value)}
            formatCompareValue={(value: number) =>
              compareMetric ? formatMetricValue(compareMetric, value) : '-'
            }
            name={metric.name}
            compareName={compareMetric?.name}
            color={styles.purple}
            compareColor="#8AC7FF"
            onMouseEnter={() => {
              trackEvent('MetricTile chart hover', {
                metricId: metric.metricId,
                name: metric.name,
                metricType: metric.metricType,
                services: metric.services,
                aggPeriod,
                compareMetricId: compareMetric?.metricId,
                compareMetricType: compareMetric?.metricType,
                compareMetricService: compareMetric?.services,
              });
            }}
          />
        </div>
      )}
    </div>
  );
};
