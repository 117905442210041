import { startTransition, useMemo, useState } from 'react';
import { DayPicker } from 'react-day-picker';
import { format, isValid, subMonths, endOfDay, startOfMonth, differenceInDays } from 'date-fns';

import { useTrackEvent } from '@/lib/analytics';
import { Select } from '@/components/form/select';
import { Button } from '@/components/button';
import { CheckboxWithLabel } from '@/components/form/checkbox';
import { Input } from '@/components/form/input';
import { TimePrecision, TimeRange } from '@/lib/types';
import { DateRangeOptions, getFromDate, getToDate } from '@/lib/date';

import styles from './metric-time-options.module.scss';

export function useMetricTimeOptions(defaultValues?: {
  aggPeriod?: TimePrecision;
  dateRangePreset?: TimeRange;
}) {
  const [aggPeriod, setAggPeriod] = useState<TimePrecision>(
    defaultValues?.aggPeriod ?? TimePrecision.Monthly,
  );
  const [isCumulative, setIsCumulative] = useState(false);
  const [dateRangePreset, setDateRangePreset] = useState<TimeRange>(
    defaultValues?.dateRangePreset ?? '1y',
  );
  const defaultFrom = useMemo(() => {
    if (dateRangePreset === 'custom') {
      return startOfMonth(subMonths(new Date(), 3));
    }
    return getFromDate(dateRangePreset, aggPeriod);
  }, [dateRangePreset, aggPeriod]);
  const defaultTo = useMemo(() => {
    if (dateRangePreset === 'custom') {
      return new Date();
    }
    return getToDate(dateRangePreset);
  }, [dateRangePreset]);
  const [fromDate, setFromDate] = useState<Date>(defaultFrom);
  const [toDate, setToDate] = useState<Date>(defaultTo);

  return {
    aggPeriod,
    setAggPeriod,
    isCumulative,
    setIsCumulative,
    fromDate,
    setFromDate,
    toDate,
    setToDate,
    dateRangePreset,
    setDateRangePreset,
  };
}

interface MetricTimeOptionsProps {
  aggPeriod: TimePrecision;
  setAggPeriod: (newVal: TimePrecision) => void;
  isCumulative: boolean;
  setIsCumulative: (newVal: boolean) => void;
  fromDate: Date;
  setFromDate: (newVal: Date) => void;
  setToDate: (newVal: Date) => void;
  toDate: Date;
  dateRangePreset: TimeRange;
  setDateRangePreset: (newVal: TimeRange) => void;
}

export const MetricTimeOptions = (props: MetricTimeOptionsProps) => {
  const {
    aggPeriod,
    setAggPeriod,
    isCumulative,
    setIsCumulative,
    fromDate,
    setFromDate,
    toDate,
    setToDate,
    dateRangePreset,
    setDateRangePreset,
  } = props;
  const [timeRangePreset, setTimeRangePreset] = useState<TimeRange>(dateRangePreset);
  const [isChangingTimeRange, setIsChangingTimeRange] = useState(false);
  const [isCumulativeChecked, setIsCumulativeChecked] = useState(isCumulative);

  const trackEvent = useTrackEvent();

  return (
    <>
      <div className={styles.timeOptions}>
        <div>
          <div className={styles.timeIndicatorLabel}>Time range</div>
          <Select
            options={DateRangeOptions}
            value={timeRangePreset}
            onChange={(value: TimeRange) => {
              setTimeRangePreset(value);

              if (value === 'custom' && dateRangePreset !== 'custom') {
                setIsChangingTimeRange(true);
              } else {
                const fromDate = getFromDate(value, aggPeriod);
                const toDate = getToDate(value);

                setIsChangingTimeRange(false);
                setDateRangePreset(value);
                setFromDate(fromDate);
                setToDate(toDate);
                setAggPeriod(getNewAggPeriod(aggPeriod, fromDate, toDate));
              }
            }}
            size="large"
          />
        </div>
        {dateRangePreset === 'custom' && (
          <div>
            <div className={styles.timeIndicatorLabel}>&nbsp;</div>
            <Button variant="outlined" onClick={() => setIsChangingTimeRange(true)}>
              Change...
            </Button>
          </div>
        )}
        <div>
          <div className={styles.timeIndicatorLabel}>Granularity</div>
          <Select
            options={[
              { value: TimePrecision.Daily, label: 'Daily' },
              { value: TimePrecision.Weekly, label: 'Weekly' },
              { value: TimePrecision.Monthly, label: 'Monthly' },
            ]}
            value={aggPeriod}
            onChange={(value: TimePrecision) => {
              setAggPeriod(value);
              trackEvent('Explore aggregation changed', { aggPeriod: value });
            }}
            size="large"
          />
        </div>
        <span>
          <div style={{ marginTop: 24, height: 40, display: 'flex', alignItems: 'center' }}>
            <CheckboxWithLabel
              checked={isCumulativeChecked}
              onChange={({ checked }) => {
                if (checked === true) {
                  trackEvent('Explore cumulative checked');
                } else {
                  trackEvent('Explore cumulative unchecked');
                }

                setIsCumulativeChecked(checked);

                startTransition(() => {
                  setIsCumulative(checked);
                });
              }}
              id="cumulativeCheck">
              Cumulative
            </CheckboxWithLabel>
          </div>
        </span>
      </div>

      {isChangingTimeRange && (
        <CustomTimeRangePicker
          defaultFrom={fromDate}
          defaultTo={toDate}
          onCancel={() => {
            setIsChangingTimeRange(false);
            setTimeRangePreset(dateRangePreset);
          }}
          onApply={(fromDate, toDate) => {
            if (
              fromDate === undefined ||
              toDate === undefined ||
              !isValid(fromDate) ||
              !isValid(toDate)
            ) {
              throw new Error('Invalid custom date range');
            }
            setDateRangePreset(timeRangePreset);
            setFromDate(fromDate);
            setToDate(toDate);

            setAggPeriod(getNewAggPeriod(aggPeriod, fromDate, toDate));
            setIsChangingTimeRange(false);
          }}
        />
      )}
    </>
  );
};

interface CustomTimeRangePickerProps {
  onApply: (fromDate?: Date, toDate?: Date) => void;
  onCancel: () => void;
  defaultFrom: Date;
  defaultTo: Date;
}

export const CustomTimeRangePicker = (props: CustomTimeRangePickerProps) => {
  const { onApply, onCancel, defaultFrom, defaultTo } = props;
  const [fromDate, setFromDate] = useState<Date | undefined>(defaultFrom);
  const [fromMonth, setFromMonth] = useState<Date>(defaultFrom);
  const [toDate, setToDate] = useState<Date | undefined>(defaultTo);
  const [toMonth, setToMonth] = useState<Date>(defaultTo);

  return (
    <div className={styles.customTimeRangePickerWrapper}>
      <div className={styles.customTimeRangePicker}>
        <div>
          <div className={styles.customTimeRangePickerLabel}>
            From
            <Input
              type="text"
              size="regular"
              value={format(fromDate as Date, 'y-MM-dd')}
              onChange={(e) => {
                const date = new Date(e.target.value);

                if (isValid(date) && date < (toDate as Date)) {
                  setFromDate(date);
                  setFromMonth(date);
                }
              }}
            />
          </div>
          <DayPicker
            fromYear={2015}
            toDate={toDate}
            month={fromMonth}
            onMonthChange={(month) => setFromMonth(month)}
            defaultMonth={defaultFrom}
            mode="single"
            onSelect={setFromDate}
            selected={fromDate}
            weekStartsOn={1}
            required
          />
        </div>
        <div>
          <div className={styles.customTimeRangePickerLabel}>
            To
            <Input
              type="text"
              size="regular"
              value={format(toDate as Date, 'y-MM-dd')}
              onChange={(e) => {
                const date = new Date(e.target.value);

                if (isValid(date) && date > (fromDate as Date)) {
                  setToDate(endOfDay(date));
                  setToMonth(date);
                }
              }}
            />
          </div>
          <DayPicker
            fromDate={fromDate}
            toDate={new Date()}
            month={toMonth}
            onMonthChange={(month) => setToMonth(month)}
            mode="single"
            onSelect={(date: Date | undefined) => setToDate(endOfDay(date as Date))}
            selected={toDate}
            weekStartsOn={1}
            required
          />
        </div>
      </div>
      <div>
        <Button
          onClick={() => onApply(fromDate, toDate)}
          disabled={!isValid(fromDate) || !isValid(toDate)}>
          Apply
        </Button>
        <Button onClick={() => onCancel()} variant="outlined">
          Cancel
        </Button>
      </div>
    </div>
  );
};

function getNewAggPeriod(currentAggPeriod: TimePrecision, fromDate: Date, toDate: Date) {
  const numDays = differenceInDays(toDate as Date, fromDate as Date);
  if (numDays < 21) {
    return TimePrecision.Daily;
  } else if (numDays <= 90 && currentAggPeriod === TimePrecision.Monthly) {
    return TimePrecision.Weekly;
  } else if (numDays >= 365 && currentAggPeriod === TimePrecision.Daily) {
    return TimePrecision.Monthly;
  } else if (numDays >= 90 && currentAggPeriod === TimePrecision.Daily) {
    return TimePrecision.Weekly;
  } else if (numDays >= 365 && currentAggPeriod === TimePrecision.Weekly) {
    return TimePrecision.Weekly;
  }

  return currentAggPeriod;
}
