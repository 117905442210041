import { FormEvent, useState } from 'react';
import { createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';
import { nth } from 'lodash';

import { auth } from '@/lib/firebase-init';
import { Card } from '@/components/gradient-layout/cards';
import { Button } from '@/components/button';
import { useToastContext } from '@/components/toast';
import { Checkbox } from '@/components/form/checkbox';
import { Input } from '@/components/form/input';

import commonStyles from '../public.module.scss';

interface EmailSignupCardProps {
  loading: boolean;
  onStartSignup: (email: string) => void;
}

export const EmailSignupCard = ({ loading, onStartSignup }: EmailSignupCardProps) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isShowingPassword, setIsShowingPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const addToast = useToastContext();

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    try {
      setIsSubmitting(true);
      const credential = await createUserWithEmailAndPassword(auth, email, password);
      const user = credential.user;
      if (user.email === null) {
        throw new Error('Email is null');
      }
      await sendEmailVerification(user);
      onStartSignup(user.email);
    } catch (error) {
      setIsSubmitting(false);
      switch ((error as any).code) {
        case 'auth/email-already-in-use':
          addToast({
            title: 'Email Already in Use',
            content: () => 'The email address is already in use by another account.',
            kind: 'error',
          });
          break;
        case 'auth/invalid-email':
          addToast({
            title: 'Invalid Email',
            content: () => 'The email address is invalid.',
            kind: 'error',
          });
          break;
        case 'auth/password-does-not-meet-requirements':
          addToast({
            title: 'Password does not meet requirements',
            content: () =>
              nth((error as any)?.message?.match(/\[(.*?)\]/), 1) ??
              'The password does not meet the requirements.',
            kind: 'error',
          });
          break;
        case 'auth/weak-password':
          addToast({
            title: 'Weak Password',
            content: () => 'The password is too weak.',
            kind: 'error',
          });
          break;
        default:
          addToast({
            title: 'Authentication Error',
            content: () => 'Something went wrong. Please contact us if the problem persists.',
            kind: 'error',
          });
      }
      throw e;
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Card>
        <div className={commonStyles.title}>Continue with email</div>

        <div className={commonStyles.fields}>
          <Input
            required
            type="email"
            size="large"
            placeholder="Work email"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />
          <Input
            required
            type={isShowingPassword ? 'text' : 'password'}
            size="large"
            placeholder="Password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          />
          <label className={commonStyles.showPassword}>
            <Checkbox
              checked={isShowingPassword}
              onChange={() => setIsShowingPassword(() => !isShowingPassword)}
            />{' '}
            Show password
          </label>
        </div>

        <Button
          type="submit"
          className={commonStyles.button}
          size="large"
          loading={isSubmitting || loading}>
          Continue
        </Button>
      </Card>
    </form>
  );
};
