import { isString } from 'lodash';

import { exhaustiveCheck } from '@/lib/exhaustive';

import { Cell, Exploration } from '../types';
import { generateExplorationId, generateCellId } from '../utils';
import { starterCode } from '../exploration/python-cell/python';

export type BuildType = 'funnel' | 'cohort' | 'variable' | 'text' | 'sql' | 'python';

const buildExploration = (name: string, cell: Cell): Exploration => ({
  explorationId: generateExplorationId(),
  name: name,
  labels: {},
  parameters: [],
  view: { cells: [cell] },
});

export const buildExplorationByType = (
  type: string | BuildType,
  baseModelId: string,
): Exploration => {
  if (type === 'funnel') {
    return buildExploration('Funnel', {
      id: generateCellId(),
      kind: 'funnel',
      title: 'My funnel',
      pipeline: {
        baseModelId,
        operations: [
          {
            operation: 'funnel',
            parameters: {
              steps: [
                { fields: [''], sortKey: '', pipeline: { baseModelId: '', operations: [] } },
                { fields: [''], sortKey: '', pipeline: { baseModelId: '', operations: [] } },
              ],
              property: { name: 'Funnel', key: 'funnel' },
              groups: [],
            },
          },
        ],
      },
    });
  }

  if (type === 'cohort') {
    return buildExploration('Cohort', {
      id: generateCellId(),
      kind: 'cohort',
      title: 'Retention chart',
      pipeline: { baseModelId, operations: [] },
    });
  }

  if (type === 'variable') {
    return buildExploration('Variable', {
      id: generateCellId(),
      kind: 'variable',
      definition: { key: 'My variable 1', kind: 'string', defaultValue: '' },
    });
  }

  if (type === 'text') {
    return buildExploration('Text', { id: generateCellId(), kind: 'text', title: '', content: '' });
  }

  if (type === 'sql') {
    return buildExploration('SQL', {
      id: generateCellId(),
      kind: 'sql',
      title: 'SQL',
      pipeline: { baseModelId, operations: [{ operation: 'sql', parameters: { sql: '' } }] },
    });
  }

  if (type === 'python') {
    return buildExploration('Python', {
      id: generateCellId(),
      kind: 'python',
      title: 'Python code',
      pipeline: {
        baseModelId,
        operations: [{ operation: 'python', parameters: { code: starterCode } }],
      },
    });
  }

  if (isString(type)) {
    throw new Error(`Unknown build type: ${type}`);
  }

  exhaustiveCheck(type);
};
