import styles from './radio-button.module.scss';

export const RadioButton = ({
  name,
  value,
  isChecked,
  label,
  onChange,
}: {
  name: string;
  value?: string;
  isChecked?: boolean;
  label: string;
  onChange: (value?: string) => void;
}) => {
  return (
    <label className={styles.radioButton}>
      <input
        type="radio"
        name={name}
        value={value}
        checked={isChecked === true}
        onChange={() => onChange(value)}
      />{' '}
      {label}
    </label>
  );
};
