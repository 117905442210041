import { useRef } from 'react';
import classNames from 'classnames';
import { first, nth } from 'lodash';
import { Token, TokenType } from '@gosupersimple/penguino';

import { useClientRect } from '@/lib/hooks/use-client-rect';

import { Tooltip } from '../../tooltip';

import styles from './penguino-input.module.scss';
import syntaxStyles from './syntax-highlighting.module.scss';

export interface SyntaxIssue {
  message: string;
  line: number;
  start: number;
  end: number;
}

const isWithinSyntaxIssue = (token: Token, issues: SyntaxIssue[]) =>
  issues.some((i) => i.line === token.line && token.start < i.end && token.end > i.start);

interface SyntaxHighlightingProps {
  tokens: Token[];
  issues: SyntaxIssue[];
  showIssueMessages: boolean;
  preRef: React.RefObject<HTMLPreElement>;
  className?: string;
}

export const SyntaxHighlighting = ({
  tokens,
  issues,
  preRef,
  showIssueMessages,
  className,
}: SyntaxHighlightingProps) => {
  const issueRef = useRef<HTMLSpanElement>(null);
  const preRect = useClientRect(preRef, [tokens, issues, preRef]);
  const issueRect = useClientRect(issueRef, [tokens, issues, issueRef]);

  const minY = preRect?.top ?? 0;
  const maxY = preRect?.bottom ?? 0;
  const tooltipPos = {
    top: Math.max(minY, Math.min(maxY, issueRect?.top ?? 0)),
    left: issueRect?.left ?? 0,
  };

  const lineBreak = nth(tokens, tokens.length - 2);
  const firstIssueToken = tokens.find((t) => isWithinSyntaxIssue(t, issues));
  tokens =
    lineBreak?.lexeme !== '\n'
      ? tokens
      : [
          // Add a space so the <pre> tag wouldn't hide the empty last line.
          ...tokens.slice(0, tokens.length - 1),
          new Token(
            TokenType.Whitespace,
            ' ',
            ' ',
            lineBreak.line,
            lineBreak.end,
            lineBreak.end + 1,
          ),
          tokens[tokens.length - 1],
        ];
  return (
    <>
      <pre className={classNames(styles.highlightedOutput, className)} ref={preRef}>
        {tokens.map((token, i) => (
          <span
            key={i}
            ref={token === firstIssueToken ? issueRef : undefined}
            className={classNames({
              [syntaxStyles.identifierToken]: token.type === TokenType.Identifier,
              [syntaxStyles.numberToken]: token.type === TokenType.Number,
              [syntaxStyles.stringToken]: token.type === TokenType.String,
              [syntaxStyles.punctuationToken]: token.type === TokenType.Comma,
              [syntaxStyles.booleanToken]:
                token.type === TokenType.True || token.type === TokenType.False,
              [syntaxStyles.nullToken]: token.type === TokenType.Null,
              [syntaxStyles.error]: isWithinSyntaxIssue(token, issues),
            })}>
            {token.lexeme}
          </span>
        ))}
      </pre>
      {showIssueMessages && issues.length > 0 && (
        <Tooltip
          size="compact"
          color="error"
          offsetLeft={0}
          offsetTop={-28}
          {...tooltipPos}
          bound="x"
          boundToRef={preRef}
          className={styles.errorTooltip}>
          {first(issues)?.message ?? 'Syntax error'}
        </Tooltip>
      )}
    </>
  );
};
