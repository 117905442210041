import { useCopyToClipboard } from 'usehooks-ts';

import { Modal } from '@/components/modal';
import { Panel } from '@/components/panel';
import { Button } from '@/components/button';
import { Icon } from '@/components/icon';
import { Loader } from '@/components/loader';
import { ErrorBanner } from '@/components/banner';
import { useAccountContext } from '@/lib/accounts/context';
import { useToastContext } from '@/components/toast';

import { mapSort, useExplorationDescribeQueryQuery } from '../../../graphql';
import { dereferencePipeline } from '../../pipeline/utils';
import { Exploration, Pipeline, Sort } from '../../types';
import { useExplorationContext } from '../exploration-context';
import { filterVariablesForPipeline } from '../../utils/variable';

import style from './sql-preview.module.scss';

interface SqlPreviewProps {
  pipeline: Pipeline;
  exploration: Exploration;
  sort?: Sort;
  onClose: () => void;
}

export const SqlPreview = (props: SqlPreviewProps) => {
  const { account } = useAccountContext();
  const { getVariables } = useExplorationContext();
  const [, copyToClipboard] = useCopyToClipboard();
  const addToast = useToastContext();

  const sort = mapSort(props.sort);
  const pipeline = dereferencePipeline(props.pipeline, props.exploration);

  const { data, loading, error } = useExplorationDescribeQueryQuery({
    variables: {
      accountId: account.accountId,
      baseModelId: pipeline.baseModelId,
      pipeline: pipeline.operations,
      sort,
      variables: filterVariablesForPipeline(pipeline, getVariables()),
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-first',
  });

  const sql = data?.account?.describeQuery.sql;

  const handleCopyToClipboard = () => {
    copyToClipboard(sql ?? '');

    addToast({
      title: 'SQL copied!',
      content: () => 'SQL copied to clipboard',
      kind: 'success',
    });
  };

  return (
    <Modal onClose={props.onClose} closeOnClickAway closeOnEsc>
      <Panel title="SQL for this block">
        <div className={style.container}>
          {loading === true ? (
            <div className={style.loader}>
              <Loader size="large" />
            </div>
          ) : error !== undefined ? (
            <ErrorBanner />
          ) : (
            <pre className={style.sql}>{sql}</pre>
          )}
          <div className={style.buttons}>
            <Button
              onClick={handleCopyToClipboard}
              icon={<Icon name="Clipboard" size={16} />}
              disabled={sql === undefined}>
              Copy to Clipboard
            </Button>
            <Button variant="gray" onClick={props.onClose}>
              Close
            </Button>
          </div>
        </div>
      </Panel>
    </Modal>
  );
};
