import { useState, useMemo, useCallback, useRef } from 'react';

import { CanvasPosition, CanvasBlock, PersistedOptions } from './types';

export interface CardStyles {
  width: string;
  height: string;
  transform: string;
  zIndex: number;
}

const elementsToIgnore = ['BUTTON', 'INPUT', 'SELECT', 'TEXTAREA'];

export const useCanvas = (initialCellIds: string[], initialOptions?: PersistedOptions) => {
  const [isCanvasView, setCanvasView] = useState(false);
  const [canvasDraggingCard, setCanvasDraggingCard] = useState<string | undefined>(undefined);
  const [canvasPosition, setCanvasPosition] = useState<CanvasPosition>({
    scale: initialOptions?.view.scale ?? 0.9,
    x: initialOptions?.view.x ?? 0,
    y: initialOptions?.view.y ?? -150,
  });
  const [canvasState, setCanvasState] = useState<CanvasBlock[]>(initialOptions?.blocks ?? []);
  const canvasRef = useRef<HTMLDivElement>(null);
  const cellRefs = useRef<Record<string, HTMLDivElement>>({});

  const initCellState = useCallback(
    (cellId: string) => {
      const savedPosition = canvasState.find((card) => card.id === cellId)?.position;
      if (savedPosition) {
        return {
          id: cellId,
          position: savedPosition,
        };
      }

      const cellRect = cellRefs.current?.[cellId]?.getBoundingClientRect();
      const canvasRect = canvasRef.current?.getBoundingClientRect();
      const defaultWidth = cellRect?.width ? cellRect.width / canvasPosition.scale : 300;
      const defaultHeight = cellRect?.height ? cellRect.height / canvasPosition.scale : 200;
      const defaultX = cellRect?.x
        ? cellRect.x / canvasPosition.scale
        : canvasRect
          ? canvasRect.top / 2 - defaultHeight / 2
          : 0;
      const defaultY = cellRect?.y
        ? cellRect.y / canvasPosition.scale
        : canvasRect
          ? canvasRect.left / 2 - defaultWidth / 2
          : 0;

      return {
        id: cellId,
        position: {
          x: defaultX,
          y: defaultY,
          width: defaultWidth,
          height: defaultHeight,
          zIndex: 10,
        },
      };
    },
    [canvasState, canvasPosition.scale],
  );

  const initCanvasLayout = useCallback(() => {
    const layout = initialCellIds.map((cellId) => initCellState(cellId));
    setCanvasState(layout);
  }, [initialCellIds, initCellState]);

  const canvasCardStyles = useMemo(() => {
    const cards: Record<string, CardStyles> = {};

    canvasState.forEach((card) => {
      cards[card.id] = {
        width: `${card.position.width}px`,
        height: `${card.position.height}px`,
        transform: `translate(${card.position.x}px, ${card.position.y}px)`,
        zIndex: card.position.zIndex,
      };

      cards.default = {
        width: 'auto',
        height: '50vw',
        transform: 'none',
        zIndex: 10,
      };
    });

    return cards;
  }, [canvasState]);

  const handleCanvasChange = useCallback((newState: CanvasBlock[]) => {
    setCanvasState(newState);
  }, []);

  const handlePositionChange = useCallback((newPos: CanvasPosition) => {
    setCanvasPosition((prev) => ({ ...prev, ...newPos }));
  }, []);

  const handleCanvasSwitch = useCallback(
    (value: string) => {
      if (value === 'canvas') {
        initCanvasLayout();
      }
      setCanvasView(value === 'canvas');
    },
    [initCanvasLayout],
  );

  const handleCardDragging = useCallback(
    (id: string, offset: { offsetX: number; offsetY: number }) => {
      setCanvasState((prev) => {
        const cards = prev;
        const maxZIndex = Math.max(...cards.map((card) => card.position.zIndex));

        if (!cards.find((card) => card.id === id)) {
          cards.push(initCellState(id));
        }

        const mappedCards = cards.map((card) =>
          card.id === id
            ? {
                ...card,
                dragOffset: offset,
                position: { ...card.position, zIndex: maxZIndex + 1 },
              }
            : { ...card, dragOffset: undefined },
        );

        return mappedCards;
      });
    },
    [initCellState],
  );

  const handleMouseDown = useCallback(
    (e: React.MouseEvent<Element, MouseEvent>, cellId: string) => {
      if (e.target instanceof HTMLElement && elementsToIgnore.includes(e.target.tagName)) {
        return;
      }

      e.preventDefault();
      e.stopPropagation();
      const rect = e.currentTarget.getBoundingClientRect();
      const offsetX = e.clientX - rect.left;
      const offsetY = e.clientY - rect.top;
      handleCardDragging(cellId, { offsetX, offsetY });
      setCanvasDraggingCard(cellId);
    },
    [handleCardDragging, setCanvasDraggingCard],
  );

  return {
    isCanvasView,
    canvasState,
    canvasPosition,
    canvasDraggingCard,
    cellRefs,
    canvasRef,
    canvasCardStyles,
    setCanvasDraggingCard,
    handleCanvasChange,
    handlePositionChange,
    handleCanvasSwitch,
    handleMouseDown,
  };
};
