import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import {
  OnboardingStateKey,
  useCreateConnectionMutation,
  useTestConnectionLazyQuery,
  useUpdateOnboardingStateMutation,
} from '@/graphql';
import { useSelectedAccount } from '@/lib/accounts/context';
import { useTrackEvent } from '@/lib/analytics';
import { useGAPageview } from '@/lib/ga/use-ga';
import { InlineButton } from '@/components/button';

import { Step, StepSection } from '../components';
import { Input } from '../../components/form/input';
import { RadioButton } from '../../components/form/radio-button';
import { Button } from '../../components/button';
import { TextArea } from '../../components/form/text-area';
import { useOnboardingStepCheck } from '../use-onboarding-step-check';
import { OnboardingStep } from '../types';

import styles from './step-database-setup.module.scss';
import formStyles from '../../components/form/form.module.scss';

type UrlConnectionConfig = { url: string };

interface UrlConnectionFieldsProps {
  title: string;
  placeholder: string;
  config: UrlConnectionConfig;
  onChange: (config: UrlConnectionConfig) => void;
}

const UrlConnectionFields = (props: UrlConnectionFieldsProps) => {
  const { title, placeholder, config, onChange } = props;
  return (
    <div className={classNames(formStyles.formRow, formStyles.rowVertical)}>
      <label className={formStyles.formLabel}>{title}</label>
      <Input
        className={styles.input}
        required
        autoFocus
        type="text"
        placeholder={placeholder}
        size="large"
        value={config?.url ?? ''}
        onChange={(e) => onChange({ url: e.target.value })}
      />
    </div>
  );
};

const BigQueryCredentialsPlaceholder = {
  type: 'service_account',
  project_id: 'customer-project',
  private_key_id: '<private key id>',
  private_key: '<private key contents>',
  client_email: 'service-account-anme@customer-project.iam.gserviceaccount.com',
  client_id: '1234567890',
};

type BigQueryConfig = { location: string; credentials: string };

interface BigQueryFieldsProps {
  config: BigQueryConfig;
  onChange: (config: BigQueryConfig) => void;
}

const BigQueryFields = ({ config, onChange }: BigQueryFieldsProps) => {
  return (
    <>
      <div className={classNames(formStyles.formRow, formStyles.rowVertical)}>
        <label className={formStyles.formLabel}>BigQuery credentials JSON</label>
        <TextArea
          className={styles.input}
          autoFocus
          required
          size="large"
          rows={7}
          value={config?.credentials ?? ''}
          onChange={(e) => onChange({ ...config, credentials: e.target.value })}
          placeholder={JSON.stringify(BigQueryCredentialsPlaceholder, null, 2)}
        />
      </div>
      <div className={classNames(formStyles.formRow, formStyles.rowVertical)}>
        <label className={formStyles.formLabel}>
          Location of Google datacenter, called region, or zone
        </label>
        <Input
          className={styles.input}
          required
          type="text"
          placeholder="e.g. EU"
          size="large"
          value={config?.location ?? ''}
          onChange={(e) => onChange({ ...config, location: e.target.value })}
        />
      </div>
    </>
  );
};

const CurrentStep: OnboardingStep = 'connection';

export const OnboardingDatabaseStep = () => {
  const {
    onboardingState,
    check: checkOnboardingState,
    loading: onboardingStateLoading,
  } = useOnboardingStepCheck(CurrentStep);

  const trackEvent = useTrackEvent();
  useGAPageview();
  const account = useSelectedAccount();

  useEffect(() => {
    trackEvent('Onboarding Connection Setup Opened', { accountId: account.accountId });
  }, [trackEvent, account.accountId]);

  const formRef = useRef<HTMLFormElement>(null);

  const [data, setData] = useState<
    | {
        type: 'postgres';
        config: UrlConnectionConfig;
      }
    | {
        type: 'mysql';
        config: UrlConnectionConfig;
      }
    | {
        type: 'bigquery';
        config: BigQueryConfig;
      }
  >({
    type: 'postgres',
    config: { url: '' },
  });
  const [error, setError] = useState<string | null>(null);

  const [testConnection, { loading: testing }] = useTestConnectionLazyQuery();
  const [createConnectionMutation, { loading: creating }] = useCreateConnectionMutation();
  const [updateOnboardingStateMutation, { loading: updatingOnboardingState }] =
    useUpdateOnboardingStateMutation();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setError(null);

    trackEvent('Onboarding Connection Setup Test', {
      accountId: account.accountId,
      type: data.type,
    });

    const testResult = await testConnection({
      variables: {
        accountId: account.accountId,
        type: data.type,
        properties: data.config,
      },
    });

    if (testResult?.data?.account?.testConnection.__typename === 'TestConnectionSuccess') {
      trackEvent('Onboarding Connection Setup Test Success', {
        accountId: account.accountId,
        type: data.type,
      });

      try {
        await createConnectionMutation({
          variables: {
            accountId: account.accountId,
            name: 'default',
            type: data.type,
            properties: data.config,
          },
        });

        trackEvent('Onboarding Connection Setup Create Success', {
          accountId: account.accountId,
          type: data.type,
        });

        checkOnboardingState();
      } catch (e) {
        trackEvent('Onboarding Connection Setup Create Failed', {
          accountId: account.accountId,
          type: data.type,
        });

        setError((e as Error).message);
      }
    } else if (testResult?.data?.account?.testConnection.__typename === 'TestConnectionFailed') {
      trackEvent('Onboarding Connection Setup Test Failed', {
        accountId: account.accountId,
        type: data.type,
        error: testResult?.data?.account?.testConnection.error,
      });

      setError(testResult?.data?.account?.testConnection.error);
    }
  };

  const handleClickSetupCall = async () => {
    await updateOnboardingStateMutation({
      variables: {
        accountId: account.accountId,
        key: OnboardingStateKey.HasPassedSetupCallStep,
        value: false,
      },
    });
    checkOnboardingState();
  };

  const handleTypeChange = (type: 'postgres' | 'mysql' | 'bigquery') => {
    type === 'postgres' || type === 'mysql'
      ? setData({ ...data, type, config: { url: 'url' in data.config ? data.config.url : '' } })
      : setData({ ...data, type, config: { location: '', credentials: '' } });
    setError(null);
  };

  return (
    <Step
      subTitle="Database setup"
      showInviteButton
      inviteText={
        <p>
          Invite a technical team member to set up the database for you.
          <br />
          They will see the same screen and it will take ~5minutes of their time.
        </p>
      }>
      <StepSection title="1. Create read-only access">
        <p>
          Supersimple only needs a read-only connection to your data warehouse.
          <br />
          In case you need to whitelist our IP addresses, we use 35.228.195.204 and 35.228.203.89.
        </p>
      </StepSection>
      <StepSection title="2. Connect to database">
        <form
          ref={formRef}
          className={classNames(styles.form, formStyles.formHorizontal)}
          onSubmit={handleSubmit}>
          <div className={formStyles.rowVertical}>
            <RadioButton
              name="databaseType"
              value="postgres"
              label="Postgres"
              isChecked={data.type === 'postgres'}
              onChange={() => handleTypeChange('postgres')}
            />
            <RadioButton
              name="databaseType"
              value="mysql"
              label="MySQL"
              isChecked={data.type === 'mysql'}
              onChange={() => handleTypeChange('mysql')}
            />
            <RadioButton
              name="databaseType"
              value="bigquery"
              label="BigQuery"
              isChecked={data.type === 'bigquery'}
              onChange={() => handleTypeChange('bigquery')}
            />
          </div>
          {data.type === 'postgres' ? (
            <UrlConnectionFields
              title="Postgres connection URL"
              placeholder="postgres://username:password@host:port/database"
              config={data.config}
              onChange={(config) => setData({ ...data, config })}
            />
          ) : data.type === 'mysql' ? (
            <UrlConnectionFields
              title="MySQL connection URL"
              placeholder="mysql://username:password@host:port/database"
              config={data.config}
              onChange={(config) => setData({ ...data, config })}
            />
          ) : data.type === 'bigquery' ? (
            <BigQueryFields
              config={data.config}
              onChange={(config) => setData({ ...data, config })}
            />
          ) : null}

          <p>
            <InlineButton
              onClick={() =>
                window.open(
                  'https://docs.supersimple.io/overview/getting-started',
                  '_blank',
                  'noopener noreferrer',
                )
              }>
              Read the documentation
            </InlineButton>
          </p>
        </form>
        {error !== null ? <div className={formStyles.error}>{error}</div> : null}
      </StepSection>
      <div className={styles.actions}>
        <Button
          variant="primary"
          size="large"
          onClick={() => formRef.current?.requestSubmit()}
          loading={testing || creating}
          disabled={updatingOnboardingState || onboardingStateLoading}>
          Test & Continue
        </Button>
        {onboardingState !== undefined && !onboardingState.hasBookedSetupCall ? (
          <>
            <div className={styles.or}>OR</div>
            <Button
              variant="outlined"
              size="large"
              onClick={handleClickSetupCall}
              loading={updatingOnboardingState || onboardingStateLoading}
              disabled={testing || creating}>
              Book an onboarding call
            </Button>
          </>
        ) : null}
      </div>
    </Step>
  );
};
