import { z } from 'zod';
import { first, pick } from 'lodash';
import { common } from '@gosupersimple/types';

import {
  CohortOperation,
  Exploration,
  Field,
  Fields,
  Model,
  Pipeline,
  PipelineStateRelation,
} from '../types';
import { EditCohortOptions, EditCohortState } from './types';
import { getCellByPipelineIdOrThrow } from '../exploration/utils';
import { dereferencePipeline, FieldGroup } from '../pipeline/utils';
import { getFinalState, PipelineStateContext } from '../pipeline/state';
import { getJoinKeyToSemanticModel, getRelationToSemanticModel } from '../model/utils';
import { fieldToOptionGrouped, fieldToOption } from '../edit-pipeline/utils';
import { getValidRelationTypesForOperation } from '../edit-pipeline/utils/relation';

export const getCohortOperation = (pipeline: Pipeline): CohortOperation | undefined => {
  const operation = first(pipeline.operations);

  if (operation?.operation !== 'cohort') {
    return undefined;
  }

  return operation;
};

export const getDefaultIdFieldKey = (fields: Fields, model: Model, models: Model[]) => {
  return (
    fields.find((field) => field.key === getJoinKeyToSemanticModel(model, models, 'User'))?.key ??
    fields.find((field) => field.key === getJoinKeyToSemanticModel(model, models, 'Account'))
      ?.key ??
    fields.find((field) => field.pk)?.key ??
    first(fields.filter((field) => field.key.toLowerCase().endsWith('id')))?.key ??
    first(fields)?.key
  );
};

export const getDefaultEventTimeKey = (fields: Fields, model: Model) =>
  serializeTimeKeyValue({
    fieldKey:
      fields.find((field) => field.key === model.semantics?.properties?.createdAt)?.key ??
      fields.find((field) => field.type === 'Date')?.key ??
      first(fields)?.key ??
      '',
  });

export const getDefaultCohortTimeKey = (model: Model, models: Model[]) => {
  const validRelationTypes = getValidRelationTypesForOperation('addRelatedColumn');
  const userRelation = getRelationToSemanticModel(model, models, 'User', validRelationTypes);
  const accountRelation = getRelationToSemanticModel(model, models, 'Account', validRelationTypes);
  const userModel = models.find((model) => model.modelId === userRelation?.modelId);
  const accountModel = models.find((model) => model.modelId === accountRelation?.modelId);
  const userCreatedAtField = userModel?.properties.find(
    (property) => property.key === userModel.semantics?.properties?.createdAt,
  );
  const accountCreatedAtField = accountModel?.properties.find(
    (property) => property.key === accountModel.semantics?.properties?.createdAt,
  );
  const userCreatedAtFieldOptionKey =
    userRelation === null || userCreatedAtField === undefined
      ? null
      : serializeTimeKeyValue({
          baseModelId: model.modelId,
          relationKey: userRelation?.key,
          fieldKey: userCreatedAtField?.key,
        });
  const accountCreatedAtFieldOptionKey =
    accountRelation === null || accountCreatedAtField === undefined
      ? null
      : serializeTimeKeyValue({
          baseModelId: model.modelId,
          relationKey: accountRelation?.key,
          fieldKey: accountCreatedAtField?.key,
        });
  return (
    userCreatedAtFieldOptionKey ??
    accountCreatedAtFieldOptionKey ??
    serializeTimeKeyValue({
      fieldKey:
        model.properties.find((property) => property.type === 'Date')?.key ??
        first(model.properties)?.key ??
        '',
    })
  );
};

export const getIdFields = (fields: Fields) => fields.filter(({ type }) => type !== 'Date');
export const getTimeKeyFields = (fields: Fields) => fields.filter(({ type }) => type === 'Date');

const timeKeyValueSchema = z.union([
  // Fields on related models
  z.object({
    baseModelId: z.string(),
    relationKey: z.string(),
    fieldKey: z.string(),
  }),
  // Fields on event model
  z.object({
    fieldKey: z.string(),
  }),
]);
type TimeKeyValue = z.infer<typeof timeKeyValueSchema>;
type RelationTimeKeyValue = Extract<TimeKeyValue, { relationKey: string }>;

export const serializeTimeKeyValue = (reference: TimeKeyValue) =>
  JSON.stringify(pick(reference, 'baseModelId', 'relationKey', 'fieldKey'));

const parseTimeKeyValue = (value: string) => {
  return timeKeyValueSchema.parse(JSON.parse(value));
};

const getTimeKeyFieldsFromRelation = (
  relation: PipelineStateRelation,
  getModel: (modelId: string) => Model,
) => {
  const timeKeyFields = getTimeKeyFields(getModel(relation.modelId).properties);
  if (timeKeyFields.length === 0) {
    return [];
  }
  return timeKeyFields.map((field) => ({
    ...field,
    name: field.name,
    key: serializeTimeKeyValue({
      baseModelId: relation.baseModelId,
      relationKey: relation.key,
      fieldKey: field.key,
    }),
  }));
};

export const getCohortPipelineState = (
  pipeline: Pipeline | undefined,
  exploration: Exploration,
  ctx: PipelineStateContext,
) => {
  if (pipeline === undefined) {
    return undefined;
  }
  const { baseModelId, operations } = dereferencePipeline(pipeline, exploration);
  const parentOperations = operations.slice(0, operations.length - pipeline.operations.length);
  return getFinalState(baseModelId, parentOperations, ctx);
};

export const getAllTimeKeyFields = (
  fields: Fields,
  pipeline: Pipeline | undefined,
  relations: PipelineStateRelation[],
  getModel: (modelId: string) => Model,
): (Field | FieldGroup)[] => {
  if (pipeline === undefined) {
    return [];
  }

  return [
    ...getTimeKeyFields(fields).map((field) => ({
      ...field,
      key: serializeTimeKeyValue({ fieldKey: field.key }),
    })),
    ...relations
      .filter((relation) =>
        getValidRelationTypesForOperation('addRelatedColumn').includes(relation.type),
      )
      .map((relation) => ({
        name: `on ${relation.name}`,
        fields: getTimeKeyFieldsFromRelation(relation, getModel),
      })),
  ];
};

export const buildOptions = (
  idFields: Fields,
  cohortTimeKeyFields: (Field | FieldGroup)[],
  eventTimeKeyFields: (Field | FieldGroup)[],
  pipeline: Pipeline | undefined,
  exploration: Exploration,
  model: Model | undefined,
): EditCohortOptions | undefined => {
  if (pipeline === undefined || model === undefined) {
    return;
  }

  if ('baseModelId' in pipeline) {
    return {
      title: model.name,
      idFields: idFields.map(fieldToOption),
      cohortTimeKeyFields: cohortTimeKeyFields.map(fieldToOptionGrouped),
      eventTimeKeyFields: eventTimeKeyFields.map(fieldToOptionGrouped),
    };
  }

  if ('parentId' in pipeline) {
    const parentCell = getCellByPipelineIdOrThrow(pipeline.parentId, exploration);

    return {
      title: ('title' in parentCell ? parentCell.title : '') ?? model.name,
      idFields: idFields.map(fieldToOption),
      cohortTimeKeyFields: cohortTimeKeyFields.map(fieldToOptionGrouped),
      eventTimeKeyFields: eventTimeKeyFields.map(fieldToOptionGrouped),
    };
  }
};

export const generateRelationTimeKeyColumnKey = (reference: RelationTimeKeyValue) =>
  `${reference.baseModelId}_${reference.relationKey}_${reference.fieldKey}`;

/**
 * Determine the select option value of a time key based on the addRelatedColumn operations (if any).
 * This way TTQ does not have to generate the property key in any specific format and we are free
 * to change option value formats.
 */
export const getTimeKeyValue = (
  timeKey: string | undefined,
  pipeline: Pipeline | undefined,
  relations: PipelineStateRelation[],
) => {
  if (timeKey === undefined || pipeline === undefined) {
    return;
  }
  const operation = pipeline.operations.find(
    (operation) =>
      operation.operation === 'addRelatedColumn' &&
      operation.parameters.columns.some((column) => column.property.key === timeKey),
  );
  if (!operation) {
    return serializeTimeKeyValue({
      fieldKey: timeKey,
    });
  }
  if (operation.operation !== 'addRelatedColumn') {
    throw new Error('Unexpected operation');
  }
  const { key, modelId } = operation.parameters.relation;
  const relation = relations.find(
    (relation) =>
      relation.key === key && (modelId === undefined || relation.baseModelId === modelId),
  );
  if (relation === undefined) {
    throw new Error(`Unable to find relation with key ${key}`);
  }
  return serializeTimeKeyValue({
    baseModelId: relation.baseModelId,
    relationKey: operation.parameters.relation.key,
    fieldKey: operation.parameters.columns[0].key,
  });
};

export const updateCohortTimeKeys = (
  state: EditCohortState,
  timeKeys: Pick<EditCohortState, 'cohortTimeKey' | 'eventTimeKey'>,
): EditCohortState => {
  const { pipeline } = state;
  if (pipeline === undefined) {
    return state;
  }

  const updatedState = Object.entries(timeKeys).reduce(
    (state, [timeKey, value]): EditCohortState => {
      const reference = parseTimeKeyValue(value);

      if (!('relationKey' in reference)) {
        return { ...state, [timeKey]: reference.fieldKey };
      }

      const columnKey = generateRelationTimeKeyColumnKey(reference);

      if (timeKeyColumnExists(state, reference)) {
        return { ...state, [timeKey]: columnKey };
      }

      return {
        ...state,
        [timeKey]: columnKey,
        pipeline: {
          ...state.pipeline,
          operations: [
            ...(state.pipeline?.operations ?? []),
            {
              operation: 'addRelatedColumn',
              parameters: {
                relation: { key: reference.relationKey, modelId: reference.baseModelId },
                columns: [
                  {
                    key: reference.fieldKey,
                    property: {
                      key: columnKey,
                      name: timeKey,
                    },
                  },
                ],
              },
            },
          ],
        } as Pipeline,
      };
    },
    state,
  );

  return removeUnusedTimeKeyRelatedColumns(updatedState);
};

const timeKeyColumnExists = (state: EditCohortState, reference: RelationTimeKeyValue) => {
  const existingOperation = state.pipeline?.operations.find((operation) => {
    return (
      operation.operation === 'addRelatedColumn' &&
      operation.parameters.columns.some(
        (column) => column.property.key === generateRelationTimeKeyColumnKey(reference),
      )
    );
  });

  return existingOperation !== undefined;
};

const removeUnusedTimeKeyRelatedColumns = (state: EditCohortState): EditCohortState => {
  if (state.pipeline === undefined) {
    return state;
  }

  const timeKeys = [state.cohortTimeKey, state.eventTimeKey];

  return {
    ...state,
    pipeline: {
      ...state.pipeline,
      operations: state.pipeline.operations.filter(
        (operation) =>
          operation.operation !== 'addRelatedColumn' ||
          operation.parameters.columns.some((column) => timeKeys.includes(column.property.key)),
      ),
    },
  };
};

const cohortTimeIntervalLabels: { [key in common.CohortTimeInterval]: string } = {
  year: 'Year',
  month: 'Month',
  week: 'Week',
  day: 'Day',
};

export const cohortTimeIntervalOptions = Object.entries(cohortTimeIntervalLabels).map(
  ([value, label]) => ({
    value,
    label,
  }),
);

export const isStateReady = (state: EditCohortState): state is Required<EditCohortState> =>
  state.pipeline !== undefined &&
  state.cohortId !== undefined &&
  state.cohortTimeKey !== undefined &&
  state.cohortTimeInterval !== undefined &&
  state.eventTimeKey !== undefined &&
  state.eventTimeInterval !== undefined;
