import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  Date: { input: string; output: string };
  JSON: { input: any; output: any };
};

export type AccessToken = {
  __typename?: 'AccessToken';
  accessTokenId: Scalars['ID']['output'];
  createdAt: Scalars['Date']['output'];
  expiresAt?: Maybe<Scalars['Date']['output']>;
  lastUsedAt?: Maybe<Scalars['Date']['output']>;
  name: Scalars['String']['output'];
  preview?: Maybe<Scalars['String']['output']>;
};

export type AccessTokenPreview = {
  __typename?: 'AccessTokenPreview';
  accessToken: AccessToken;
  token: Scalars['String']['output'];
};

export type Account = {
  __typename?: 'Account';
  accountId: Scalars['ID']['output'];
  alertConfiguration?: Maybe<AlertConfiguration>;
  alertConfigurations: Array<AlertConfiguration>;
  analyticsEvents: Array<AnalyticsEvent>;
  connections: Array<Connection>;
  describeQuery: DescribeQueryResponse;
  exploration?: Maybe<Exploration>;
  explorationState?: Maybe<ExplorationState>;
  explorations: Array<Exploration>;
  features?: Maybe<Scalars['JSON']['output']>;
  generateExploration?: Maybe<ExplorationResult>;
  integrations: Array<Integration>;
  isOnboarded: Scalars['Boolean']['output'];
  me: AccountUser;
  metric?: Maybe<Metric>;
  metrics: Array<Metric>;
  metricsV2: Array<MetricV2>;
  model?: Maybe<Model>;
  models: Array<Model>;
  name: Scalars['String']['output'];
  navigation: Array<NavigationItem>;
  onboardingState: OnboardingState;
  parameterisedExplorations: Array<Exploration>;
  query: QueryResponseConnection;
  slug: Scalars['String']['output'];
  testConnection: TestConnectionResult;
  timezone?: Maybe<Scalars['String']['output']>;
  users: Array<AccountUser>;
  validateExploration: Scalars['JSON']['output'];
};

export type AccountAlertConfigurationArgs = {
  alertConfigurationId: Scalars['ID']['input'];
};

export type AccountDescribeQueryArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  baseModelId: Scalars['ID']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  pipeline: Array<OperationInput>;
  sort?: InputMaybe<Array<QuerySortBy>>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  variables?: InputMaybe<Scalars['JSON']['input']>;
};

export type AccountExplorationArgs = {
  explorationId: Scalars['ID']['input'];
};

export type AccountExplorationStateArgs = {
  explorationId: Scalars['ID']['input'];
};

export type AccountExplorationsArgs = {
  search?: InputMaybe<Scalars['String']['input']>;
};

export type AccountGenerateExplorationArgs = {
  correlationId?: InputMaybe<Scalars['String']['input']>;
  exploration?: InputMaybe<ExplorationInput>;
  search: Scalars['String']['input'];
};

export type AccountMetricArgs = {
  metricId: Scalars['ID']['input'];
};

export type AccountMetricsArgs = {
  metricIdFilter?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type AccountModelArgs = {
  modelId: Scalars['ID']['input'];
};

export type AccountQueryArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  baseModelId: Scalars['ID']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  pipeline: Array<OperationInput>;
  sort?: InputMaybe<Array<QuerySortBy>>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  variables?: InputMaybe<Scalars['JSON']['input']>;
};

export type AccountTestConnectionArgs = {
  properties: Scalars['JSON']['input'];
  type: Scalars['String']['input'];
};

export type AccountValidateExplorationArgs = {
  exploration: Scalars['JSON']['input'];
  metricsInput?: InputMaybe<Scalars['JSON']['input']>;
  modelsInput: Scalars['JSON']['input'];
};

export type AccountUser = {
  __typename?: 'AccountUser';
  addedAt: Scalars['Date']['output'];
  createdAt: Scalars['Date']['output'];
  email: Scalars['String']['output'];
  role: Role;
  updatedAt: Scalars['Date']['output'];
  userId: Scalars['ID']['output'];
};

export type AccountUserChangeResponse = {
  __typename?: 'AccountUserChangeResponse';
  account: Account;
  accountUser: AccountUser;
};

export type AccountUserDeleteResponse = {
  __typename?: 'AccountUserDeleteResponse';
  account: Account;
  accountUser: AccountUser;
  success: Scalars['Boolean']['output'];
};

export type AlertConfiguration = {
  __typename?: 'AlertConfiguration';
  accountId: Scalars['ID']['output'];
  alertConfigurationId: Scalars['ID']['output'];
  createdAt: Scalars['Date']['output'];
  keyFields: Array<Scalars['String']['output']>;
  message: Scalars['String']['output'];
  name: Scalars['String']['output'];
  notificationConfig: Scalars['JSON']['output'];
  pipeline: Scalars['JSON']['output'];
  postedFields?: Maybe<Array<Scalars['String']['output']>>;
};

export type AlertConfigurationInput = {
  alertConfigurationId?: InputMaybe<Scalars['ID']['input']>;
  keyFields: Array<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  notificationConfig: Scalars['JSON']['input'];
  pipeline: Scalars['JSON']['input'];
  postedFields?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type AnalyticsEvent = {
  __typename?: 'AnalyticsEvent';
  analyticsEventId: Scalars['ID']['output'];
  createdAt: Scalars['Date']['output'];
  name: Scalars['String']['output'];
  properties: Scalars['JSON']['output'];
};

export type CohortViewCell = {
  __typename?: 'CohortViewCell';
  id: Scalars['ID']['output'];
  kind: Scalars['String']['output'];
  pipeline: QueryPipeline;
  title?: Maybe<Scalars['String']['output']>;
  viewOptions?: Maybe<Scalars['JSON']['output']>;
};

export type ConfigurationImportResult = {
  __typename?: 'ConfigurationImportResult';
  errors?: Maybe<Array<Scalars['String']['output']>>;
  success: Scalars['Boolean']['output'];
};

export type ConfigurationValidationResult = {
  __typename?: 'ConfigurationValidationResult';
  errors?: Maybe<Array<Scalars['String']['output']>>;
  success: Scalars['Boolean']['output'];
};

export type Connection = {
  __typename?: 'Connection';
  connectionId: Scalars['ID']['output'];
  createdAt: Scalars['Date']['output'];
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
  updatedAt: Scalars['Date']['output'];
};

export type ConsentInput = {
  subjects: Array<Scalars['String']['input']>;
};

export type CreateAccessTokenError = {
  __typename?: 'CreateAccessTokenError';
  error: Scalars['String']['output'];
};

export type CreateAccessTokenInput = {
  name: Scalars['String']['input'];
};

export type CreateAccessTokenResult = AccessTokenPreview | CreateAccessTokenError | NotAllowedError;

export type CreateAnalyticsEventError = {
  __typename?: 'CreateAnalyticsEventError';
  error: Scalars['String']['output'];
};

export type CreateAnalyticsEventInput = {
  name: Scalars['String']['input'];
  properties: Scalars['JSON']['input'];
};

export type CreateAnalyticsEventResult =
  | AnalyticsEvent
  | CreateAnalyticsEventError
  | NotAllowedError;

export type DeleteAccessTokenError = {
  __typename?: 'DeleteAccessTokenError';
  error: Scalars['String']['output'];
};

export type DeleteAccessTokenResult =
  | DeleteAccessTokenError
  | DeleteAccessTokenSuccess
  | NotAllowedError;

export type DeleteAccessTokenSuccess = {
  __typename?: 'DeleteAccessTokenSuccess';
  result: Scalars['Boolean']['output'];
};

export type DeleteAlertConfigurationResult = DeleteAlertConfigurationSuccess;

export type DeleteAlertConfigurationSuccess = {
  __typename?: 'DeleteAlertConfigurationSuccess';
  result: Scalars['Boolean']['output'];
};

export type DeleteExplorationSuccess = {
  __typename?: 'DeleteExplorationSuccess';
  result: Scalars['Boolean']['output'];
};

export type DeleteMetricError = {
  __typename?: 'DeleteMetricError';
  error: Scalars['String']['output'];
};

export type DeleteMetricResult = DeleteMetricError | DeleteMetricSuccess | NotAllowedError;

export type DeleteMetricSuccess = {
  __typename?: 'DeleteMetricSuccess';
  result: Scalars['Boolean']['output'];
};

export type DeleteModelError = {
  __typename?: 'DeleteModelError';
  error: Scalars['String']['output'];
};

export type DeleteModelResult = DeleteModelError | DeleteModelSuccess | NotAllowedError;

export type DeleteModelSuccess = {
  __typename?: 'DeleteModelSuccess';
  result: Scalars['Boolean']['output'];
};

export type DescribeQueryResponse = {
  __typename?: 'DescribeQueryResponse';
  sql: Scalars['String']['output'];
};

export type Exploration = {
  __typename?: 'Exploration';
  description?: Maybe<Scalars['String']['output']>;
  explorationId: Scalars['ID']['output'];
  fields?: Maybe<Array<Scalars['String']['output']>>;
  labels: Scalars['JSON']['output'];
  name: Scalars['String']['output'];
  parameters: Array<ExplorationParameter>;
  view: ExplorationView;
};

export type ExplorationInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  explorationId: Scalars['ID']['input'];
  labels?: InputMaybe<Scalars['JSON']['input']>;
  name: Scalars['String']['input'];
  parameters?: InputMaybe<Scalars['JSON']['input']>;
  view: Scalars['JSON']['input'];
};

export type ExplorationNavigationItem = {
  __typename?: 'ExplorationNavigationItem';
  explorationId: Scalars['String']['output'];
  kind: Scalars['String']['output'];
  label: Scalars['String']['output'];
};

export type ExplorationParameter = {
  __typename?: 'ExplorationParameter';
  key: Scalars['String']['output'];
  modelId: Scalars['String']['output'];
};

export type ExplorationResult = {
  __typename?: 'ExplorationResult';
  correlationId: Scalars['String']['output'];
  exploration?: Maybe<Exploration>;
};

export type ExplorationState = {
  __typename?: 'ExplorationState';
  explorationId: Scalars['ID']['output'];
  explorationSourceId?: Maybe<Scalars['ID']['output']>;
  previousStateId?: Maybe<Scalars['ID']['output']>;
  state: Scalars['JSON']['output'];
};

export type ExplorationStateInput = {
  explorationId: Scalars['ID']['input'];
  explorationSourceId?: InputMaybe<Scalars['ID']['input']>;
  previousStateId?: InputMaybe<Scalars['ID']['input']>;
  state: Scalars['JSON']['input'];
};

export type ExplorationView = {
  __typename?: 'ExplorationView';
  cells: Array<ViewCell>;
};

export type ExplorationViewCellSort = {
  __typename?: 'ExplorationViewCellSort';
  direction: Scalars['String']['output'];
  key: Scalars['String']['output'];
};

export type FunnelViewCell = {
  __typename?: 'FunnelViewCell';
  id: Scalars['ID']['output'];
  kind: Scalars['String']['output'];
  pipeline: QueryPipeline;
  title?: Maybe<Scalars['String']['output']>;
  viewOptions?: Maybe<Scalars['JSON']['output']>;
};

export type Integration = {
  __typename?: 'Integration';
  description?: Maybe<Scalars['String']['output']>;
  integrationId: Scalars['ID']['output'];
  service: IntegrationService;
};

export enum IntegrationService {
  Custom = 'CUSTOM',
  FacebookAds = 'FACEBOOK_ADS',
  FacebookPage = 'FACEBOOK_PAGE',
  GoogleAds = 'GOOGLE_ADS',
  GoogleAnalytics = 'GOOGLE_ANALYTICS',
  GoogleBigquery = 'GOOGLE_BIGQUERY',
  Mixpanel = 'MIXPANEL',
  Shopify = 'SHOPIFY',
  Stripe = 'STRIPE',
  Woocommerce = 'WOOCOMMERCE',
}

export type Metric = {
  __typename?: 'Metric';
  category: Scalars['String']['output'];
  displayPostfix?: Maybe<Scalars['String']['output']>;
  displayPrefix?: Maybe<Scalars['String']['output']>;
  kind: MetricKind;
  metricId: Scalars['ID']['output'];
  metricType: Scalars['String']['output'];
  name: Scalars['String']['output'];
  pipeline: QueryPipeline;
  services: Array<IntegrationService>;
  values: Array<MetricValue>;
};

export type MetricPipelineArgs = {
  aggregation?: InputMaybe<MetricValueAggregation>;
};

export type MetricValuesArgs = {
  aggregation: MetricValueAggregation;
  endDate: Scalars['Date']['input'];
  startDate: Scalars['Date']['input'];
};

export type MetricDefinitionInput = {
  category: Scalars['String']['input'];
  definition: Scalars['JSON']['input'];
  displayPostfix?: InputMaybe<Scalars['String']['input']>;
  displayPrefix?: InputMaybe<Scalars['String']['input']>;
  kind: MetricKind;
  metricId: Scalars['String']['input'];
  metricType: Scalars['String']['input'];
  name: Scalars['String']['input'];
  services: Array<Scalars['String']['input']>;
};

export type MetricInput = {
  definition: MetricDefinitionInput;
  metricId: Scalars['String']['input'];
};

export enum MetricKind {
  AbsoluteValue = 'ABSOLUTE_VALUE',
  NewValue = 'NEW_VALUE',
  Percentage = 'PERCENTAGE',
}

export type MetricV2 = {
  __typename?: 'MetricV2';
  definition: Scalars['JSON']['output'];
  description?: Maybe<Scalars['String']['output']>;
  metricId: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type MetricValue = {
  __typename?: 'MetricValue';
  date: Scalars['Date']['output'];
  value: Scalars['Float']['output'];
};

export enum MetricValueAggregation {
  Daily = 'DAILY',
  Monthly = 'MONTHLY',
  Weekly = 'WEEKLY',
}

export type MetricViewCell = {
  __typename?: 'MetricViewCell';
  id: Scalars['ID']['output'];
  kind: Scalars['String']['output'];
  metricId: Scalars['String']['output'];
  viewOptions?: Maybe<Scalars['JSON']['output']>;
};

export type Model = {
  __typename?: 'Model';
  description?: Maybe<Scalars['String']['output']>;
  labels: Scalars['JSON']['output'];
  modelId: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  primaryKey: Array<Scalars['String']['output']>;
  properties: Array<ModelProperty>;
  property?: Maybe<ModelProperty>;
  relations: Array<ModelRelation>;
  semantics?: Maybe<Scalars['JSON']['output']>;
};

export type ModelPropertyArgs = {
  key: Scalars['String']['input'];
};

export type ModelInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  labels?: InputMaybe<Scalars['JSON']['input']>;
  modelId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  pipeline?: InputMaybe<Scalars['JSON']['input']>;
  primaryKey: Array<Scalars['String']['input']>;
  properties: Array<ModelPropertyInput>;
  prql: Scalars['String']['input'];
  relations?: InputMaybe<Array<ModelRelationInput>>;
  semantics?: InputMaybe<ModelSemanticsInput>;
};

export type ModelProperty = {
  __typename?: 'ModelProperty';
  description?: Maybe<Scalars['String']['output']>;
  enumOptions?: Maybe<Array<ModelPropertyEnumOption>>;
  key: Scalars['String']['output'];
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type ModelPropertyEnumOption = {
  __typename?: 'ModelPropertyEnumOption';
  label: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type ModelPropertyEnumOptionInput = {
  label: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type ModelPropertyEnumOptionsInput = {
  load: Scalars['String']['input'];
  options?: InputMaybe<Array<ModelPropertyEnumOptionInput>>;
};

export type ModelPropertyInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  enumOptions?: InputMaybe<ModelPropertyEnumOptionsInput>;
  key: Scalars['String']['input'];
  name: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

export type ModelRelation = {
  __typename?: 'ModelRelation';
  description?: Maybe<Scalars['String']['output']>;
  joinStrategy: ModelRelationJoinStrategy;
  key: Scalars['String']['output'];
  model: Model;
  modelId: Scalars['String']['output'];
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type ModelRelationInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  joinStrategy: ModelRelationJoinStrategyInput;
  key: Scalars['String']['input'];
  modelId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

export type ModelRelationJoinStrategy = {
  __typename?: 'ModelRelationJoinStrategy';
  joinKeyOnBase?: Maybe<Scalars['String']['output']>;
  joinKeyOnRelated?: Maybe<Scalars['String']['output']>;
  steps?: Maybe<Array<ModelRelationJoinStrategySteps>>;
  through?: Maybe<ModelRelationJoinStrategyThrough>;
};

export type ModelRelationJoinStrategyInput = {
  joinKeyOnBase?: InputMaybe<Scalars['String']['input']>;
  joinKeyOnRelated?: InputMaybe<Scalars['String']['input']>;
  steps?: InputMaybe<Array<ModelRelationJoinStrategyStepsInput>>;
  through?: InputMaybe<ModelRelationJoinStrategyThroughInput>;
};

export type ModelRelationJoinStrategySteps = {
  __typename?: 'ModelRelationJoinStrategySteps';
  relationKey: Scalars['String']['output'];
};

export type ModelRelationJoinStrategyStepsInput = {
  relationKey: Scalars['String']['input'];
};

export type ModelRelationJoinStrategyThrough = {
  __typename?: 'ModelRelationJoinStrategyThrough';
  joinKeyToBase?: Maybe<Scalars['String']['output']>;
  joinKeyToRelated?: Maybe<Scalars['String']['output']>;
  modelId: Scalars['String']['output'];
};

export type ModelRelationJoinStrategyThroughInput = {
  joinKeyToBase?: InputMaybe<Scalars['String']['input']>;
  joinKeyToRelated?: InputMaybe<Scalars['String']['input']>;
  modelId: Scalars['String']['input'];
};

export type ModelSemanticsInput = {
  kind: Scalars['String']['input'];
  properties?: InputMaybe<Scalars['JSON']['input']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addUserToAccount: AccountUserChangeResponse;
  createAccessToken: CreateAccessTokenResult;
  createAnalyticsEvent: CreateAnalyticsEventResult;
  createConnection: Connection;
  deleteAccessToken: DeleteAccessTokenResult;
  deleteAlertConfiguration: DeleteAlertConfigurationResult;
  deleteExploration: DeleteExplorationSuccess;
  deleteMetric: DeleteMetricResult;
  deleteModel: DeleteModelResult;
  deleteUserFromAccount: AccountUserDeleteResponse;
  giveConsent: Scalars['Boolean']['output'];
  importConfiguration: ConfigurationImportResult;
  ping: Scalars['String']['output'];
  signUp: User;
  updateNavigation: Scalars['Boolean']['output'];
  updateOnboardingState: Scalars['JSON']['output'];
  updateUserInAccount: AccountUserChangeResponse;
  upsertAlertConfiguration: AlertConfiguration;
  upsertExploration: Exploration;
  upsertExplorationState: ExplorationState;
  upsertMetric: UpsertMetricResult;
  upsertModel: UpsertModelResult;
  validateConfigurationImport: ConfigurationValidationResult;
};

export type MutationAddUserToAccountArgs = {
  accountId: Scalars['ID']['input'];
  email: Scalars['String']['input'];
  role: UserRole;
};

export type MutationCreateAccessTokenArgs = {
  input: CreateAccessTokenInput;
};

export type MutationCreateAnalyticsEventArgs = {
  accountId?: InputMaybe<Scalars['ID']['input']>;
  input: CreateAnalyticsEventInput;
};

export type MutationCreateConnectionArgs = {
  accountId: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  properties: Scalars['JSON']['input'];
  type: Scalars['String']['input'];
};

export type MutationDeleteAccessTokenArgs = {
  accessTokenId: Scalars['ID']['input'];
};

export type MutationDeleteAlertConfigurationArgs = {
  accountId: Scalars['ID']['input'];
  alertConfigurationId: Scalars['ID']['input'];
};

export type MutationDeleteExplorationArgs = {
  accountId: Scalars['ID']['input'];
  explorationId: Scalars['ID']['input'];
};

export type MutationDeleteMetricArgs = {
  accountId: Scalars['ID']['input'];
  id: Scalars['String']['input'];
};

export type MutationDeleteModelArgs = {
  accountId: Scalars['ID']['input'];
  id: Scalars['String']['input'];
};

export type MutationDeleteUserFromAccountArgs = {
  accountId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type MutationGiveConsentArgs = {
  accountId: Scalars['ID']['input'];
  input: ConsentInput;
};

export type MutationImportConfigurationArgs = {
  accountId: Scalars['ID']['input'];
  deleteDangling?: InputMaybe<Scalars['Boolean']['input']>;
  input: Scalars['String']['input'];
  validateExplorations?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationSignUpArgs = {
  consent?: InputMaybe<ConsentInput>;
  email: Scalars['String']['input'];
  extras?: InputMaybe<Scalars['JSON']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdateNavigationArgs = {
  accountId: Scalars['ID']['input'];
  input: Scalars['JSON']['input'];
};

export type MutationUpdateOnboardingStateArgs = {
  accountId: Scalars['ID']['input'];
  key: OnboardingStateKey;
  value: Scalars['JSON']['input'];
};

export type MutationUpdateUserInAccountArgs = {
  accountId: Scalars['ID']['input'];
  role: UserRole;
  userId: Scalars['ID']['input'];
};

export type MutationUpsertAlertConfigurationArgs = {
  accountId: Scalars['ID']['input'];
  input: AlertConfigurationInput;
};

export type MutationUpsertExplorationArgs = {
  accountId: Scalars['ID']['input'];
  input: ExplorationInput;
};

export type MutationUpsertExplorationStateArgs = {
  accountId: Scalars['ID']['input'];
  input: ExplorationStateInput;
};

export type MutationUpsertMetricArgs = {
  accountId: Scalars['ID']['input'];
  input: MetricInput;
};

export type MutationUpsertModelArgs = {
  accountId: Scalars['ID']['input'];
  input: ModelInput;
};

export type MutationValidateConfigurationImportArgs = {
  accountId: Scalars['ID']['input'];
  input: Scalars['String']['input'];
  validateDangling?: InputMaybe<Scalars['Boolean']['input']>;
  validateExplorations?: InputMaybe<Scalars['Boolean']['input']>;
};

export type NavigationItem = ExplorationNavigationItem;

export type NotAllowedError = {
  __typename?: 'NotAllowedError';
  message: Scalars['String']['output'];
};

export type OnboardingState = {
  __typename?: 'OnboardingState';
  hasBookedSetupCall: Scalars['Boolean']['output'];
  hasConnection: Scalars['Boolean']['output'];
  hasConsented: Scalars['Boolean']['output'];
  hasOnboarded: Scalars['Boolean']['output'];
  hasPassedSetupCallStep: Scalars['Boolean']['output'];
  welcomeSurvey?: Maybe<Scalars['JSON']['output']>;
};

export enum OnboardingStateKey {
  HasBookedSetupCall = 'hasBookedSetupCall',
  HasPassedSetupCallStep = 'hasPassedSetupCallStep',
  WelcomeSurvey = 'welcomeSurvey',
}

export type OperationInput = {
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
  operation: Scalars['String']['input'];
  parameters: Scalars['JSON']['input'];
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type Permission = {
  __typename?: 'Permission';
  name: Scalars['String']['output'];
  permissionId: UserRolePermission;
};

export type PipelineOperator = {
  __typename?: 'PipelineOperator';
  disabled?: Maybe<Scalars['Boolean']['output']>;
  operation: Scalars['String']['output'];
  parameters: Scalars['JSON']['output'];
};

export type PythonViewCell = {
  __typename?: 'PythonViewCell';
  id: Scalars['ID']['output'];
  kind: Scalars['String']['output'];
  pipeline: QueryPipeline;
  title?: Maybe<Scalars['String']['output']>;
  viewOptions?: Maybe<Scalars['JSON']['output']>;
};

export type Query = {
  __typename?: 'Query';
  accessTokens: Array<AccessToken>;
  account?: Maybe<Account>;
  accounts: Array<Account>;
  me?: Maybe<User>;
  permissions: Array<Permission>;
  ping: Scalars['String']['output'];
  roles: Array<Role>;
};

export type QueryAccountArgs = {
  accountId: Scalars['ID']['input'];
};

export type QueryPipeline = {
  __typename?: 'QueryPipeline';
  baseModelId?: Maybe<Scalars['String']['output']>;
  operations: Array<PipelineOperator>;
  parentId?: Maybe<Scalars['String']['output']>;
  pipelineId?: Maybe<Scalars['String']['output']>;
};

export type QueryResponseConnection = {
  __typename?: 'QueryResponseConnection';
  edges: Array<QueryResponseEdge>;
  grouping: Array<QueryResponseGrouping>;
  pageInfo: PageInfo;
  recordType: Array<QueryResponseRecordType>;
};

export type QueryResponseEdge = {
  __typename?: 'QueryResponseEdge';
  node: Scalars['JSON']['output'];
};

export type QueryResponseGrouping = {
  __typename?: 'QueryResponseGrouping';
  key: Scalars['String']['output'];
};

export type QueryResponseRecordModel = {
  __typename?: 'QueryResponseRecordModel';
  modelId: Scalars['String']['output'];
  name: Scalars['String']['output'];
  propertyKey: Scalars['String']['output'];
};

export type QueryResponseRecordRelation = {
  __typename?: 'QueryResponseRecordRelation';
  key: Scalars['String']['output'];
  modelId: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type QueryResponseRecordType = {
  __typename?: 'QueryResponseRecordType';
  format?: Maybe<Scalars['String']['output']>;
  key: Scalars['String']['output'];
  model?: Maybe<QueryResponseRecordModel>;
  name: Scalars['String']['output'];
  pk?: Maybe<Scalars['Boolean']['output']>;
  precision?: Maybe<Scalars['String']['output']>;
  relation?: Maybe<QueryResponseRecordRelation>;
  type: Scalars['String']['output'];
};

export type QuerySortBy = {
  direction: QuerySortDirection;
  key: Scalars['String']['input'];
};

export enum QuerySortDirection {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type RecordsViewCell = {
  __typename?: 'RecordsViewCell';
  excludeProperties?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['ID']['output'];
  kind: Scalars['String']['output'];
  pipeline: QueryPipeline;
  sort?: Maybe<Array<ExplorationViewCellSort>>;
  title?: Maybe<Scalars['String']['output']>;
  viewOptions?: Maybe<Scalars['JSON']['output']>;
  visualisations?: Maybe<Array<Visualisation>>;
};

export type Role = {
  __typename?: 'Role';
  name: Scalars['String']['output'];
  permissions: Array<Permission>;
  roleId: UserRole;
};

export type SqlViewCell = {
  __typename?: 'SqlViewCell';
  id: Scalars['ID']['output'];
  kind: Scalars['String']['output'];
  pipeline: QueryPipeline;
  title?: Maybe<Scalars['String']['output']>;
  viewOptions?: Maybe<Scalars['JSON']['output']>;
};

export type TestConnectionFailed = {
  __typename?: 'TestConnectionFailed';
  error: Scalars['String']['output'];
};

export type TestConnectionResult = TestConnectionFailed | TestConnectionSuccess;

export type TestConnectionSuccess = {
  __typename?: 'TestConnectionSuccess';
  success: Scalars['Boolean']['output'];
};

export type TextViewCell = {
  __typename?: 'TextViewCell';
  content?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  kind: Scalars['String']['output'];
  title?: Maybe<Scalars['String']['output']>;
  viewOptions?: Maybe<Scalars['JSON']['output']>;
};

export type UpsertMetricError = {
  __typename?: 'UpsertMetricError';
  error: Scalars['String']['output'];
};

export type UpsertMetricResult = Metric | NotAllowedError | UpsertMetricError;

export type UpsertModelError = {
  __typename?: 'UpsertModelError';
  error: Scalars['String']['output'];
};

export type UpsertModelResult = Model | NotAllowedError | UpsertModelError;

export type User = {
  __typename?: 'User';
  createdAt: Scalars['Date']['output'];
  email: Scalars['String']['output'];
  updatedAt: Scalars['Date']['output'];
  userId: Scalars['ID']['output'];
};

export enum UserRole {
  Admin = 'ADMIN',
  Editor = 'EDITOR',
  Explorer = 'EXPLORER',
}

export enum UserRolePermission {
  ManageAccount = 'MANAGE_ACCOUNT',
  ManageAlerts = 'MANAGE_ALERTS',
  ManageConnections = 'MANAGE_CONNECTIONS',
  ManageExplorations = 'MANAGE_EXPLORATIONS',
  ManageModels = 'MANAGE_MODELS',
  ManageUsers = 'MANAGE_USERS',
}

export type VariableViewCell = {
  __typename?: 'VariableViewCell';
  definition: Scalars['JSON']['output'];
  id: Scalars['ID']['output'];
  kind: Scalars['String']['output'];
  viewOptions?: Maybe<Scalars['JSON']['output']>;
};

export type ViewCell =
  | CohortViewCell
  | FunnelViewCell
  | MetricViewCell
  | PythonViewCell
  | RecordsViewCell
  | SqlViewCell
  | TextViewCell
  | VariableViewCell;

export type Visualisation = {
  __typename?: 'Visualisation';
  aggregation?: Maybe<Scalars['JSON']['output']>;
  aggregationTarget?: Maybe<Scalars['String']['output']>;
  aggregationType?: Maybe<Scalars['String']['output']>;
  aggregations?: Maybe<Scalars['JSON']['output']>;
  groupByField?: Maybe<Scalars['String']['output']>;
  groups?: Maybe<Scalars['JSON']['output']>;
  mainAxisKey?: Maybe<Scalars['String']['output']>;
  timeGranularity?: Maybe<Scalars['String']['output']>;
  valueKeys?: Maybe<Array<Scalars['String']['output']>>;
  viewOptions?: Maybe<Scalars['JSON']['output']>;
};

export type AlertConfigurationQueryVariables = Exact<{
  accountId: Scalars['ID']['input'];
  alertConfigurationId: Scalars['ID']['input'];
}>;

export type AlertConfigurationQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    alertConfiguration?: {
      __typename?: 'AlertConfiguration';
      alertConfigurationId: string;
      accountId: string;
      name: string;
      message: string;
      pipeline: any;
      keyFields: Array<string>;
      notificationConfig: any;
      createdAt: string;
    } | null;
  } | null;
};

export type NavigationQueryVariables = Exact<{
  accountId: Scalars['ID']['input'];
}>;

export type NavigationQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    accountId: string;
    navigation: Array<{
      __typename?: 'ExplorationNavigationItem';
      kind: string;
      label: string;
      explorationId: string;
    }>;
  } | null;
};

export type ExplorationRecordTypeQueryVariables = Exact<{
  accountId: Scalars['ID']['input'];
  baseModelId: Scalars['ID']['input'];
  pipeline: Array<OperationInput> | OperationInput;
  variables?: InputMaybe<Scalars['JSON']['input']>;
}>;

export type ExplorationRecordTypeQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    accountId: string;
    query: {
      __typename?: 'QueryResponseConnection';
      recordType: Array<{
        __typename?: 'QueryResponseRecordType';
        key: string;
        type: string;
        name: string;
        relation?: {
          __typename?: 'QueryResponseRecordRelation';
          modelId: string;
          key: string;
          name: string;
        } | null;
      }>;
      grouping: Array<{ __typename?: 'QueryResponseGrouping'; key: string }>;
    };
  } | null;
};

export type ExplorationGenerationQueryVariables = Exact<{
  accountId: Scalars['ID']['input'];
  search: Scalars['String']['input'];
  correlationId?: InputMaybe<Scalars['String']['input']>;
  exploration?: InputMaybe<ExplorationInput>;
}>;

export type ExplorationGenerationQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    generateExploration?: {
      __typename?: 'ExplorationResult';
      exploration?: {
        __typename?: 'Exploration';
        explorationId: string;
        name: string;
        description?: string | null;
        labels: any;
        parameters: Array<{ __typename?: 'ExplorationParameter'; key: string; modelId: string }>;
        view: {
          __typename?: 'ExplorationView';
          cells: Array<
            | {
                __typename?: 'CohortViewCell';
                id: string;
                kind: string;
                title?: string | null;
                viewOptions?: any | null;
                pipeline: {
                  __typename?: 'QueryPipeline';
                  baseModelId?: string | null;
                  pipelineId?: string | null;
                  parentId?: string | null;
                  operations: Array<{
                    __typename?: 'PipelineOperator';
                    operation: string;
                    parameters: any;
                  }>;
                };
              }
            | {
                __typename?: 'FunnelViewCell';
                id: string;
                kind: string;
                title?: string | null;
                viewOptions?: any | null;
                pipeline: {
                  __typename?: 'QueryPipeline';
                  baseModelId?: string | null;
                  operations: Array<{
                    __typename?: 'PipelineOperator';
                    operation: string;
                    parameters: any;
                  }>;
                };
              }
            | {
                __typename?: 'MetricViewCell';
                id: string;
                kind: string;
                metricId: string;
                viewOptions?: any | null;
              }
            | {
                __typename?: 'PythonViewCell';
                id: string;
                kind: string;
                title?: string | null;
                viewOptions?: any | null;
                pipeline: {
                  __typename?: 'QueryPipeline';
                  baseModelId?: string | null;
                  pipelineId?: string | null;
                  operations: Array<{
                    __typename?: 'PipelineOperator';
                    operation: string;
                    parameters: any;
                  }>;
                };
              }
            | {
                __typename?: 'RecordsViewCell';
                id: string;
                kind: string;
                title?: string | null;
                excludeProperties?: Array<string> | null;
                viewOptions?: any | null;
                pipeline: {
                  __typename?: 'QueryPipeline';
                  baseModelId?: string | null;
                  pipelineId?: string | null;
                  parentId?: string | null;
                  operations: Array<{
                    __typename?: 'PipelineOperator';
                    operation: string;
                    parameters: any;
                    disabled?: boolean | null;
                  }>;
                };
                sort?: Array<{
                  __typename?: 'ExplorationViewCellSort';
                  key: string;
                  direction: string;
                }> | null;
                visualisations?: Array<{
                  __typename?: 'Visualisation';
                  aggregationType?: string | null;
                  aggregationTarget?: string | null;
                  groupByField?: string | null;
                  timeGranularity?: string | null;
                  viewOptions?: any | null;
                  aggregations?: any | null;
                  groups?: any | null;
                  aggregation?: any | null;
                  valueKeys?: Array<string> | null;
                  mainAxisKey?: string | null;
                }> | null;
              }
            | {
                __typename?: 'SqlViewCell';
                id: string;
                kind: string;
                title?: string | null;
                viewOptions?: any | null;
                pipeline: {
                  __typename?: 'QueryPipeline';
                  baseModelId?: string | null;
                  pipelineId?: string | null;
                  operations: Array<{
                    __typename?: 'PipelineOperator';
                    operation: string;
                    parameters: any;
                  }>;
                };
              }
            | {
                __typename?: 'TextViewCell';
                id: string;
                kind: string;
                title?: string | null;
                content?: string | null;
                viewOptions?: any | null;
              }
            | {
                __typename?: 'VariableViewCell';
                id: string;
                kind: string;
                definition: any;
                viewOptions?: any | null;
              }
          >;
        };
      } | null;
    } | null;
  } | null;
};

export type ExplorationsSearchQueryVariables = Exact<{
  accountId: Scalars['ID']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
}>;

export type ExplorationsSearchQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    explorations: Array<{
      __typename?: 'Exploration';
      explorationId: string;
      name: string;
      description?: string | null;
      labels: any;
      parameters: Array<{ __typename?: 'ExplorationParameter'; key: string; modelId: string }>;
      view: {
        __typename?: 'ExplorationView';
        cells: Array<
          | {
              __typename?: 'CohortViewCell';
              id: string;
              kind: string;
              title?: string | null;
              viewOptions?: any | null;
              pipeline: {
                __typename?: 'QueryPipeline';
                baseModelId?: string | null;
                pipelineId?: string | null;
                parentId?: string | null;
                operations: Array<{
                  __typename?: 'PipelineOperator';
                  operation: string;
                  parameters: any;
                }>;
              };
            }
          | {
              __typename?: 'FunnelViewCell';
              id: string;
              kind: string;
              title?: string | null;
              viewOptions?: any | null;
              pipeline: {
                __typename?: 'QueryPipeline';
                baseModelId?: string | null;
                operations: Array<{
                  __typename?: 'PipelineOperator';
                  operation: string;
                  parameters: any;
                }>;
              };
            }
          | {
              __typename?: 'MetricViewCell';
              id: string;
              kind: string;
              metricId: string;
              viewOptions?: any | null;
            }
          | {
              __typename?: 'PythonViewCell';
              id: string;
              kind: string;
              title?: string | null;
              viewOptions?: any | null;
              pipeline: {
                __typename?: 'QueryPipeline';
                baseModelId?: string | null;
                pipelineId?: string | null;
                operations: Array<{
                  __typename?: 'PipelineOperator';
                  operation: string;
                  parameters: any;
                }>;
              };
            }
          | {
              __typename?: 'RecordsViewCell';
              id: string;
              kind: string;
              title?: string | null;
              excludeProperties?: Array<string> | null;
              viewOptions?: any | null;
              pipeline: {
                __typename?: 'QueryPipeline';
                baseModelId?: string | null;
                pipelineId?: string | null;
                parentId?: string | null;
                operations: Array<{
                  __typename?: 'PipelineOperator';
                  operation: string;
                  parameters: any;
                  disabled?: boolean | null;
                }>;
              };
              sort?: Array<{
                __typename?: 'ExplorationViewCellSort';
                key: string;
                direction: string;
              }> | null;
              visualisations?: Array<{
                __typename?: 'Visualisation';
                aggregationType?: string | null;
                aggregationTarget?: string | null;
                groupByField?: string | null;
                timeGranularity?: string | null;
                viewOptions?: any | null;
                aggregations?: any | null;
                groups?: any | null;
                aggregation?: any | null;
                valueKeys?: Array<string> | null;
                mainAxisKey?: string | null;
              }> | null;
            }
          | {
              __typename?: 'SqlViewCell';
              id: string;
              kind: string;
              title?: string | null;
              viewOptions?: any | null;
              pipeline: {
                __typename?: 'QueryPipeline';
                baseModelId?: string | null;
                pipelineId?: string | null;
                operations: Array<{
                  __typename?: 'PipelineOperator';
                  operation: string;
                  parameters: any;
                }>;
              };
            }
          | {
              __typename?: 'TextViewCell';
              id: string;
              kind: string;
              title?: string | null;
              content?: string | null;
              viewOptions?: any | null;
            }
          | {
              __typename?: 'VariableViewCell';
              id: string;
              kind: string;
              definition: any;
              viewOptions?: any | null;
            }
        >;
      };
    }>;
  } | null;
};

export type ExplorationFragmentFragment = {
  __typename?: 'Exploration';
  explorationId: string;
  name: string;
  description?: string | null;
  labels: any;
  parameters: Array<{ __typename?: 'ExplorationParameter'; key: string; modelId: string }>;
  view: {
    __typename?: 'ExplorationView';
    cells: Array<
      | {
          __typename?: 'CohortViewCell';
          id: string;
          kind: string;
          title?: string | null;
          viewOptions?: any | null;
          pipeline: {
            __typename?: 'QueryPipeline';
            baseModelId?: string | null;
            pipelineId?: string | null;
            parentId?: string | null;
            operations: Array<{
              __typename?: 'PipelineOperator';
              operation: string;
              parameters: any;
            }>;
          };
        }
      | {
          __typename?: 'FunnelViewCell';
          id: string;
          kind: string;
          title?: string | null;
          viewOptions?: any | null;
          pipeline: {
            __typename?: 'QueryPipeline';
            baseModelId?: string | null;
            operations: Array<{
              __typename?: 'PipelineOperator';
              operation: string;
              parameters: any;
            }>;
          };
        }
      | {
          __typename?: 'MetricViewCell';
          id: string;
          kind: string;
          metricId: string;
          viewOptions?: any | null;
        }
      | {
          __typename?: 'PythonViewCell';
          id: string;
          kind: string;
          title?: string | null;
          viewOptions?: any | null;
          pipeline: {
            __typename?: 'QueryPipeline';
            baseModelId?: string | null;
            pipelineId?: string | null;
            operations: Array<{
              __typename?: 'PipelineOperator';
              operation: string;
              parameters: any;
            }>;
          };
        }
      | {
          __typename?: 'RecordsViewCell';
          id: string;
          kind: string;
          title?: string | null;
          excludeProperties?: Array<string> | null;
          viewOptions?: any | null;
          pipeline: {
            __typename?: 'QueryPipeline';
            baseModelId?: string | null;
            pipelineId?: string | null;
            parentId?: string | null;
            operations: Array<{
              __typename?: 'PipelineOperator';
              operation: string;
              parameters: any;
              disabled?: boolean | null;
            }>;
          };
          sort?: Array<{
            __typename?: 'ExplorationViewCellSort';
            key: string;
            direction: string;
          }> | null;
          visualisations?: Array<{
            __typename?: 'Visualisation';
            aggregationType?: string | null;
            aggregationTarget?: string | null;
            groupByField?: string | null;
            timeGranularity?: string | null;
            viewOptions?: any | null;
            aggregations?: any | null;
            groups?: any | null;
            aggregation?: any | null;
            valueKeys?: Array<string> | null;
            mainAxisKey?: string | null;
          }> | null;
        }
      | {
          __typename?: 'SqlViewCell';
          id: string;
          kind: string;
          title?: string | null;
          viewOptions?: any | null;
          pipeline: {
            __typename?: 'QueryPipeline';
            baseModelId?: string | null;
            pipelineId?: string | null;
            operations: Array<{
              __typename?: 'PipelineOperator';
              operation: string;
              parameters: any;
            }>;
          };
        }
      | {
          __typename?: 'TextViewCell';
          id: string;
          kind: string;
          title?: string | null;
          content?: string | null;
          viewOptions?: any | null;
        }
      | {
          __typename?: 'VariableViewCell';
          id: string;
          kind: string;
          definition: any;
          viewOptions?: any | null;
        }
    >;
  };
};

export type DeleteExplorationMutationVariables = Exact<{
  accountId: Scalars['ID']['input'];
  explorationId: Scalars['ID']['input'];
}>;

export type DeleteExplorationMutation = {
  __typename?: 'Mutation';
  deleteExploration: { __typename?: 'DeleteExplorationSuccess'; result: boolean };
};

export type EnumOptionsQueryVariables = Exact<{
  accountId: Scalars['ID']['input'];
  modelId: Scalars['ID']['input'];
  propertyKey: Scalars['String']['input'];
}>;

export type EnumOptionsQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    model?: {
      __typename?: 'Model';
      modelId: string;
      property?: {
        __typename?: 'ModelProperty';
        key: string;
        enumOptions?: Array<{
          __typename?: 'ModelPropertyEnumOption';
          label: string;
          value: string;
        }> | null;
      } | null;
    } | null;
  } | null;
};

export type ExplorationDataQueryVariables = Exact<{
  accountId: Scalars['ID']['input'];
  baseModelId: Scalars['ID']['input'];
  pipeline: Array<OperationInput> | OperationInput;
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<QuerySortBy> | QuerySortBy>;
  variables?: InputMaybe<Scalars['JSON']['input']>;
}>;

export type ExplorationDataQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    accountId: string;
    name: string;
    query: {
      __typename?: 'QueryResponseConnection';
      edges: Array<{ __typename?: 'QueryResponseEdge'; node: any }>;
      pageInfo: { __typename?: 'PageInfo'; hasNextPage: boolean; endCursor?: string | null };
      recordType: Array<{
        __typename?: 'QueryResponseRecordType';
        key: string;
        type: string;
        name: string;
        pk?: boolean | null;
        precision?: string | null;
        model?: {
          __typename?: 'QueryResponseRecordModel';
          modelId: string;
          name: string;
          propertyKey: string;
        } | null;
        relation?: {
          __typename?: 'QueryResponseRecordRelation';
          modelId: string;
          key: string;
          name: string;
        } | null;
      }>;
      grouping: Array<{ __typename?: 'QueryResponseGrouping'; key: string }>;
    };
  } | null;
};

export type ExplorationDescribeQueryQueryVariables = Exact<{
  accountId: Scalars['ID']['input'];
  baseModelId: Scalars['ID']['input'];
  pipeline: Array<OperationInput> | OperationInput;
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<QuerySortBy> | QuerySortBy>;
  variables?: InputMaybe<Scalars['JSON']['input']>;
}>;

export type ExplorationDescribeQueryQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    accountId: string;
    name: string;
    describeQuery: { __typename?: 'DescribeQueryResponse'; sql: string };
  } | null;
};

export type UpsertExplorationMutationVariables = Exact<{
  accountId: Scalars['ID']['input'];
  input: ExplorationInput;
}>;

export type UpsertExplorationMutation = {
  __typename?: 'Mutation';
  upsertExploration: {
    __typename?: 'Exploration';
    explorationId: string;
    name: string;
    description?: string | null;
    labels: any;
    parameters: Array<{ __typename?: 'ExplorationParameter'; key: string; modelId: string }>;
    view: {
      __typename?: 'ExplorationView';
      cells: Array<
        | {
            __typename?: 'CohortViewCell';
            id: string;
            kind: string;
            title?: string | null;
            viewOptions?: any | null;
            pipeline: {
              __typename?: 'QueryPipeline';
              baseModelId?: string | null;
              pipelineId?: string | null;
              parentId?: string | null;
              operations: Array<{
                __typename?: 'PipelineOperator';
                operation: string;
                parameters: any;
              }>;
            };
          }
        | {
            __typename?: 'FunnelViewCell';
            id: string;
            kind: string;
            title?: string | null;
            viewOptions?: any | null;
            pipeline: {
              __typename?: 'QueryPipeline';
              baseModelId?: string | null;
              operations: Array<{
                __typename?: 'PipelineOperator';
                operation: string;
                parameters: any;
              }>;
            };
          }
        | {
            __typename?: 'MetricViewCell';
            id: string;
            kind: string;
            metricId: string;
            viewOptions?: any | null;
          }
        | {
            __typename?: 'PythonViewCell';
            id: string;
            kind: string;
            title?: string | null;
            viewOptions?: any | null;
            pipeline: {
              __typename?: 'QueryPipeline';
              baseModelId?: string | null;
              pipelineId?: string | null;
              operations: Array<{
                __typename?: 'PipelineOperator';
                operation: string;
                parameters: any;
              }>;
            };
          }
        | {
            __typename?: 'RecordsViewCell';
            id: string;
            kind: string;
            title?: string | null;
            excludeProperties?: Array<string> | null;
            viewOptions?: any | null;
            pipeline: {
              __typename?: 'QueryPipeline';
              baseModelId?: string | null;
              pipelineId?: string | null;
              parentId?: string | null;
              operations: Array<{
                __typename?: 'PipelineOperator';
                operation: string;
                parameters: any;
                disabled?: boolean | null;
              }>;
            };
            sort?: Array<{
              __typename?: 'ExplorationViewCellSort';
              key: string;
              direction: string;
            }> | null;
            visualisations?: Array<{
              __typename?: 'Visualisation';
              aggregationType?: string | null;
              aggregationTarget?: string | null;
              groupByField?: string | null;
              timeGranularity?: string | null;
              viewOptions?: any | null;
              aggregations?: any | null;
              groups?: any | null;
              aggregation?: any | null;
              valueKeys?: Array<string> | null;
              mainAxisKey?: string | null;
            }> | null;
          }
        | {
            __typename?: 'SqlViewCell';
            id: string;
            kind: string;
            title?: string | null;
            viewOptions?: any | null;
            pipeline: {
              __typename?: 'QueryPipeline';
              baseModelId?: string | null;
              pipelineId?: string | null;
              operations: Array<{
                __typename?: 'PipelineOperator';
                operation: string;
                parameters: any;
              }>;
            };
          }
        | {
            __typename?: 'TextViewCell';
            id: string;
            kind: string;
            title?: string | null;
            content?: string | null;
            viewOptions?: any | null;
          }
        | {
            __typename?: 'VariableViewCell';
            id: string;
            kind: string;
            definition: any;
            viewOptions?: any | null;
          }
      >;
    };
  };
};

export type ExplorationsQueryVariables = Exact<{
  accountId: Scalars['ID']['input'];
}>;

export type ExplorationsQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    accountId: string;
    explorations: Array<{
      __typename?: 'Exploration';
      explorationId: string;
      name: string;
      description?: string | null;
      labels: any;
      parameters: Array<{ __typename?: 'ExplorationParameter'; key: string; modelId: string }>;
      view: {
        __typename?: 'ExplorationView';
        cells: Array<
          | {
              __typename?: 'CohortViewCell';
              id: string;
              kind: string;
              title?: string | null;
              viewOptions?: any | null;
              pipeline: {
                __typename?: 'QueryPipeline';
                baseModelId?: string | null;
                pipelineId?: string | null;
                parentId?: string | null;
                operations: Array<{
                  __typename?: 'PipelineOperator';
                  operation: string;
                  parameters: any;
                }>;
              };
            }
          | {
              __typename?: 'FunnelViewCell';
              id: string;
              kind: string;
              title?: string | null;
              viewOptions?: any | null;
              pipeline: {
                __typename?: 'QueryPipeline';
                baseModelId?: string | null;
                operations: Array<{
                  __typename?: 'PipelineOperator';
                  operation: string;
                  parameters: any;
                }>;
              };
            }
          | {
              __typename?: 'MetricViewCell';
              id: string;
              kind: string;
              metricId: string;
              viewOptions?: any | null;
            }
          | {
              __typename?: 'PythonViewCell';
              id: string;
              kind: string;
              title?: string | null;
              viewOptions?: any | null;
              pipeline: {
                __typename?: 'QueryPipeline';
                baseModelId?: string | null;
                pipelineId?: string | null;
                operations: Array<{
                  __typename?: 'PipelineOperator';
                  operation: string;
                  parameters: any;
                }>;
              };
            }
          | {
              __typename?: 'RecordsViewCell';
              id: string;
              kind: string;
              title?: string | null;
              excludeProperties?: Array<string> | null;
              viewOptions?: any | null;
              pipeline: {
                __typename?: 'QueryPipeline';
                baseModelId?: string | null;
                pipelineId?: string | null;
                parentId?: string | null;
                operations: Array<{
                  __typename?: 'PipelineOperator';
                  operation: string;
                  parameters: any;
                  disabled?: boolean | null;
                }>;
              };
              sort?: Array<{
                __typename?: 'ExplorationViewCellSort';
                key: string;
                direction: string;
              }> | null;
              visualisations?: Array<{
                __typename?: 'Visualisation';
                aggregationType?: string | null;
                aggregationTarget?: string | null;
                groupByField?: string | null;
                timeGranularity?: string | null;
                viewOptions?: any | null;
                aggregations?: any | null;
                groups?: any | null;
                aggregation?: any | null;
                valueKeys?: Array<string> | null;
                mainAxisKey?: string | null;
              }> | null;
            }
          | {
              __typename?: 'SqlViewCell';
              id: string;
              kind: string;
              title?: string | null;
              viewOptions?: any | null;
              pipeline: {
                __typename?: 'QueryPipeline';
                baseModelId?: string | null;
                pipelineId?: string | null;
                operations: Array<{
                  __typename?: 'PipelineOperator';
                  operation: string;
                  parameters: any;
                }>;
              };
            }
          | {
              __typename?: 'TextViewCell';
              id: string;
              kind: string;
              title?: string | null;
              content?: string | null;
              viewOptions?: any | null;
            }
          | {
              __typename?: 'VariableViewCell';
              id: string;
              kind: string;
              definition: any;
              viewOptions?: any | null;
            }
        >;
      };
    }>;
    models: Array<{
      __typename?: 'Model';
      modelId: string;
      name: string;
      description?: string | null;
      primaryKey: Array<string>;
      labels: any;
      semantics?: any | null;
      properties: Array<{ __typename?: 'ModelProperty'; name: string; type: string; key: string }>;
      relations: Array<{
        __typename?: 'ModelRelation';
        key: string;
        modelId: string;
        type: string;
        name: string;
        joinStrategy: {
          __typename?: 'ModelRelationJoinStrategy';
          joinKeyOnBase?: string | null;
          joinKeyOnRelated?: string | null;
          through?: {
            __typename?: 'ModelRelationJoinStrategyThrough';
            modelId: string;
            joinKeyToBase?: string | null;
            joinKeyToRelated?: string | null;
          } | null;
          steps?: Array<{
            __typename?: 'ModelRelationJoinStrategySteps';
            relationKey: string;
          }> | null;
        };
      }>;
    }>;
    metrics: Array<{
      __typename?: 'Metric';
      metricId: string;
      name: string;
      metricType: string;
      kind: MetricKind;
      services: Array<IntegrationService>;
      category: string;
      displayPrefix?: string | null;
      displayPostfix?: string | null;
      pipeline: {
        __typename?: 'QueryPipeline';
        baseModelId?: string | null;
        operations: Array<{ __typename?: 'PipelineOperator'; operation: string; parameters: any }>;
      };
    }>;
    metricsV2: Array<{ __typename?: 'MetricV2'; metricId: string; name: string; definition: any }>;
    parameterisedExplorations: Array<{
      __typename?: 'Exploration';
      explorationId: string;
      name: string;
      description?: string | null;
      labels: any;
      parameters: Array<{ __typename?: 'ExplorationParameter'; key: string; modelId: string }>;
      view: {
        __typename?: 'ExplorationView';
        cells: Array<
          | {
              __typename?: 'CohortViewCell';
              id: string;
              kind: string;
              title?: string | null;
              viewOptions?: any | null;
              pipeline: {
                __typename?: 'QueryPipeline';
                baseModelId?: string | null;
                pipelineId?: string | null;
                parentId?: string | null;
                operations: Array<{
                  __typename?: 'PipelineOperator';
                  operation: string;
                  parameters: any;
                }>;
              };
            }
          | {
              __typename?: 'FunnelViewCell';
              id: string;
              kind: string;
              title?: string | null;
              viewOptions?: any | null;
              pipeline: {
                __typename?: 'QueryPipeline';
                baseModelId?: string | null;
                operations: Array<{
                  __typename?: 'PipelineOperator';
                  operation: string;
                  parameters: any;
                }>;
              };
            }
          | {
              __typename?: 'MetricViewCell';
              id: string;
              kind: string;
              metricId: string;
              viewOptions?: any | null;
            }
          | {
              __typename?: 'PythonViewCell';
              id: string;
              kind: string;
              title?: string | null;
              viewOptions?: any | null;
              pipeline: {
                __typename?: 'QueryPipeline';
                baseModelId?: string | null;
                pipelineId?: string | null;
                operations: Array<{
                  __typename?: 'PipelineOperator';
                  operation: string;
                  parameters: any;
                }>;
              };
            }
          | {
              __typename?: 'RecordsViewCell';
              id: string;
              kind: string;
              title?: string | null;
              excludeProperties?: Array<string> | null;
              viewOptions?: any | null;
              pipeline: {
                __typename?: 'QueryPipeline';
                baseModelId?: string | null;
                pipelineId?: string | null;
                parentId?: string | null;
                operations: Array<{
                  __typename?: 'PipelineOperator';
                  operation: string;
                  parameters: any;
                  disabled?: boolean | null;
                }>;
              };
              sort?: Array<{
                __typename?: 'ExplorationViewCellSort';
                key: string;
                direction: string;
              }> | null;
              visualisations?: Array<{
                __typename?: 'Visualisation';
                aggregationType?: string | null;
                aggregationTarget?: string | null;
                groupByField?: string | null;
                timeGranularity?: string | null;
                viewOptions?: any | null;
                aggregations?: any | null;
                groups?: any | null;
                aggregation?: any | null;
                valueKeys?: Array<string> | null;
                mainAxisKey?: string | null;
              }> | null;
            }
          | {
              __typename?: 'SqlViewCell';
              id: string;
              kind: string;
              title?: string | null;
              viewOptions?: any | null;
              pipeline: {
                __typename?: 'QueryPipeline';
                baseModelId?: string | null;
                pipelineId?: string | null;
                operations: Array<{
                  __typename?: 'PipelineOperator';
                  operation: string;
                  parameters: any;
                }>;
              };
            }
          | {
              __typename?: 'TextViewCell';
              id: string;
              kind: string;
              title?: string | null;
              content?: string | null;
              viewOptions?: any | null;
            }
          | {
              __typename?: 'VariableViewCell';
              id: string;
              kind: string;
              definition: any;
              viewOptions?: any | null;
            }
        >;
      };
    }>;
  } | null;
};

export type MetricFragmentFragment = {
  __typename?: 'Metric';
  metricId: string;
  name: string;
  metricType: string;
  kind: MetricKind;
  services: Array<IntegrationService>;
  category: string;
  displayPrefix?: string | null;
  displayPostfix?: string | null;
  pipeline: {
    __typename?: 'QueryPipeline';
    baseModelId?: string | null;
    operations: Array<{ __typename?: 'PipelineOperator'; operation: string; parameters: any }>;
  };
};

export type MetricV2FragmentFragment = {
  __typename?: 'MetricV2';
  metricId: string;
  name: string;
  definition: any;
};

export type MetricDataQueryVariables = Exact<{
  accountId: Scalars['ID']['input'];
  metricId: Scalars['ID']['input'];
  startDate: Scalars['Date']['input'];
  endDate: Scalars['Date']['input'];
  aggregation: MetricValueAggregation;
}>;

export type MetricDataQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    accountId: string;
    metric?: {
      __typename?: 'Metric';
      metricId: string;
      values: Array<{ __typename?: 'MetricValue'; value: number; date: string }>;
    } | null;
  } | null;
};

export type MetricsQueryVariables = Exact<{
  accountId: Scalars['ID']['input'];
  aggregation: MetricValueAggregation;
}>;

export type MetricsQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    accountId: string;
    name: string;
    models: Array<{ __typename?: 'Model'; modelId: string; name: string }>;
    metrics: Array<{
      __typename?: 'Metric';
      metricId: string;
      name: string;
      metricType: string;
      kind: MetricKind;
      services: Array<IntegrationService>;
      category: string;
      displayPrefix?: string | null;
      displayPostfix?: string | null;
      pipeline: {
        __typename?: 'QueryPipeline';
        baseModelId?: string | null;
        operations: Array<{ __typename?: 'PipelineOperator'; operation: string; parameters: any }>;
      };
    }>;
  } | null;
};

export type ModelFragmentFragment = {
  __typename?: 'Model';
  modelId: string;
  name: string;
  description?: string | null;
  primaryKey: Array<string>;
  labels: any;
  semantics?: any | null;
  properties: Array<{ __typename?: 'ModelProperty'; name: string; type: string; key: string }>;
  relations: Array<{
    __typename?: 'ModelRelation';
    key: string;
    modelId: string;
    type: string;
    name: string;
    joinStrategy: {
      __typename?: 'ModelRelationJoinStrategy';
      joinKeyOnBase?: string | null;
      joinKeyOnRelated?: string | null;
      through?: {
        __typename?: 'ModelRelationJoinStrategyThrough';
        modelId: string;
        joinKeyToBase?: string | null;
        joinKeyToRelated?: string | null;
      } | null;
      steps?: Array<{ __typename?: 'ModelRelationJoinStrategySteps'; relationKey: string }> | null;
    };
  }>;
};

export type RecordTypeFragmentFragment = {
  __typename?: 'QueryResponseRecordType';
  key: string;
  type: string;
  name: string;
  pk?: boolean | null;
  precision?: string | null;
  model?: {
    __typename?: 'QueryResponseRecordModel';
    modelId: string;
    name: string;
    propertyKey: string;
  } | null;
  relation?: {
    __typename?: 'QueryResponseRecordRelation';
    modelId: string;
    key: string;
    name: string;
  } | null;
};

export type GetExplorationStateQueryVariables = Exact<{
  accountId: Scalars['ID']['input'];
  explorationId: Scalars['ID']['input'];
}>;

export type GetExplorationStateQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    explorationState?: {
      __typename?: 'ExplorationState';
      explorationId: string;
      previousStateId?: string | null;
      explorationSourceId?: string | null;
      state: any;
    } | null;
  } | null;
};

export type SetExplorationStateMutationVariables = Exact<{
  accountId: Scalars['ID']['input'];
  input: ExplorationStateInput;
}>;

export type SetExplorationStateMutation = {
  __typename?: 'Mutation';
  upsertExplorationState: {
    __typename?: 'ExplorationState';
    explorationId: string;
    previousStateId?: string | null;
    explorationSourceId?: string | null;
    state: any;
  };
};

export type AccountsListQueryVariables = Exact<{ [key: string]: never }>;

export type AccountsListQuery = {
  __typename?: 'Query';
  me?: { __typename?: 'User'; userId: string; email: string } | null;
  accounts: Array<{
    __typename?: 'Account';
    accountId: string;
    slug: string;
    name: string;
    timezone?: string | null;
    isOnboarded: boolean;
    features?: any | null;
    me: {
      __typename?: 'AccountUser';
      role: {
        __typename?: 'Role';
        permissions: Array<{ __typename?: 'Permission'; permissionId: UserRolePermission }>;
      };
    };
  }>;
};

export type CreateAnalyticsEventMutationVariables = Exact<{
  input: CreateAnalyticsEventInput;
  accountId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type CreateAnalyticsEventMutation = {
  __typename?: 'Mutation';
  createAnalyticsEvent:
    | { __typename?: 'AnalyticsEvent'; analyticsEventId: string }
    | { __typename?: 'CreateAnalyticsEventError'; error: string }
    | { __typename?: 'NotAllowedError'; message: string };
};

export type OnboardingStateQueryVariables = Exact<{
  accountId: Scalars['ID']['input'];
}>;

export type OnboardingStateQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    onboardingState: {
      __typename?: 'OnboardingState';
      hasConnection: boolean;
      hasConsented: boolean;
      hasOnboarded: boolean;
      hasPassedSetupCallStep: boolean;
      hasBookedSetupCall: boolean;
      welcomeSurvey?: any | null;
    };
  } | null;
};

export type CreateConnectionMutationVariables = Exact<{
  accountId: Scalars['ID']['input'];
  type: Scalars['String']['input'];
  name: Scalars['String']['input'];
  properties: Scalars['JSON']['input'];
}>;

export type CreateConnectionMutation = {
  __typename?: 'Mutation';
  createConnection: { __typename?: 'Connection'; connectionId: string };
};

export type TestConnectionQueryVariables = Exact<{
  accountId: Scalars['ID']['input'];
  type: Scalars['String']['input'];
  properties: Scalars['JSON']['input'];
}>;

export type TestConnectionQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    testConnection:
      | { __typename?: 'TestConnectionFailed'; error: string }
      | { __typename?: 'TestConnectionSuccess'; success: boolean };
  } | null;
};

export type GiveConsentMutationVariables = Exact<{
  accountId: Scalars['ID']['input'];
  input: ConsentInput;
}>;

export type GiveConsentMutation = { __typename?: 'Mutation'; giveConsent: boolean };

export type UpdateOnboardingStateMutationVariables = Exact<{
  accountId: Scalars['ID']['input'];
  key: OnboardingStateKey;
  value: Scalars['JSON']['input'];
}>;

export type UpdateOnboardingStateMutation = { __typename?: 'Mutation'; updateOnboardingState: any };

export type MeQueryVariables = Exact<{ [key: string]: never }>;

export type MeQuery = {
  __typename?: 'Query';
  me?: { __typename?: 'User'; userId: string; email: string } | null;
};

export type SignUpMutationVariables = Exact<{
  email: Scalars['String']['input'];
  consent: ConsentInput;
}>;

export type SignUpMutation = {
  __typename?: 'Mutation';
  signUp: { __typename?: 'User'; userId: string };
};

export type CreateAccessTokenMutationVariables = Exact<{
  input: CreateAccessTokenInput;
}>;

export type CreateAccessTokenMutation = {
  __typename?: 'Mutation';
  createAccessToken:
    | {
        __typename?: 'AccessTokenPreview';
        token: string;
        accessToken: {
          __typename?: 'AccessToken';
          name: string;
          preview?: string | null;
          lastUsedAt?: string | null;
          expiresAt?: string | null;
          createdAt: string;
          accessTokenId: string;
        };
      }
    | { __typename?: 'CreateAccessTokenError'; error: string }
    | { __typename?: 'NotAllowedError'; message: string };
};

export type DeleteAccessTokenMutationVariables = Exact<{
  accessTokenId: Scalars['ID']['input'];
}>;

export type DeleteAccessTokenMutation = {
  __typename?: 'Mutation';
  deleteAccessToken:
    | { __typename?: 'DeleteAccessTokenError'; error: string }
    | { __typename?: 'DeleteAccessTokenSuccess'; result: boolean }
    | { __typename?: 'NotAllowedError'; message: string };
};

export type DeleteAlertConfigurationMutationVariables = Exact<{
  accountId: Scalars['ID']['input'];
  alertConfigurationId: Scalars['ID']['input'];
}>;

export type DeleteAlertConfigurationMutation = {
  __typename?: 'Mutation';
  deleteAlertConfiguration: { __typename?: 'DeleteAlertConfigurationSuccess'; result: boolean };
};

export type UpsertAlertConfigurationMutationVariables = Exact<{
  accountId: Scalars['ID']['input'];
  input: AlertConfigurationInput;
}>;

export type UpsertAlertConfigurationMutation = {
  __typename?: 'Mutation';
  upsertAlertConfiguration: {
    __typename?: 'AlertConfiguration';
    alertConfigurationId: string;
    name: string;
    message: string;
    keyFields: Array<string>;
    pipeline: any;
    notificationConfig: any;
    createdAt: string;
  };
};

export type AccountSettingsQueryVariables = Exact<{
  accountId: Scalars['ID']['input'];
}>;

export type AccountSettingsQuery = {
  __typename?: 'Query';
  accessTokens: Array<{
    __typename?: 'AccessToken';
    name: string;
    preview?: string | null;
    expiresAt?: string | null;
    lastUsedAt?: string | null;
    createdAt: string;
    accessTokenId: string;
  }>;
  account?: {
    __typename?: 'Account';
    me: {
      __typename?: 'AccountUser';
      userId: string;
      role: {
        __typename?: 'Role';
        roleId: UserRole;
        permissions: Array<{ __typename?: 'Permission'; permissionId: UserRolePermission }>;
      };
    };
    users: Array<{
      __typename?: 'AccountUser';
      userId: string;
      email: string;
      addedAt: string;
      role: { __typename?: 'Role'; roleId: UserRole; name: string };
    }>;
    connections: Array<{
      __typename?: 'Connection';
      name: string;
      type: string;
      createdAt: string;
    }>;
    integrations: Array<{
      __typename?: 'Integration';
      integrationId: string;
      service: IntegrationService;
      description?: string | null;
    }>;
    alertConfigurations: Array<{
      __typename?: 'AlertConfiguration';
      alertConfigurationId: string;
      accountId: string;
      name: string;
      message: string;
      keyFields: Array<string>;
      pipeline: any;
      notificationConfig: any;
      createdAt: string;
    }>;
    models: Array<{
      __typename?: 'Model';
      modelId: string;
      name: string;
      primaryKey: Array<string>;
      labels: any;
      properties: Array<{ __typename?: 'ModelProperty'; name: string; type: string; key: string }>;
      relations: Array<{
        __typename?: 'ModelRelation';
        key: string;
        modelId: string;
        type: string;
        name: string;
      }>;
    }>;
    metricsV2: Array<{ __typename?: 'MetricV2'; metricId: string; name: string; definition: any }>;
    navigation: Array<{
      __typename?: 'ExplorationNavigationItem';
      kind: string;
      label: string;
      explorationId: string;
    }>;
    explorations: Array<{ __typename?: 'Exploration'; explorationId: string; name: string }>;
  } | null;
};

export type UpdateNavigationMutationVariables = Exact<{
  accountId: Scalars['ID']['input'];
  input: Scalars['JSON']['input'];
}>;

export type UpdateNavigationMutation = { __typename?: 'Mutation'; updateNavigation: boolean };

export type AddUserToAccountMutationVariables = Exact<{
  accountId: Scalars['ID']['input'];
  email: Scalars['String']['input'];
  role: UserRole;
}>;

export type AddUserToAccountMutation = {
  __typename?: 'Mutation';
  addUserToAccount: {
    __typename?: 'AccountUserChangeResponse';
    accountUser: {
      __typename?: 'AccountUser';
      userId: string;
      email: string;
      role: { __typename?: 'Role'; roleId: UserRole };
    };
  };
};

export type DeleteUserFromAccountMutationVariables = Exact<{
  accountId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
}>;

export type DeleteUserFromAccountMutation = {
  __typename?: 'Mutation';
  deleteUserFromAccount: {
    __typename?: 'AccountUserDeleteResponse';
    success: boolean;
    account: {
      __typename?: 'Account';
      accountId: string;
      users: Array<{
        __typename?: 'AccountUser';
        userId: string;
        email: string;
        addedAt: string;
        role: { __typename?: 'Role'; roleId: UserRole };
      }>;
    };
    accountUser: { __typename?: 'AccountUser'; userId: string; email: string };
  };
};

export type UpdateUserInAccountMutationVariables = Exact<{
  accountId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
  role: UserRole;
}>;

export type UpdateUserInAccountMutation = {
  __typename?: 'Mutation';
  updateUserInAccount: {
    __typename?: 'AccountUserChangeResponse';
    accountUser: { __typename?: 'AccountUser'; userId: string; email: string };
  };
};

export const ExplorationFragmentFragmentDoc = gql`
  fragment ExplorationFragment on Exploration {
    explorationId
    name
    description
    labels
    parameters {
      key
      modelId
    }
    view {
      cells {
        ... on RecordsViewCell {
          id
          kind
          title
          excludeProperties
          pipeline {
            baseModelId
            operations {
              operation
              parameters
              disabled
            }
            pipelineId
            parentId
          }
          sort {
            key
            direction
          }
          visualisations {
            aggregationType
            aggregationTarget
            groupByField
            timeGranularity
            viewOptions
            aggregations
            groups
            aggregation
            valueKeys
            mainAxisKey
          }
          viewOptions
        }
        ... on MetricViewCell {
          id
          kind
          metricId
          viewOptions
        }
        ... on FunnelViewCell {
          id
          kind
          title
          pipeline {
            baseModelId
            operations {
              operation
              parameters
            }
          }
          viewOptions
        }
        ... on CohortViewCell {
          id
          kind
          title
          pipeline {
            baseModelId
            operations {
              operation
              parameters
            }
            pipelineId
            parentId
          }
          viewOptions
        }
        ... on VariableViewCell {
          id
          kind
          definition
          viewOptions
        }
        ... on SqlViewCell {
          id
          kind
          title
          pipeline {
            baseModelId
            operations {
              operation
              parameters
            }
            pipelineId
          }
          viewOptions
        }
        ... on PythonViewCell {
          id
          kind
          title
          pipeline {
            baseModelId
            operations {
              operation
              parameters
            }
            pipelineId
          }
          viewOptions
        }
        ... on TextViewCell {
          id
          kind
          title
          content
          viewOptions
        }
      }
    }
  }
`;
export const MetricFragmentFragmentDoc = gql`
  fragment MetricFragment on Metric {
    metricId
    name
    metricType
    kind
    services
    category
    displayPrefix
    displayPostfix
    pipeline(aggregation: MONTHLY) {
      baseModelId
      operations {
        operation
        parameters
      }
    }
  }
`;
export const MetricV2FragmentFragmentDoc = gql`
  fragment MetricV2Fragment on MetricV2 {
    metricId
    name
    definition
  }
`;
export const ModelFragmentFragmentDoc = gql`
  fragment ModelFragment on Model {
    modelId
    name
    description
    primaryKey
    labels
    semantics
    properties {
      name
      type
      key
    }
    relations {
      key
      modelId
      type
      name
      joinStrategy {
        joinKeyOnBase
        joinKeyOnRelated
        through {
          modelId
          joinKeyToBase
          joinKeyToRelated
        }
        steps {
          relationKey
        }
      }
    }
  }
`;
export const RecordTypeFragmentFragmentDoc = gql`
  fragment RecordTypeFragment on QueryResponseRecordType {
    key
    type
    name
    pk
    precision
    model {
      modelId
      name
      propertyKey
    }
    relation {
      modelId
      key
      name
    }
  }
`;
export const AlertConfigurationDocument = gql`
  query AlertConfiguration($accountId: ID!, $alertConfigurationId: ID!) {
    account(accountId: $accountId) {
      alertConfiguration(alertConfigurationId: $alertConfigurationId) {
        alertConfigurationId
        accountId
        name
        message
        pipeline
        keyFields
        notificationConfig
        createdAt
      }
    }
  }
`;

/**
 * __useAlertConfigurationQuery__
 *
 * To run a query within a React component, call `useAlertConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useAlertConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAlertConfigurationQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      alertConfigurationId: // value for 'alertConfigurationId'
 *   },
 * });
 */
export function useAlertConfigurationQuery(
  baseOptions: Apollo.QueryHookOptions<AlertConfigurationQuery, AlertConfigurationQueryVariables> &
    ({ variables: AlertConfigurationQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AlertConfigurationQuery, AlertConfigurationQueryVariables>(
    AlertConfigurationDocument,
    options,
  );
}
export function useAlertConfigurationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AlertConfigurationQuery,
    AlertConfigurationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AlertConfigurationQuery, AlertConfigurationQueryVariables>(
    AlertConfigurationDocument,
    options,
  );
}
export function useAlertConfigurationSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    AlertConfigurationQuery,
    AlertConfigurationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AlertConfigurationQuery, AlertConfigurationQueryVariables>(
    AlertConfigurationDocument,
    options,
  );
}
export type AlertConfigurationQueryHookResult = ReturnType<typeof useAlertConfigurationQuery>;
export type AlertConfigurationLazyQueryHookResult = ReturnType<
  typeof useAlertConfigurationLazyQuery
>;
export type AlertConfigurationSuspenseQueryHookResult = ReturnType<
  typeof useAlertConfigurationSuspenseQuery
>;
export type AlertConfigurationQueryResult = Apollo.QueryResult<
  AlertConfigurationQuery,
  AlertConfigurationQueryVariables
>;
export const NavigationDocument = gql`
  query Navigation($accountId: ID!) {
    account(accountId: $accountId) {
      accountId
      navigation {
        ... on ExplorationNavigationItem {
          kind
          label
          explorationId
        }
      }
    }
  }
`;

/**
 * __useNavigationQuery__
 *
 * To run a query within a React component, call `useNavigationQuery` and pass it any options that fit your needs.
 * When your component renders, `useNavigationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNavigationQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useNavigationQuery(
  baseOptions: Apollo.QueryHookOptions<NavigationQuery, NavigationQueryVariables> &
    ({ variables: NavigationQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<NavigationQuery, NavigationQueryVariables>(NavigationDocument, options);
}
export function useNavigationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<NavigationQuery, NavigationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<NavigationQuery, NavigationQueryVariables>(
    NavigationDocument,
    options,
  );
}
export function useNavigationSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<NavigationQuery, NavigationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<NavigationQuery, NavigationQueryVariables>(
    NavigationDocument,
    options,
  );
}
export type NavigationQueryHookResult = ReturnType<typeof useNavigationQuery>;
export type NavigationLazyQueryHookResult = ReturnType<typeof useNavigationLazyQuery>;
export type NavigationSuspenseQueryHookResult = ReturnType<typeof useNavigationSuspenseQuery>;
export type NavigationQueryResult = Apollo.QueryResult<NavigationQuery, NavigationQueryVariables>;
export const ExplorationRecordTypeDocument = gql`
  query ExplorationRecordType(
    $accountId: ID!
    $baseModelId: ID!
    $pipeline: [OperationInput!]!
    $variables: JSON
  ) {
    account(accountId: $accountId) {
      accountId
      query(baseModelId: $baseModelId, pipeline: $pipeline, first: 1, variables: $variables) {
        recordType {
          key
          type
          name
          relation {
            modelId
            key
            name
          }
        }
        grouping {
          key
        }
      }
    }
  }
`;

/**
 * __useExplorationRecordTypeQuery__
 *
 * To run a query within a React component, call `useExplorationRecordTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useExplorationRecordTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExplorationRecordTypeQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      baseModelId: // value for 'baseModelId'
 *      pipeline: // value for 'pipeline'
 *      variables: // value for 'variables'
 *   },
 * });
 */
export function useExplorationRecordTypeQuery(
  baseOptions: Apollo.QueryHookOptions<
    ExplorationRecordTypeQuery,
    ExplorationRecordTypeQueryVariables
  > &
    ({ variables: ExplorationRecordTypeQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ExplorationRecordTypeQuery, ExplorationRecordTypeQueryVariables>(
    ExplorationRecordTypeDocument,
    options,
  );
}
export function useExplorationRecordTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExplorationRecordTypeQuery,
    ExplorationRecordTypeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ExplorationRecordTypeQuery, ExplorationRecordTypeQueryVariables>(
    ExplorationRecordTypeDocument,
    options,
  );
}
export function useExplorationRecordTypeSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ExplorationRecordTypeQuery,
    ExplorationRecordTypeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ExplorationRecordTypeQuery, ExplorationRecordTypeQueryVariables>(
    ExplorationRecordTypeDocument,
    options,
  );
}
export type ExplorationRecordTypeQueryHookResult = ReturnType<typeof useExplorationRecordTypeQuery>;
export type ExplorationRecordTypeLazyQueryHookResult = ReturnType<
  typeof useExplorationRecordTypeLazyQuery
>;
export type ExplorationRecordTypeSuspenseQueryHookResult = ReturnType<
  typeof useExplorationRecordTypeSuspenseQuery
>;
export type ExplorationRecordTypeQueryResult = Apollo.QueryResult<
  ExplorationRecordTypeQuery,
  ExplorationRecordTypeQueryVariables
>;
export const ExplorationGenerationDocument = gql`
  query ExplorationGeneration(
    $accountId: ID!
    $search: String!
    $correlationId: String
    $exploration: ExplorationInput
  ) {
    account(accountId: $accountId) {
      generateExploration(
        search: $search
        correlationId: $correlationId
        exploration: $exploration
      ) {
        exploration {
          ...ExplorationFragment
        }
      }
    }
  }
  ${ExplorationFragmentFragmentDoc}
`;

/**
 * __useExplorationGenerationQuery__
 *
 * To run a query within a React component, call `useExplorationGenerationQuery` and pass it any options that fit your needs.
 * When your component renders, `useExplorationGenerationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExplorationGenerationQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      search: // value for 'search'
 *      correlationId: // value for 'correlationId'
 *      exploration: // value for 'exploration'
 *   },
 * });
 */
export function useExplorationGenerationQuery(
  baseOptions: Apollo.QueryHookOptions<
    ExplorationGenerationQuery,
    ExplorationGenerationQueryVariables
  > &
    ({ variables: ExplorationGenerationQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ExplorationGenerationQuery, ExplorationGenerationQueryVariables>(
    ExplorationGenerationDocument,
    options,
  );
}
export function useExplorationGenerationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExplorationGenerationQuery,
    ExplorationGenerationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ExplorationGenerationQuery, ExplorationGenerationQueryVariables>(
    ExplorationGenerationDocument,
    options,
  );
}
export function useExplorationGenerationSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ExplorationGenerationQuery,
    ExplorationGenerationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ExplorationGenerationQuery, ExplorationGenerationQueryVariables>(
    ExplorationGenerationDocument,
    options,
  );
}
export type ExplorationGenerationQueryHookResult = ReturnType<typeof useExplorationGenerationQuery>;
export type ExplorationGenerationLazyQueryHookResult = ReturnType<
  typeof useExplorationGenerationLazyQuery
>;
export type ExplorationGenerationSuspenseQueryHookResult = ReturnType<
  typeof useExplorationGenerationSuspenseQuery
>;
export type ExplorationGenerationQueryResult = Apollo.QueryResult<
  ExplorationGenerationQuery,
  ExplorationGenerationQueryVariables
>;
export const ExplorationsSearchDocument = gql`
  query ExplorationsSearch($accountId: ID!, $search: String) {
    account(accountId: $accountId) {
      explorations(search: $search) {
        ...ExplorationFragment
      }
    }
  }
  ${ExplorationFragmentFragmentDoc}
`;

/**
 * __useExplorationsSearchQuery__
 *
 * To run a query within a React component, call `useExplorationsSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useExplorationsSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExplorationsSearchQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useExplorationsSearchQuery(
  baseOptions: Apollo.QueryHookOptions<ExplorationsSearchQuery, ExplorationsSearchQueryVariables> &
    ({ variables: ExplorationsSearchQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ExplorationsSearchQuery, ExplorationsSearchQueryVariables>(
    ExplorationsSearchDocument,
    options,
  );
}
export function useExplorationsSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExplorationsSearchQuery,
    ExplorationsSearchQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ExplorationsSearchQuery, ExplorationsSearchQueryVariables>(
    ExplorationsSearchDocument,
    options,
  );
}
export function useExplorationsSearchSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ExplorationsSearchQuery,
    ExplorationsSearchQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ExplorationsSearchQuery, ExplorationsSearchQueryVariables>(
    ExplorationsSearchDocument,
    options,
  );
}
export type ExplorationsSearchQueryHookResult = ReturnType<typeof useExplorationsSearchQuery>;
export type ExplorationsSearchLazyQueryHookResult = ReturnType<
  typeof useExplorationsSearchLazyQuery
>;
export type ExplorationsSearchSuspenseQueryHookResult = ReturnType<
  typeof useExplorationsSearchSuspenseQuery
>;
export type ExplorationsSearchQueryResult = Apollo.QueryResult<
  ExplorationsSearchQuery,
  ExplorationsSearchQueryVariables
>;
export const DeleteExplorationDocument = gql`
  mutation DeleteExploration($accountId: ID!, $explorationId: ID!) {
    deleteExploration(accountId: $accountId, explorationId: $explorationId) {
      result
    }
  }
`;
export type DeleteExplorationMutationFn = Apollo.MutationFunction<
  DeleteExplorationMutation,
  DeleteExplorationMutationVariables
>;

/**
 * __useDeleteExplorationMutation__
 *
 * To run a mutation, you first call `useDeleteExplorationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteExplorationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteExplorationMutation, { data, loading, error }] = useDeleteExplorationMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      explorationId: // value for 'explorationId'
 *   },
 * });
 */
export function useDeleteExplorationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteExplorationMutation,
    DeleteExplorationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteExplorationMutation, DeleteExplorationMutationVariables>(
    DeleteExplorationDocument,
    options,
  );
}
export type DeleteExplorationMutationHookResult = ReturnType<typeof useDeleteExplorationMutation>;
export type DeleteExplorationMutationResult = Apollo.MutationResult<DeleteExplorationMutation>;
export type DeleteExplorationMutationOptions = Apollo.BaseMutationOptions<
  DeleteExplorationMutation,
  DeleteExplorationMutationVariables
>;
export const EnumOptionsDocument = gql`
  query EnumOptions($accountId: ID!, $modelId: ID!, $propertyKey: String!) {
    account(accountId: $accountId) {
      model(modelId: $modelId) {
        modelId
        property(key: $propertyKey) {
          key
          enumOptions {
            label
            value
          }
        }
      }
    }
  }
`;

/**
 * __useEnumOptionsQuery__
 *
 * To run a query within a React component, call `useEnumOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnumOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnumOptionsQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      modelId: // value for 'modelId'
 *      propertyKey: // value for 'propertyKey'
 *   },
 * });
 */
export function useEnumOptionsQuery(
  baseOptions: Apollo.QueryHookOptions<EnumOptionsQuery, EnumOptionsQueryVariables> &
    ({ variables: EnumOptionsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EnumOptionsQuery, EnumOptionsQueryVariables>(EnumOptionsDocument, options);
}
export function useEnumOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<EnumOptionsQuery, EnumOptionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EnumOptionsQuery, EnumOptionsQueryVariables>(
    EnumOptionsDocument,
    options,
  );
}
export function useEnumOptionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<EnumOptionsQuery, EnumOptionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<EnumOptionsQuery, EnumOptionsQueryVariables>(
    EnumOptionsDocument,
    options,
  );
}
export type EnumOptionsQueryHookResult = ReturnType<typeof useEnumOptionsQuery>;
export type EnumOptionsLazyQueryHookResult = ReturnType<typeof useEnumOptionsLazyQuery>;
export type EnumOptionsSuspenseQueryHookResult = ReturnType<typeof useEnumOptionsSuspenseQuery>;
export type EnumOptionsQueryResult = Apollo.QueryResult<
  EnumOptionsQuery,
  EnumOptionsQueryVariables
>;
export const ExplorationDataDocument = gql`
  query ExplorationData(
    $accountId: ID!
    $baseModelId: ID!
    $pipeline: [OperationInput!]!
    $first: Int
    $after: String
    $sort: [QuerySortBy!]
    $variables: JSON
  ) {
    account(accountId: $accountId) {
      accountId
      name
      query(
        baseModelId: $baseModelId
        pipeline: $pipeline
        first: $first
        after: $after
        sort: $sort
        variables: $variables
      ) {
        edges {
          node
        }
        pageInfo {
          hasNextPage
          endCursor
        }
        recordType {
          ...RecordTypeFragment
        }
        grouping {
          key
        }
      }
    }
  }
  ${RecordTypeFragmentFragmentDoc}
`;

/**
 * __useExplorationDataQuery__
 *
 * To run a query within a React component, call `useExplorationDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useExplorationDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExplorationDataQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      baseModelId: // value for 'baseModelId'
 *      pipeline: // value for 'pipeline'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      sort: // value for 'sort'
 *      variables: // value for 'variables'
 *   },
 * });
 */
export function useExplorationDataQuery(
  baseOptions: Apollo.QueryHookOptions<ExplorationDataQuery, ExplorationDataQueryVariables> &
    ({ variables: ExplorationDataQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ExplorationDataQuery, ExplorationDataQueryVariables>(
    ExplorationDataDocument,
    options,
  );
}
export function useExplorationDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ExplorationDataQuery, ExplorationDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ExplorationDataQuery, ExplorationDataQueryVariables>(
    ExplorationDataDocument,
    options,
  );
}
export function useExplorationDataSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ExplorationDataQuery,
    ExplorationDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ExplorationDataQuery, ExplorationDataQueryVariables>(
    ExplorationDataDocument,
    options,
  );
}
export type ExplorationDataQueryHookResult = ReturnType<typeof useExplorationDataQuery>;
export type ExplorationDataLazyQueryHookResult = ReturnType<typeof useExplorationDataLazyQuery>;
export type ExplorationDataSuspenseQueryHookResult = ReturnType<
  typeof useExplorationDataSuspenseQuery
>;
export type ExplorationDataQueryResult = Apollo.QueryResult<
  ExplorationDataQuery,
  ExplorationDataQueryVariables
>;
export const ExplorationDescribeQueryDocument = gql`
  query ExplorationDescribeQuery(
    $accountId: ID!
    $baseModelId: ID!
    $pipeline: [OperationInput!]!
    $first: Int
    $after: String
    $sort: [QuerySortBy!]
    $variables: JSON
  ) {
    account(accountId: $accountId) {
      accountId
      name
      describeQuery(
        baseModelId: $baseModelId
        pipeline: $pipeline
        first: $first
        after: $after
        sort: $sort
        variables: $variables
      ) {
        sql
      }
    }
  }
`;

/**
 * __useExplorationDescribeQueryQuery__
 *
 * To run a query within a React component, call `useExplorationDescribeQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useExplorationDescribeQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExplorationDescribeQueryQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      baseModelId: // value for 'baseModelId'
 *      pipeline: // value for 'pipeline'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      sort: // value for 'sort'
 *      variables: // value for 'variables'
 *   },
 * });
 */
export function useExplorationDescribeQueryQuery(
  baseOptions: Apollo.QueryHookOptions<
    ExplorationDescribeQueryQuery,
    ExplorationDescribeQueryQueryVariables
  > &
    ({ variables: ExplorationDescribeQueryQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ExplorationDescribeQueryQuery, ExplorationDescribeQueryQueryVariables>(
    ExplorationDescribeQueryDocument,
    options,
  );
}
export function useExplorationDescribeQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExplorationDescribeQueryQuery,
    ExplorationDescribeQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ExplorationDescribeQueryQuery, ExplorationDescribeQueryQueryVariables>(
    ExplorationDescribeQueryDocument,
    options,
  );
}
export function useExplorationDescribeQuerySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ExplorationDescribeQueryQuery,
    ExplorationDescribeQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ExplorationDescribeQueryQuery,
    ExplorationDescribeQueryQueryVariables
  >(ExplorationDescribeQueryDocument, options);
}
export type ExplorationDescribeQueryQueryHookResult = ReturnType<
  typeof useExplorationDescribeQueryQuery
>;
export type ExplorationDescribeQueryLazyQueryHookResult = ReturnType<
  typeof useExplorationDescribeQueryLazyQuery
>;
export type ExplorationDescribeQuerySuspenseQueryHookResult = ReturnType<
  typeof useExplorationDescribeQuerySuspenseQuery
>;
export type ExplorationDescribeQueryQueryResult = Apollo.QueryResult<
  ExplorationDescribeQueryQuery,
  ExplorationDescribeQueryQueryVariables
>;
export const UpsertExplorationDocument = gql`
  mutation UpsertExploration($accountId: ID!, $input: ExplorationInput!) {
    upsertExploration(accountId: $accountId, input: $input) {
      ...ExplorationFragment
    }
  }
  ${ExplorationFragmentFragmentDoc}
`;
export type UpsertExplorationMutationFn = Apollo.MutationFunction<
  UpsertExplorationMutation,
  UpsertExplorationMutationVariables
>;

/**
 * __useUpsertExplorationMutation__
 *
 * To run a mutation, you first call `useUpsertExplorationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertExplorationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertExplorationMutation, { data, loading, error }] = useUpsertExplorationMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertExplorationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertExplorationMutation,
    UpsertExplorationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpsertExplorationMutation, UpsertExplorationMutationVariables>(
    UpsertExplorationDocument,
    options,
  );
}
export type UpsertExplorationMutationHookResult = ReturnType<typeof useUpsertExplorationMutation>;
export type UpsertExplorationMutationResult = Apollo.MutationResult<UpsertExplorationMutation>;
export type UpsertExplorationMutationOptions = Apollo.BaseMutationOptions<
  UpsertExplorationMutation,
  UpsertExplorationMutationVariables
>;
export const ExplorationsDocument = gql`
  query Explorations($accountId: ID!) {
    account(accountId: $accountId) {
      accountId
      explorations {
        ...ExplorationFragment
      }
      models {
        ...ModelFragment
      }
      metrics {
        ...MetricFragment
      }
      metricsV2 {
        ...MetricV2Fragment
      }
      parameterisedExplorations {
        ...ExplorationFragment
      }
    }
  }
  ${ExplorationFragmentFragmentDoc}
  ${ModelFragmentFragmentDoc}
  ${MetricFragmentFragmentDoc}
  ${MetricV2FragmentFragmentDoc}
`;

/**
 * __useExplorationsQuery__
 *
 * To run a query within a React component, call `useExplorationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExplorationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExplorationsQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useExplorationsQuery(
  baseOptions: Apollo.QueryHookOptions<ExplorationsQuery, ExplorationsQueryVariables> &
    ({ variables: ExplorationsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ExplorationsQuery, ExplorationsQueryVariables>(
    ExplorationsDocument,
    options,
  );
}
export function useExplorationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ExplorationsQuery, ExplorationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ExplorationsQuery, ExplorationsQueryVariables>(
    ExplorationsDocument,
    options,
  );
}
export function useExplorationsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ExplorationsQuery, ExplorationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ExplorationsQuery, ExplorationsQueryVariables>(
    ExplorationsDocument,
    options,
  );
}
export type ExplorationsQueryHookResult = ReturnType<typeof useExplorationsQuery>;
export type ExplorationsLazyQueryHookResult = ReturnType<typeof useExplorationsLazyQuery>;
export type ExplorationsSuspenseQueryHookResult = ReturnType<typeof useExplorationsSuspenseQuery>;
export type ExplorationsQueryResult = Apollo.QueryResult<
  ExplorationsQuery,
  ExplorationsQueryVariables
>;
export const MetricDataDocument = gql`
  query MetricData(
    $accountId: ID!
    $metricId: ID!
    $startDate: Date!
    $endDate: Date!
    $aggregation: MetricValueAggregation!
  ) {
    account(accountId: $accountId) {
      accountId
      metric(metricId: $metricId) {
        metricId
        values(startDate: $startDate, endDate: $endDate, aggregation: $aggregation) {
          value
          date
        }
      }
    }
  }
`;

/**
 * __useMetricDataQuery__
 *
 * To run a query within a React component, call `useMetricDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useMetricDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMetricDataQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      metricId: // value for 'metricId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      aggregation: // value for 'aggregation'
 *   },
 * });
 */
export function useMetricDataQuery(
  baseOptions: Apollo.QueryHookOptions<MetricDataQuery, MetricDataQueryVariables> &
    ({ variables: MetricDataQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MetricDataQuery, MetricDataQueryVariables>(MetricDataDocument, options);
}
export function useMetricDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MetricDataQuery, MetricDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MetricDataQuery, MetricDataQueryVariables>(
    MetricDataDocument,
    options,
  );
}
export function useMetricDataSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<MetricDataQuery, MetricDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MetricDataQuery, MetricDataQueryVariables>(
    MetricDataDocument,
    options,
  );
}
export type MetricDataQueryHookResult = ReturnType<typeof useMetricDataQuery>;
export type MetricDataLazyQueryHookResult = ReturnType<typeof useMetricDataLazyQuery>;
export type MetricDataSuspenseQueryHookResult = ReturnType<typeof useMetricDataSuspenseQuery>;
export type MetricDataQueryResult = Apollo.QueryResult<MetricDataQuery, MetricDataQueryVariables>;
export const MetricsDocument = gql`
  query Metrics($accountId: ID!, $aggregation: MetricValueAggregation!) {
    account(accountId: $accountId) {
      accountId
      name
      models {
        modelId
        name
      }
      metrics {
        metricId
        name
        metricType
        kind
        services
        category
        displayPrefix
        displayPostfix
        pipeline(aggregation: $aggregation) {
          baseModelId
          operations {
            operation
            parameters
          }
        }
      }
    }
  }
`;

/**
 * __useMetricsQuery__
 *
 * To run a query within a React component, call `useMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMetricsQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      aggregation: // value for 'aggregation'
 *   },
 * });
 */
export function useMetricsQuery(
  baseOptions: Apollo.QueryHookOptions<MetricsQuery, MetricsQueryVariables> &
    ({ variables: MetricsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MetricsQuery, MetricsQueryVariables>(MetricsDocument, options);
}
export function useMetricsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MetricsQuery, MetricsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MetricsQuery, MetricsQueryVariables>(MetricsDocument, options);
}
export function useMetricsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<MetricsQuery, MetricsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MetricsQuery, MetricsQueryVariables>(MetricsDocument, options);
}
export type MetricsQueryHookResult = ReturnType<typeof useMetricsQuery>;
export type MetricsLazyQueryHookResult = ReturnType<typeof useMetricsLazyQuery>;
export type MetricsSuspenseQueryHookResult = ReturnType<typeof useMetricsSuspenseQuery>;
export type MetricsQueryResult = Apollo.QueryResult<MetricsQuery, MetricsQueryVariables>;
export const GetExplorationStateDocument = gql`
  query GetExplorationState($accountId: ID!, $explorationId: ID!) {
    account(accountId: $accountId) {
      explorationState(explorationId: $explorationId) {
        explorationId
        previousStateId
        explorationSourceId
        state
      }
    }
  }
`;

/**
 * __useGetExplorationStateQuery__
 *
 * To run a query within a React component, call `useGetExplorationStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExplorationStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExplorationStateQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      explorationId: // value for 'explorationId'
 *   },
 * });
 */
export function useGetExplorationStateQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetExplorationStateQuery,
    GetExplorationStateQueryVariables
  > &
    ({ variables: GetExplorationStateQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetExplorationStateQuery, GetExplorationStateQueryVariables>(
    GetExplorationStateDocument,
    options,
  );
}
export function useGetExplorationStateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetExplorationStateQuery,
    GetExplorationStateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetExplorationStateQuery, GetExplorationStateQueryVariables>(
    GetExplorationStateDocument,
    options,
  );
}
export function useGetExplorationStateSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetExplorationStateQuery,
    GetExplorationStateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetExplorationStateQuery, GetExplorationStateQueryVariables>(
    GetExplorationStateDocument,
    options,
  );
}
export type GetExplorationStateQueryHookResult = ReturnType<typeof useGetExplorationStateQuery>;
export type GetExplorationStateLazyQueryHookResult = ReturnType<
  typeof useGetExplorationStateLazyQuery
>;
export type GetExplorationStateSuspenseQueryHookResult = ReturnType<
  typeof useGetExplorationStateSuspenseQuery
>;
export type GetExplorationStateQueryResult = Apollo.QueryResult<
  GetExplorationStateQuery,
  GetExplorationStateQueryVariables
>;
export const SetExplorationStateDocument = gql`
  mutation SetExplorationState($accountId: ID!, $input: ExplorationStateInput!) {
    upsertExplorationState(accountId: $accountId, input: $input) {
      explorationId
      previousStateId
      explorationSourceId
      state
    }
  }
`;
export type SetExplorationStateMutationFn = Apollo.MutationFunction<
  SetExplorationStateMutation,
  SetExplorationStateMutationVariables
>;

/**
 * __useSetExplorationStateMutation__
 *
 * To run a mutation, you first call `useSetExplorationStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetExplorationStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setExplorationStateMutation, { data, loading, error }] = useSetExplorationStateMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetExplorationStateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetExplorationStateMutation,
    SetExplorationStateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetExplorationStateMutation, SetExplorationStateMutationVariables>(
    SetExplorationStateDocument,
    options,
  );
}
export type SetExplorationStateMutationHookResult = ReturnType<
  typeof useSetExplorationStateMutation
>;
export type SetExplorationStateMutationResult = Apollo.MutationResult<SetExplorationStateMutation>;
export type SetExplorationStateMutationOptions = Apollo.BaseMutationOptions<
  SetExplorationStateMutation,
  SetExplorationStateMutationVariables
>;
export const AccountsListDocument = gql`
  query AccountsList {
    me {
      userId
      email
    }
    accounts {
      accountId
      slug
      name
      timezone
      isOnboarded
      features
      me {
        role {
          permissions {
            permissionId
          }
        }
      }
    }
  }
`;

/**
 * __useAccountsListQuery__
 *
 * To run a query within a React component, call `useAccountsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountsListQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccountsListQuery(
  baseOptions?: Apollo.QueryHookOptions<AccountsListQuery, AccountsListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AccountsListQuery, AccountsListQueryVariables>(
    AccountsListDocument,
    options,
  );
}
export function useAccountsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AccountsListQuery, AccountsListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AccountsListQuery, AccountsListQueryVariables>(
    AccountsListDocument,
    options,
  );
}
export function useAccountsListSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<AccountsListQuery, AccountsListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AccountsListQuery, AccountsListQueryVariables>(
    AccountsListDocument,
    options,
  );
}
export type AccountsListQueryHookResult = ReturnType<typeof useAccountsListQuery>;
export type AccountsListLazyQueryHookResult = ReturnType<typeof useAccountsListLazyQuery>;
export type AccountsListSuspenseQueryHookResult = ReturnType<typeof useAccountsListSuspenseQuery>;
export type AccountsListQueryResult = Apollo.QueryResult<
  AccountsListQuery,
  AccountsListQueryVariables
>;
export const CreateAnalyticsEventDocument = gql`
  mutation CreateAnalyticsEvent($input: CreateAnalyticsEventInput!, $accountId: ID) {
    createAnalyticsEvent(input: $input, accountId: $accountId) {
      ... on AnalyticsEvent {
        analyticsEventId
      }
      ... on NotAllowedError {
        message
      }
      ... on CreateAnalyticsEventError {
        error
      }
    }
  }
`;
export type CreateAnalyticsEventMutationFn = Apollo.MutationFunction<
  CreateAnalyticsEventMutation,
  CreateAnalyticsEventMutationVariables
>;

/**
 * __useCreateAnalyticsEventMutation__
 *
 * To run a mutation, you first call `useCreateAnalyticsEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAnalyticsEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAnalyticsEventMutation, { data, loading, error }] = useCreateAnalyticsEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useCreateAnalyticsEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAnalyticsEventMutation,
    CreateAnalyticsEventMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateAnalyticsEventMutation, CreateAnalyticsEventMutationVariables>(
    CreateAnalyticsEventDocument,
    options,
  );
}
export type CreateAnalyticsEventMutationHookResult = ReturnType<
  typeof useCreateAnalyticsEventMutation
>;
export type CreateAnalyticsEventMutationResult =
  Apollo.MutationResult<CreateAnalyticsEventMutation>;
export type CreateAnalyticsEventMutationOptions = Apollo.BaseMutationOptions<
  CreateAnalyticsEventMutation,
  CreateAnalyticsEventMutationVariables
>;
export const OnboardingStateDocument = gql`
  query OnboardingState($accountId: ID!) {
    account(accountId: $accountId) {
      onboardingState {
        hasConnection
        hasConsented
        hasOnboarded
        hasPassedSetupCallStep
        hasBookedSetupCall
        welcomeSurvey
      }
    }
  }
`;

/**
 * __useOnboardingStateQuery__
 *
 * To run a query within a React component, call `useOnboardingStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useOnboardingStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnboardingStateQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useOnboardingStateQuery(
  baseOptions: Apollo.QueryHookOptions<OnboardingStateQuery, OnboardingStateQueryVariables> &
    ({ variables: OnboardingStateQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OnboardingStateQuery, OnboardingStateQueryVariables>(
    OnboardingStateDocument,
    options,
  );
}
export function useOnboardingStateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OnboardingStateQuery, OnboardingStateQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OnboardingStateQuery, OnboardingStateQueryVariables>(
    OnboardingStateDocument,
    options,
  );
}
export function useOnboardingStateSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    OnboardingStateQuery,
    OnboardingStateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<OnboardingStateQuery, OnboardingStateQueryVariables>(
    OnboardingStateDocument,
    options,
  );
}
export type OnboardingStateQueryHookResult = ReturnType<typeof useOnboardingStateQuery>;
export type OnboardingStateLazyQueryHookResult = ReturnType<typeof useOnboardingStateLazyQuery>;
export type OnboardingStateSuspenseQueryHookResult = ReturnType<
  typeof useOnboardingStateSuspenseQuery
>;
export type OnboardingStateQueryResult = Apollo.QueryResult<
  OnboardingStateQuery,
  OnboardingStateQueryVariables
>;
export const CreateConnectionDocument = gql`
  mutation CreateConnection($accountId: ID!, $type: String!, $name: String!, $properties: JSON!) {
    createConnection(accountId: $accountId, type: $type, name: $name, properties: $properties) {
      connectionId
    }
  }
`;
export type CreateConnectionMutationFn = Apollo.MutationFunction<
  CreateConnectionMutation,
  CreateConnectionMutationVariables
>;

/**
 * __useCreateConnectionMutation__
 *
 * To run a mutation, you first call `useCreateConnectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateConnectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createConnectionMutation, { data, loading, error }] = useCreateConnectionMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      type: // value for 'type'
 *      name: // value for 'name'
 *      properties: // value for 'properties'
 *   },
 * });
 */
export function useCreateConnectionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateConnectionMutation,
    CreateConnectionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateConnectionMutation, CreateConnectionMutationVariables>(
    CreateConnectionDocument,
    options,
  );
}
export type CreateConnectionMutationHookResult = ReturnType<typeof useCreateConnectionMutation>;
export type CreateConnectionMutationResult = Apollo.MutationResult<CreateConnectionMutation>;
export type CreateConnectionMutationOptions = Apollo.BaseMutationOptions<
  CreateConnectionMutation,
  CreateConnectionMutationVariables
>;
export const TestConnectionDocument = gql`
  query TestConnection($accountId: ID!, $type: String!, $properties: JSON!) {
    account(accountId: $accountId) {
      testConnection(type: $type, properties: $properties) {
        ... on TestConnectionSuccess {
          success
        }
        ... on TestConnectionFailed {
          error
        }
      }
    }
  }
`;

/**
 * __useTestConnectionQuery__
 *
 * To run a query within a React component, call `useTestConnectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useTestConnectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTestConnectionQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      type: // value for 'type'
 *      properties: // value for 'properties'
 *   },
 * });
 */
export function useTestConnectionQuery(
  baseOptions: Apollo.QueryHookOptions<TestConnectionQuery, TestConnectionQueryVariables> &
    ({ variables: TestConnectionQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TestConnectionQuery, TestConnectionQueryVariables>(
    TestConnectionDocument,
    options,
  );
}
export function useTestConnectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TestConnectionQuery, TestConnectionQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TestConnectionQuery, TestConnectionQueryVariables>(
    TestConnectionDocument,
    options,
  );
}
export function useTestConnectionSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<TestConnectionQuery, TestConnectionQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TestConnectionQuery, TestConnectionQueryVariables>(
    TestConnectionDocument,
    options,
  );
}
export type TestConnectionQueryHookResult = ReturnType<typeof useTestConnectionQuery>;
export type TestConnectionLazyQueryHookResult = ReturnType<typeof useTestConnectionLazyQuery>;
export type TestConnectionSuspenseQueryHookResult = ReturnType<
  typeof useTestConnectionSuspenseQuery
>;
export type TestConnectionQueryResult = Apollo.QueryResult<
  TestConnectionQuery,
  TestConnectionQueryVariables
>;
export const GiveConsentDocument = gql`
  mutation GiveConsent($accountId: ID!, $input: ConsentInput!) {
    giveConsent(accountId: $accountId, input: $input)
  }
`;
export type GiveConsentMutationFn = Apollo.MutationFunction<
  GiveConsentMutation,
  GiveConsentMutationVariables
>;

/**
 * __useGiveConsentMutation__
 *
 * To run a mutation, you first call `useGiveConsentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGiveConsentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [giveConsentMutation, { data, loading, error }] = useGiveConsentMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGiveConsentMutation(
  baseOptions?: Apollo.MutationHookOptions<GiveConsentMutation, GiveConsentMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<GiveConsentMutation, GiveConsentMutationVariables>(
    GiveConsentDocument,
    options,
  );
}
export type GiveConsentMutationHookResult = ReturnType<typeof useGiveConsentMutation>;
export type GiveConsentMutationResult = Apollo.MutationResult<GiveConsentMutation>;
export type GiveConsentMutationOptions = Apollo.BaseMutationOptions<
  GiveConsentMutation,
  GiveConsentMutationVariables
>;
export const UpdateOnboardingStateDocument = gql`
  mutation UpdateOnboardingState($accountId: ID!, $key: OnboardingStateKey!, $value: JSON!) {
    updateOnboardingState(accountId: $accountId, key: $key, value: $value)
  }
`;
export type UpdateOnboardingStateMutationFn = Apollo.MutationFunction<
  UpdateOnboardingStateMutation,
  UpdateOnboardingStateMutationVariables
>;

/**
 * __useUpdateOnboardingStateMutation__
 *
 * To run a mutation, you first call `useUpdateOnboardingStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOnboardingStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOnboardingStateMutation, { data, loading, error }] = useUpdateOnboardingStateMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      key: // value for 'key'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useUpdateOnboardingStateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOnboardingStateMutation,
    UpdateOnboardingStateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateOnboardingStateMutation, UpdateOnboardingStateMutationVariables>(
    UpdateOnboardingStateDocument,
    options,
  );
}
export type UpdateOnboardingStateMutationHookResult = ReturnType<
  typeof useUpdateOnboardingStateMutation
>;
export type UpdateOnboardingStateMutationResult =
  Apollo.MutationResult<UpdateOnboardingStateMutation>;
export type UpdateOnboardingStateMutationOptions = Apollo.BaseMutationOptions<
  UpdateOnboardingStateMutation,
  UpdateOnboardingStateMutationVariables
>;
export const MeDocument = gql`
  query Me {
    me {
      userId
      email
    }
  }
`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export function useMeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export function useMeSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<MeQuery, MeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeSuspenseQueryHookResult = ReturnType<typeof useMeSuspenseQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const SignUpDocument = gql`
  mutation SignUp($email: String!, $consent: ConsentInput!) {
    signUp(email: $email, consent: $consent) {
      userId
    }
  }
`;
export type SignUpMutationFn = Apollo.MutationFunction<SignUpMutation, SignUpMutationVariables>;

/**
 * __useSignUpMutation__
 *
 * To run a mutation, you first call `useSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpMutation, { data, loading, error }] = useSignUpMutation({
 *   variables: {
 *      email: // value for 'email'
 *      consent: // value for 'consent'
 *   },
 * });
 */
export function useSignUpMutation(
  baseOptions?: Apollo.MutationHookOptions<SignUpMutation, SignUpMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SignUpMutation, SignUpMutationVariables>(SignUpDocument, options);
}
export type SignUpMutationHookResult = ReturnType<typeof useSignUpMutation>;
export type SignUpMutationResult = Apollo.MutationResult<SignUpMutation>;
export type SignUpMutationOptions = Apollo.BaseMutationOptions<
  SignUpMutation,
  SignUpMutationVariables
>;
export const CreateAccessTokenDocument = gql`
  mutation CreateAccessToken($input: CreateAccessTokenInput!) {
    createAccessToken(input: $input) {
      ... on AccessTokenPreview {
        accessToken {
          name
          preview
          lastUsedAt
          expiresAt
          createdAt
          accessTokenId
        }
        token
      }
      ... on NotAllowedError {
        message
      }
      ... on CreateAccessTokenError {
        error
      }
    }
  }
`;
export type CreateAccessTokenMutationFn = Apollo.MutationFunction<
  CreateAccessTokenMutation,
  CreateAccessTokenMutationVariables
>;

/**
 * __useCreateAccessTokenMutation__
 *
 * To run a mutation, you first call `useCreateAccessTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccessTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccessTokenMutation, { data, loading, error }] = useCreateAccessTokenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAccessTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAccessTokenMutation,
    CreateAccessTokenMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateAccessTokenMutation, CreateAccessTokenMutationVariables>(
    CreateAccessTokenDocument,
    options,
  );
}
export type CreateAccessTokenMutationHookResult = ReturnType<typeof useCreateAccessTokenMutation>;
export type CreateAccessTokenMutationResult = Apollo.MutationResult<CreateAccessTokenMutation>;
export type CreateAccessTokenMutationOptions = Apollo.BaseMutationOptions<
  CreateAccessTokenMutation,
  CreateAccessTokenMutationVariables
>;
export const DeleteAccessTokenDocument = gql`
  mutation DeleteAccessToken($accessTokenId: ID!) {
    deleteAccessToken(accessTokenId: $accessTokenId) {
      ... on DeleteAccessTokenSuccess {
        result
      }
      ... on NotAllowedError {
        message
      }
      ... on DeleteAccessTokenError {
        error
      }
    }
  }
`;
export type DeleteAccessTokenMutationFn = Apollo.MutationFunction<
  DeleteAccessTokenMutation,
  DeleteAccessTokenMutationVariables
>;

/**
 * __useDeleteAccessTokenMutation__
 *
 * To run a mutation, you first call `useDeleteAccessTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAccessTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAccessTokenMutation, { data, loading, error }] = useDeleteAccessTokenMutation({
 *   variables: {
 *      accessTokenId: // value for 'accessTokenId'
 *   },
 * });
 */
export function useDeleteAccessTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteAccessTokenMutation,
    DeleteAccessTokenMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteAccessTokenMutation, DeleteAccessTokenMutationVariables>(
    DeleteAccessTokenDocument,
    options,
  );
}
export type DeleteAccessTokenMutationHookResult = ReturnType<typeof useDeleteAccessTokenMutation>;
export type DeleteAccessTokenMutationResult = Apollo.MutationResult<DeleteAccessTokenMutation>;
export type DeleteAccessTokenMutationOptions = Apollo.BaseMutationOptions<
  DeleteAccessTokenMutation,
  DeleteAccessTokenMutationVariables
>;
export const DeleteAlertConfigurationDocument = gql`
  mutation DeleteAlertConfiguration($accountId: ID!, $alertConfigurationId: ID!) {
    deleteAlertConfiguration(accountId: $accountId, alertConfigurationId: $alertConfigurationId) {
      ... on DeleteAlertConfigurationSuccess {
        result
      }
    }
  }
`;
export type DeleteAlertConfigurationMutationFn = Apollo.MutationFunction<
  DeleteAlertConfigurationMutation,
  DeleteAlertConfigurationMutationVariables
>;

/**
 * __useDeleteAlertConfigurationMutation__
 *
 * To run a mutation, you first call `useDeleteAlertConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAlertConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAlertConfigurationMutation, { data, loading, error }] = useDeleteAlertConfigurationMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      alertConfigurationId: // value for 'alertConfigurationId'
 *   },
 * });
 */
export function useDeleteAlertConfigurationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteAlertConfigurationMutation,
    DeleteAlertConfigurationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteAlertConfigurationMutation,
    DeleteAlertConfigurationMutationVariables
  >(DeleteAlertConfigurationDocument, options);
}
export type DeleteAlertConfigurationMutationHookResult = ReturnType<
  typeof useDeleteAlertConfigurationMutation
>;
export type DeleteAlertConfigurationMutationResult =
  Apollo.MutationResult<DeleteAlertConfigurationMutation>;
export type DeleteAlertConfigurationMutationOptions = Apollo.BaseMutationOptions<
  DeleteAlertConfigurationMutation,
  DeleteAlertConfigurationMutationVariables
>;
export const UpsertAlertConfigurationDocument = gql`
  mutation UpsertAlertConfiguration($accountId: ID!, $input: AlertConfigurationInput!) {
    upsertAlertConfiguration(accountId: $accountId, input: $input) {
      alertConfigurationId
      name
      message
      keyFields
      pipeline
      notificationConfig
      createdAt
    }
  }
`;
export type UpsertAlertConfigurationMutationFn = Apollo.MutationFunction<
  UpsertAlertConfigurationMutation,
  UpsertAlertConfigurationMutationVariables
>;

/**
 * __useUpsertAlertConfigurationMutation__
 *
 * To run a mutation, you first call `useUpsertAlertConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertAlertConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertAlertConfigurationMutation, { data, loading, error }] = useUpsertAlertConfigurationMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertAlertConfigurationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertAlertConfigurationMutation,
    UpsertAlertConfigurationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpsertAlertConfigurationMutation,
    UpsertAlertConfigurationMutationVariables
  >(UpsertAlertConfigurationDocument, options);
}
export type UpsertAlertConfigurationMutationHookResult = ReturnType<
  typeof useUpsertAlertConfigurationMutation
>;
export type UpsertAlertConfigurationMutationResult =
  Apollo.MutationResult<UpsertAlertConfigurationMutation>;
export type UpsertAlertConfigurationMutationOptions = Apollo.BaseMutationOptions<
  UpsertAlertConfigurationMutation,
  UpsertAlertConfigurationMutationVariables
>;
export const AccountSettingsDocument = gql`
  query AccountSettings($accountId: ID!) {
    accessTokens {
      name
      preview
      expiresAt
      lastUsedAt
      createdAt
      accessTokenId
    }
    account(accountId: $accountId) {
      me {
        userId
        role {
          roleId
          permissions {
            permissionId
          }
        }
      }
      users {
        userId
        email
        role {
          roleId
          name
        }
        addedAt
      }
      connections {
        name
        type
        createdAt
      }
      integrations {
        integrationId
        service
        description
      }
      alertConfigurations {
        alertConfigurationId
        accountId
        name
        message
        keyFields
        pipeline
        notificationConfig
        createdAt
      }
      models {
        modelId
        name
        primaryKey
        labels
        properties {
          name
          type
          key
        }
        relations {
          key
          modelId
          type
          name
        }
      }
      metricsV2 {
        ...MetricV2Fragment
      }
      navigation {
        ... on ExplorationNavigationItem {
          kind
          label
          explorationId
        }
      }
      explorations {
        explorationId
        name
      }
    }
  }
  ${MetricV2FragmentFragmentDoc}
`;

/**
 * __useAccountSettingsQuery__
 *
 * To run a query within a React component, call `useAccountSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountSettingsQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useAccountSettingsQuery(
  baseOptions: Apollo.QueryHookOptions<AccountSettingsQuery, AccountSettingsQueryVariables> &
    ({ variables: AccountSettingsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AccountSettingsQuery, AccountSettingsQueryVariables>(
    AccountSettingsDocument,
    options,
  );
}
export function useAccountSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AccountSettingsQuery, AccountSettingsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AccountSettingsQuery, AccountSettingsQueryVariables>(
    AccountSettingsDocument,
    options,
  );
}
export function useAccountSettingsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    AccountSettingsQuery,
    AccountSettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AccountSettingsQuery, AccountSettingsQueryVariables>(
    AccountSettingsDocument,
    options,
  );
}
export type AccountSettingsQueryHookResult = ReturnType<typeof useAccountSettingsQuery>;
export type AccountSettingsLazyQueryHookResult = ReturnType<typeof useAccountSettingsLazyQuery>;
export type AccountSettingsSuspenseQueryHookResult = ReturnType<
  typeof useAccountSettingsSuspenseQuery
>;
export type AccountSettingsQueryResult = Apollo.QueryResult<
  AccountSettingsQuery,
  AccountSettingsQueryVariables
>;
export const UpdateNavigationDocument = gql`
  mutation UpdateNavigation($accountId: ID!, $input: JSON!) {
    updateNavigation(accountId: $accountId, input: $input)
  }
`;
export type UpdateNavigationMutationFn = Apollo.MutationFunction<
  UpdateNavigationMutation,
  UpdateNavigationMutationVariables
>;

/**
 * __useUpdateNavigationMutation__
 *
 * To run a mutation, you first call `useUpdateNavigationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNavigationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNavigationMutation, { data, loading, error }] = useUpdateNavigationMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateNavigationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateNavigationMutation,
    UpdateNavigationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateNavigationMutation, UpdateNavigationMutationVariables>(
    UpdateNavigationDocument,
    options,
  );
}
export type UpdateNavigationMutationHookResult = ReturnType<typeof useUpdateNavigationMutation>;
export type UpdateNavigationMutationResult = Apollo.MutationResult<UpdateNavigationMutation>;
export type UpdateNavigationMutationOptions = Apollo.BaseMutationOptions<
  UpdateNavigationMutation,
  UpdateNavigationMutationVariables
>;
export const AddUserToAccountDocument = gql`
  mutation AddUserToAccount($accountId: ID!, $email: String!, $role: UserRole!) {
    addUserToAccount(accountId: $accountId, email: $email, role: $role) {
      accountUser {
        userId
        email
        role {
          roleId
        }
      }
    }
  }
`;
export type AddUserToAccountMutationFn = Apollo.MutationFunction<
  AddUserToAccountMutation,
  AddUserToAccountMutationVariables
>;

/**
 * __useAddUserToAccountMutation__
 *
 * To run a mutation, you first call `useAddUserToAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserToAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserToAccountMutation, { data, loading, error }] = useAddUserToAccountMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      email: // value for 'email'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useAddUserToAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddUserToAccountMutation,
    AddUserToAccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddUserToAccountMutation, AddUserToAccountMutationVariables>(
    AddUserToAccountDocument,
    options,
  );
}
export type AddUserToAccountMutationHookResult = ReturnType<typeof useAddUserToAccountMutation>;
export type AddUserToAccountMutationResult = Apollo.MutationResult<AddUserToAccountMutation>;
export type AddUserToAccountMutationOptions = Apollo.BaseMutationOptions<
  AddUserToAccountMutation,
  AddUserToAccountMutationVariables
>;
export const DeleteUserFromAccountDocument = gql`
  mutation DeleteUserFromAccount($accountId: ID!, $userId: ID!) {
    deleteUserFromAccount(accountId: $accountId, userId: $userId) {
      account {
        accountId
        users {
          userId
          email
          role {
            roleId
          }
          addedAt
        }
      }
      accountUser {
        userId
        email
      }
      success
    }
  }
`;
export type DeleteUserFromAccountMutationFn = Apollo.MutationFunction<
  DeleteUserFromAccountMutation,
  DeleteUserFromAccountMutationVariables
>;

/**
 * __useDeleteUserFromAccountMutation__
 *
 * To run a mutation, you first call `useDeleteUserFromAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserFromAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserFromAccountMutation, { data, loading, error }] = useDeleteUserFromAccountMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useDeleteUserFromAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteUserFromAccountMutation,
    DeleteUserFromAccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteUserFromAccountMutation, DeleteUserFromAccountMutationVariables>(
    DeleteUserFromAccountDocument,
    options,
  );
}
export type DeleteUserFromAccountMutationHookResult = ReturnType<
  typeof useDeleteUserFromAccountMutation
>;
export type DeleteUserFromAccountMutationResult =
  Apollo.MutationResult<DeleteUserFromAccountMutation>;
export type DeleteUserFromAccountMutationOptions = Apollo.BaseMutationOptions<
  DeleteUserFromAccountMutation,
  DeleteUserFromAccountMutationVariables
>;
export const UpdateUserInAccountDocument = gql`
  mutation UpdateUserInAccount($accountId: ID!, $userId: ID!, $role: UserRole!) {
    updateUserInAccount(accountId: $accountId, userId: $userId, role: $role) {
      accountUser {
        userId
        email
      }
    }
  }
`;
export type UpdateUserInAccountMutationFn = Apollo.MutationFunction<
  UpdateUserInAccountMutation,
  UpdateUserInAccountMutationVariables
>;

/**
 * __useUpdateUserInAccountMutation__
 *
 * To run a mutation, you first call `useUpdateUserInAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserInAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserInAccountMutation, { data, loading, error }] = useUpdateUserInAccountMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      userId: // value for 'userId'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useUpdateUserInAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserInAccountMutation,
    UpdateUserInAccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUserInAccountMutation, UpdateUserInAccountMutationVariables>(
    UpdateUserInAccountDocument,
    options,
  );
}
export type UpdateUserInAccountMutationHookResult = ReturnType<
  typeof useUpdateUserInAccountMutation
>;
export type UpdateUserInAccountMutationResult = Apollo.MutationResult<UpdateUserInAccountMutation>;
export type UpdateUserInAccountMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserInAccountMutation,
  UpdateUserInAccountMutationVariables
>;
