import { MouseEvent, MouseEventHandler, ReactNode } from 'react';
import { Text } from '@visx/text';
import { TooltipWithBounds as VisxTooltip } from '@visx/tooltip';

import { getFormattedDateForPeriod } from '@/lib/date';
import { isNil } from '@/lib/utils';
import { TooltipBase } from '@/components/tooltip';

import { TimeAggregationPeriod } from '../../types';

import styles, { lineColor, chartHeight } from './charts.module.scss';

export type BarTooltipData = {
  label: string | null;
  value: number | null;
  color?: string;
};

type TimeTooltipData = {
  label: Date;
  timezone: string;
  value: number | null;
  aggPeriod: TimeAggregationPeriod;
};
export type TooltipData = BarTooltipData | TimeTooltipData;

export const DefaultChartHeight = parseInt(chartHeight, 10);
export interface VisProps<T = Record<string, any>> {
  data: T[];
  categoryKey: string;
  categoryLabel: string;
  valueKey: string;
  valueLabel: string;
  isResized?: boolean;
  onCategroryClick?: (event: MouseEvent, key: string, value: any) => void;
}

const isTimeTooltip = (tooltipData: TooltipData): tooltipData is TimeTooltipData =>
  tooltipData.label instanceof Date;

export const BarGradientHorizontal = () => (
  <linearGradient id="grad-brand" x1="0" y1="0" x2="1" y2="0">
    <stop offset="0%" stopColor="#00766F" stopOpacity={1} />
    <stop offset="100%" stopColor="#52DFAC" stopOpacity={1} />
  </linearGradient>
);

export const BarGradientVertical = () => (
  <linearGradient id="grad-brand" x1="0" y1="0" x2="0" y2="1">
    <stop offset="0%" stopColor="#52DFAC" stopOpacity={1} />
    <stop offset="100%" stopColor="#00766F" stopOpacity={1} />
  </linearGradient>
);

interface LabelProps {
  x: number;
  textAnchor?: 'middle';
  verticalAnchor?: 'middle';
  y: number;
  hideTooltip: () => void;
  label: string;
  value: number | null;
  width?: number;
  onMouseOver?: (event: any, data: TooltipData) => void;
  onClick?: MouseEventHandler;
}

export const Label = ({
  x,
  y,
  textAnchor,
  verticalAnchor,
  hideTooltip,
  label,
  value,
  width = 100,
  onMouseOver,
  onClick,
}: LabelProps) => (
  <Text
    verticalAnchor={verticalAnchor}
    x={x}
    textAnchor={textAnchor}
    y={y}
    className={styles.label}
    width={width}
    onMouseMove={onMouseOver ? (e) => onMouseOver(e, { label, value }) : undefined}
    onMouseOut={hideTooltip}
    onClick={onClick}
    scaleToFit="shrink-only"
    cursor={onClick ? 'pointer' : 'default'}>
    {label ?? '-'}
  </Text>
);

interface TooltipBaseProps {
  top: number;
  left: number;
  title?: React.ReactNode;
  children?: ReactNode;
}

export const ChartTooltip = ({ top, left, title, children }: TooltipBaseProps) => (
  <VisxTooltip top={top} left={left} className={styles.tooltip} unstyled>
    <TooltipBase title={title}>{children}</TooltipBase>
  </VisxTooltip>
);

interface TooltipProps {
  tooltipOpen: boolean;
  tooltipTop: number | undefined;
  tooltipLeft: number | undefined;
  tooltipData: TooltipData | undefined;
  categoryLabel: string;
  valueLabel: string;
  seriesColor?: string;
}

export const ChartTooltipSingleValue = ({
  tooltipOpen,
  tooltipTop = 0,
  tooltipLeft = 0,
  tooltipData,
  valueLabel,
  categoryLabel,
  seriesColor,
}: TooltipProps) =>
  tooltipOpen && tooltipData ? (
    <ChartTooltip
      top={tooltipTop}
      left={tooltipLeft}
      title={
        isTimeTooltip(tooltipData)
          ? getFormattedDateForPeriod(
              tooltipData.label,
              tooltipData.aggPeriod,
              tooltipData.timezone,
            )
          : categoryLabel +
            ': ' +
            (isNil(tooltipData.label) || tooltipData.label === '' ? '-' : tooltipData.label)
      }>
      <ul>
        <li>
          <span className={styles.seriesMarker} style={{ background: seriesColor ?? lineColor }} />
          {valueLabel}:{' '}
          <strong>
            {tooltipData.value?.toLocaleString(undefined, {
              maximumFractionDigits: 2,
            }) ?? 0}
          </strong>
        </li>
      </ul>
    </ChartTooltip>
  ) : null;
