import { MouseEventHandler } from 'react';
import classNames from 'classnames';

import { Icon, IconProps } from '../icon';
import { FormInputSize } from '../form/types';
import { Loader } from '../loader';

import styles from './button.module.scss';

type InlineButtonProps = {
  children: string | React.ReactNode;
  onClick?: MouseEventHandler;
  title?: string;
  style?: React.CSSProperties;
  className?: classNames.Argument;
  disabled?: boolean;
  color?: 'white' | 'green';
  size?: FormInputSize;
};

export const InlineButton = ({
  children,
  onClick,
  className,
  disabled,
  color,
  style,
  title,
  size = 'regular',
}: InlineButtonProps) => (
  <button
    style={style}
    className={classNames(styles.inlineTextBtn, className, {
      [styles.white]: color === 'white',
      [styles.green]: color === 'green',
      [styles.sizeSmall]: size === 'small',
      [styles.sizeMedium]: size === 'regular',
    })}
    type="button"
    title={title}
    onClick={onClick}
    disabled={disabled}>
    {children}
  </button>
);

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  loading?: boolean;
  icon?: React.ReactNode;
  variant?: 'primary' | 'secondary' | 'outlined' | 'gray';
  color?: 'default' | 'warning';
  size?: 'small' | 'compact' | 'regular' | 'large';
};

export const Button = (props: ButtonProps) => {
  const {
    children,
    icon,
    className,
    disabled = false,
    loading = false,
    variant = 'primary',
    size = 'regular',
    color = 'default',
    ...rest
  } = props;
  return (
    <button
      {...rest}
      className={classNames(styles.button, className, {
        [styles.primary]: variant === 'primary',
        [styles.secondary]: variant === 'secondary',
        [styles.outlined]: variant === 'outlined',
        [styles.styleGray]: variant === 'gray',
        [styles.sizeSmall]: size === 'small',
        [styles.sizeCompact]: size === 'compact',
        [styles.sizeMedium]: size === 'regular',
        [styles.sizeLarge]: size === 'large',
        [styles.warning]: color === 'warning',
        [styles.loading]: loading,
      })}
      disabled={disabled || loading}>
      {icon}
      <span>{children}</span>
      {loading && <Loader size="small" className={styles.loader} />}
    </button>
  );
};

type IconButtonProps = {
  icon: IconProps['name'];
  title: string;
  iconSize?: 'small' | 'regular' | 'large';
  type?: 'regular' | 'outlined' | 'gray';
  onClick?: MouseEventHandler;
  disabled?: boolean;
  className?: classNames.Argument;
  style?: React.CSSProperties;
  size?: FormInputSize;
  tabIndex?: number;
};

export const IconButton = ({
  icon,
  size = 'small',
  iconSize,
  type,
  onClick,
  disabled,
  className,
  style,
  title,
  tabIndex,
}: IconButtonProps) => (
  <button
    style={style}
    className={classNames(styles.iconButton, className, {
      [styles.styleOutlined]: type === 'outlined',
      [styles.styleGray]: type === 'gray',
      [styles.sizeSmall]: size === 'small',
      [styles.sizeMedium]: size === 'regular',
      [styles.sizeLarge]: size === 'large',
    })}
    type="button"
    title={title}
    onClick={onClick}
    disabled={disabled}
    tabIndex={tabIndex}>
    <Icon name={icon} size={iconSize === 'small' ? 12 : iconSize === 'large' ? 24 : 16} />
  </button>
);

type CloseButtonProps = {
  iconSize?: FormInputSize;
  className?: classNames.Argument;
  onClick?: MouseEventHandler;
  tabIndex?: number;
};

export const CloseButton = ({ iconSize, className, onClick, tabIndex }: CloseButtonProps) => (
  <IconButton
    icon="X"
    title="Close"
    iconSize={iconSize}
    className={className}
    onClick={onClick}
    tabIndex={tabIndex}
  />
);

type ScrollToButtonProps = {
  label: string;
  direction: 'up' | 'down';
  className?: classNames.Argument;
  onClick: MouseEventHandler;
};

export const ScrollToButton = ({ label, direction, className, onClick }: ScrollToButtonProps) => (
  <button className={classNames(styles.scrollToButton, className)} type="button" onClick={onClick}>
    <Icon name={direction === 'up' ? 'ArrowUp' : 'ArrowDown'} size={16} />
    {label}
  </button>
);

interface DropDownButtonProps {
  children: string;
  icon?: React.ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  size?: 'small' | 'large';
  className?: classNames.Argument;
}

export const DropDownButton = (props: DropDownButtonProps) => {
  const { size = 'small' } = props;

  const chevron =
    size === 'small' ? (
      <div className={styles.chevron}>
        <Icon name="ChevronDownThin" size={16} />
      </div>
    ) : (
      <Icon name="ChevronDown" size={16} />
    );

  return (
    <button
      className={classNames(styles.dropDownButton, props.className, {
        [styles.sizeMedium]: size === 'small',
        [styles.sizeLarge]: size === 'large',
      })}
      onClick={props.onClick}>
      {props.icon}
      <span>{props.children}</span>
      {chevron}
    </button>
  );
};

type ButtonGroupProps = {
  options: { label: string; value: string }[];
  value: string;
  onChange: (value: string) => void;
};

export const ButtonGroup = ({ options, value, onChange }: ButtonGroupProps) => {
  return (
    <div className={styles.buttonGroup}>
      {options.map((option) => (
        <button
          type="button"
          key={option.value}
          className={classNames({ [styles.active]: option.value === value })}
          onClick={() => onChange(option.value)}>
          {option.label}
        </button>
      ))}
    </div>
  );
};
