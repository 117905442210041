import { first, get, isObject } from 'lodash';
import {
  referencedVariablesInExpression,
  replaceVariableInExpression,
} from '@gosupersimple/penguino';

import { exhaustiveCheck } from '@/lib/exhaustive';

import {
  CompositeFilterCondition,
  DereferencedPipeline,
  ExplorationParameters,
  VariableDefinition,
  QueryVariables,
  Exploration,
  Pipeline,
  PipelineOperation,
  DereferencedPipelineOperation,
  VariableCell,
  ExplorationParameter,
  booleanParameter,
  enumParameter,
  dateParameter,
  stringParameter,
  dateRangeParameter,
  numberParameter,
  timeIntervalParameter,
} from '../types';
import { isValueExpression } from '../pipeline/operation';
import { parseParameterValue } from '.';

export const isUniqueVariableKey = (key: string, existingKeys: string[]) =>
  !existingKeys.includes(key);

export const isVariableCell = (value: any): value is VariableCell => value.kind === 'variable';

export const generateUniqueVariableKey = (key: string, existingKeys: string[]) => {
  const pattern = /-\d*$/;
  let currentStep = parseInt(pattern.exec(key)?.[0].slice(1) ?? '0');
  let currentKey = key;
  while (existingKeys.includes(currentKey)) {
    currentKey =
      currentStep > 0 ? key.replace(pattern, `-${++currentStep}`) : `${key}-${++currentStep}`;
  }
  return {
    uniqueKey: currentKey,
    stepsTaken: currentStep,
  };
};

export const replaceDefinition = (
  definition: VariableDefinition,
  exploration: Exploration,
  cellId: string,
) => ({
  ...exploration,
  view: {
    ...exploration.view,
    cells: exploration.view.cells.map((cell) => {
      if (cell.id !== cellId) {
        return cell;
      }
      return { ...cell, definition };
    }),
  },
});

export const setVariableDefinitionDefaultValue = (
  definition: VariableDefinition,
  value: ExplorationParameter,
): VariableDefinition => {
  switch (definition.kind) {
    case 'string':
      return { ...definition, defaultValue: stringParameter.parse(value) };
    case 'number':
      return { ...definition, defaultValue: numberParameter.parse(value) };
    case 'enum':
      return { ...definition, defaultValue: enumParameter.parse(value) };
    case 'boolean':
      return { ...definition, defaultValue: booleanParameter.parse(value) };
    case 'date':
      return { ...definition, defaultValue: dateParameter.parse(value) };
    case 'date_range':
      return { ...definition, defaultRange: dateRangeParameter.parse(value).range };
    case 'time_interval':
      return { ...definition, defaultValue: timeIntervalParameter.parse(value) };
    default:
      return exhaustiveCheck(definition);
  }
};

export const getVariableDefinitions = (exploration: Exploration) =>
  exploration.view.cells.filter(isVariableCell).map((cell) => cell.definition);

export const getVariableExpression = (key: string) => `variable("${key}")`;

export const getVariableKeyFromExpression = (expression: string) =>
  first(referencedVariablesInExpression(expression));

export const getVariableColor = (
  key: string,
  variables: VariableDefinition[],
  colors: string[],
) => {
  const idx = variables.findIndex((variable) => variable.key === key);
  return colors[idx % colors.length];
};

const VariableToQueryVariableTypeMap = {
  string: 'String',
  number: 'Number',
  boolean: 'Boolean',
  enum: 'String',
  date: 'Date',
  time_interval: 'String',
} as const;

/**
 * Zip together variable definitions and their values from parameters.
 */
export const getQueryVariablesFromParameters = (
  variables: VariableDefinition[],
  parameters: ExplorationParameters,
): QueryVariables => {
  return variables.reduce<QueryVariables>((acc, variable) => {
    const { key, kind } = variable;
    const type = get(VariableToQueryVariableTypeMap, kind);
    const value = get(parameters, key);
    return [
      ...acc,
      { type, key, value: parseParameterValue(value, variable) } as QueryVariables[number],
    ];
  }, []);
};

/**
 * Returns the variables of an exploration with default values.
 */
export const getExplorationVariables = (exploration: Exploration): QueryVariables =>
  getQueryVariablesFromParameters(getVariableDefinitions(exploration), {});

const getVariablesInExpression = (expression: string): string[] => {
  try {
    return referencedVariablesInExpression(expression);
  } catch (e) {
    return [];
  }
};

export function filterVariablesForPipeline(
  pipeline: DereferencedPipeline,
  variables: QueryVariables,
) {
  const keys = getVariableKeysInPipeline(pipeline, variables);
  return variables.filter((v) => keys.includes(v.key));
}

export function getVariableKeysInPipeline(
  pipeline: DereferencedPipeline,
  variables: { key: string }[],
): string[] {
  const keys = variables.map((variable) => variable.key);

  const variableKeysInPipeline = pipeline.operations
    .map((operation) => {
      if (operation.disabled === true) {
        return [];
      }

      const keys: string[] = [];

      visitPipelineOperationExpressions(operation, (expression) => {
        keys.push(...getVariablesInExpression(expression));
        return expression;
      });

      return keys;
    })
    .flat();

  return variableKeysInPipeline.filter((key) => keys.includes(key));
}

function visitCompositeFilterExpressions(
  condition: CompositeFilterCondition,
  visitExpression: (expression: string) => string,
): CompositeFilterCondition {
  if ('value' in condition) {
    if (isObject(condition.value) && 'expression' in condition.value) {
      return {
        ...condition,
        value: {
          ...condition.value,
          expression: visitExpression(condition.value.expression),
        },
      };
    }
  }
  if ('operands' in condition) {
    return {
      ...condition,
      operands: condition.operands.map((operand) =>
        visitCompositeFilterExpressions(operand, visitExpression),
      ),
    };
  }
  return condition;
}

function visitPipelineOperationExpressions<
  T extends PipelineOperation | DereferencedPipelineOperation,
>(operation: T, visitExpression: (expression: string) => string): T {
  if (operation.operation === 'filter') {
    return {
      ...operation,
      parameters: visitCompositeFilterExpressions(operation.parameters, visitExpression),
    };
  }
  if (operation.operation === 'deriveField') {
    return {
      ...operation,
      parameters: {
        ...operation.parameters,
        value: {
          ...operation.parameters.value,
          expression: visitExpression(operation.parameters.value.expression),
        },
      },
    };

    return operation;
  }
  if (operation.operation === 'cohort') {
    return {
      ...operation,
      parameters: {
        ...operation.parameters,
        pipeline: visitPipelineExpressions(operation.parameters.pipeline, visitExpression),
      },
    };
  }
  if (operation.operation === 'groupAggregate') {
    return {
      ...operation,
      parameters: {
        ...operation.parameters,
        aggregations: operation.parameters.aggregations.map((aggregation) => {
          if (aggregation.type === 'custom' && aggregation.value?.expression !== undefined) {
            return {
              ...aggregation,
              value: {
                ...aggregation.value,
                expression: visitExpression(aggregation.value.expression),
              },
            };
          }
          return aggregation;
        }),
        groups: operation.parameters.groups.map((group) => {
          if (isValueExpression(group.precision)) {
            return {
              ...group,
              precision: {
                ...group.precision,
                expression: visitExpression(group.precision.expression),
              },
            };
          }
        }),
      },
    };
  }

  if (operation.operation === 'relationAggregate') {
    return {
      ...operation,
      parameters: {
        ...operation.parameters,
        aggregations: operation.parameters.aggregations.map((aggregation) => {
          if (aggregation.type === 'custom' && aggregation.value?.expression !== undefined) {
            return {
              ...aggregation,
              value: {
                ...aggregation.value,
                expression: visitExpression(aggregation.value.expression),
              },
            };
          }
          return aggregation;
        }),
        filters: operation.parameters.filters?.map((filter) => ({
          parameters: visitCompositeFilterExpressions(filter.parameters, visitExpression),
        })),
      },
    };
  }

  if (operation.operation === 'joinPipeline') {
    return {
      ...operation,
      parameters: {
        ...operation.parameters,
        pipeline: visitPipelineExpressions(operation.parameters.pipeline, visitExpression),
      },
    };
  }

  return operation;
}

const visitPipelineExpressions = <T extends Pipeline | DereferencedPipeline>(
  pipeline: T,
  visitExpression: (expression: string) => string,
): T => ({
  ...pipeline,
  operations: pipeline.operations.map((operation) =>
    visitPipelineOperationExpressions(operation, visitExpression),
  ),
});

export const replaceVariableForExploration = (
  exploration: Exploration,
  previous: string,
  next: string,
): Exploration => ({
  ...exploration,
  view: {
    ...exploration.view,
    cells: exploration.view.cells.map((cell) =>
      'pipeline' in cell
        ? {
            ...cell,
            pipeline: visitPipelineExpressions(cell.pipeline, (expression) =>
              replaceVariableInExpression(expression, previous, next),
            ),
          }
        : cell,
    ),
  },
});
