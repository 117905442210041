import { useMemo, useState } from 'react';
import classNames from 'classnames';
import { NavLink, useNavigate } from 'react-router-dom';

import { useBuildAccountUrl, useSelectedAccount } from '@/lib/accounts/context';
import { useNavigationQuery } from '@/graphql';

import { AccountMenu, DefaultAccountMenu, MobileAccountMenu } from './account-menu';
import { IconButton, CloseButton } from '../button';
import { Icon } from '../icon';
import { buildExplorationUrl } from '../../explore/utils/url';

import styles from './header.module.scss';

interface MenuProps {
  Component: typeof DefaultMenu | typeof MobileMenu;
}

type MenuItem = {
  label: string;
  url: string;
};

interface MenuComponentProps {
  onClickLogOut: () => void;
  items: MenuItem[];
}

export const Menu = (props: MenuProps) => {
  const navigate = useNavigate();
  const account = useSelectedAccount();
  const buildAccountUrl = useBuildAccountUrl();

  const { data } = useNavigationQuery({
    variables: {
      accountId: account?.accountId ?? '',
    },
    skip: account === null,
    fetchPolicy: 'cache-first',
  });

  const menuItems = useMemo(() => {
    const items =
      data?.account?.navigation.map((item) => ({
        label: item.label,
        url: buildAccountUrl(buildExplorationUrl(item)),
      })) ?? [];

    return [
      {
        label: 'Explore',
        url: buildAccountUrl('/explore'),
      },
      ...items,
    ];
  }, [data?.account, buildAccountUrl]);

  return <props.Component onClickLogOut={() => navigate('/logout')} items={menuItems} />;
};

interface MainMenuItemsProps {
  items: MenuItem[];
  onClick?: (item: MenuItem) => void;
}

const MainMenuItems = ({ items, onClick }: MainMenuItemsProps) => (
  <>
    {items.map((item, idx) => (
      <NavLink
        key={idx}
        to={item.url}
        className={() => (item.url === location.pathname ? 'active' : '')}
        onClick={() => onClick && onClick(item)}>
        {item.label}
      </NavLink>
    ))}
  </>
);

export const DefaultMenu = ({ onClickLogOut, items }: MenuComponentProps) => {
  const buildAccountUrl = useBuildAccountUrl();
  return (
    <nav className={styles.menu}>
      <MainMenuItems items={items} />
      <div className={styles.right}>
        <AccountMenu
          Component={DefaultAccountMenu}
          accountSettingsUrl={buildAccountUrl('/settings')}
          onClickLogOut={onClickLogOut}
        />
      </div>
    </nav>
  );
};

export const MobileMenu = ({ onClickLogOut, items }: MenuComponentProps) => {
  const navigate = useNavigate();
  const buildAccountUrl = useBuildAccountUrl();
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <IconButton
        icon="Menu"
        iconSize="large"
        title="Open menu"
        className={styles.menuButton}
        onClick={() => setIsOpen(true)}
      />
      <div className={classNames(styles.mobileMenu, { [styles.isOpen]: isOpen })}>
        <div className={styles.scrollContainer}>
          <nav>
            <MainMenuItems items={items} onClick={() => setIsOpen(false)} />
            <hr />
            <AccountMenu
              Component={MobileAccountMenu}
              accountSettingsUrl={buildAccountUrl('/settings')}
              onSwitchAccount={() => setIsOpen(false)}
              onClickLogOut={onClickLogOut}
            />
            <hr />
            <button
              className={styles.logoutButton}
              onClick={() => navigate(buildAccountUrl('/settings'))}>
              <Icon name="Settings" size={16} />
              Account Settings
            </button>
            <hr />
            <button className={styles.logoutButton} onClick={onClickLogOut}>
              <Icon name="X" size={16} />
              Log out
            </button>
          </nav>
        </div>
        <CloseButton
          className={styles.closeButton}
          onClick={() => setIsOpen(false)}
          iconSize="large"
        />
      </div>
    </>
  );
};
